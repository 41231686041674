import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

const textFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledReactMarkdown = styled(ReactMarkdown, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isNew'].includes(prop)
})(({ theme, isNew = false }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,

  // Animaciones y estilos base
  '& p, & li, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
    opacity: isNew ? 0 : 1,
    animation: isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    '& .word': {
      display: 'inline-block',
      opacity: isNew ? 0 : 1,
      animation: isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    }
  },

  // Estilos para KaTeX
  '& .math': {
    margin: '8px 0',
    '&.math-display': {
      display: 'block',
      overflowX: 'auto',
      padding: '16px 0',
      textAlign: 'center',
      '& > .katex': {
        textAlign: 'center',
        maxWidth: '100%',
      },
      '& > .katex-display': {
        margin: 0,
        padding: '8px 0',
      }
    },
    '&.math-inline': {
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '0 4px',
    }
  },

  // Párrafos
  '& p': {
    margin: '0 0 16px 0',
    lineHeight: 1.75,
    fontSize: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  // Listas
  '& ul, & ol': {
    margin: '0 0 16px 1.5em',
    padding: 0,
  },

  '& li': {
    margin: '0 0 8px 0',
    lineHeight: 1.75,
    '& > p': {
      margin: '0 0 8px 0',
    }
  },

  // Citas
  '& blockquote': {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    paddingLeft: '16px',
    margin: '16px 0',
    fontStyle: 'italic',
    lineHeight: 1.75,
    color: theme.palette.text.secondary,
  },

  // Código
  '& code': {
    padding: '2px 4px',
    borderRadius: '4px',
    fontSize: '0.9em',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
  },

  '& pre': {
    margin: '16px 0',
    padding: '16px',
    overflow: 'auto',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '& code': {
      padding: 0,
      backgroundColor: 'transparent',
    }
  },

  // Encabezados
  '& h1': {
    fontSize: '2.25rem',
    fontWeight: 600,
    margin: '32px 0 16px 0',
    lineHeight: 1.3,
  },

  '& h2': {
    fontSize: '1.875rem',
    fontWeight: 600,
    margin: '28px 0 16px 0',
    lineHeight: 1.35,
  },

  '& h3': {
    fontSize: '1.875rem',
    fontWeight: 600,
    margin: '24px 0 16px 0',
    lineHeight: 1.4,
  },

  '& h4': {
    fontSize: '1.25rem',
    fontWeight: 500,
    margin: '20px 0 16px 0',
    lineHeight: 1.4,
  },

  '& h5': {
    fontSize: '1.125rem',
    fontWeight: 500,
    margin: '20px 0 16px 0',
    lineHeight: 1.4,
  },

  '& h6': {
    fontSize: '1rem',
    fontWeight: 500,
    margin: '20px 0 16px 0',
    lineHeight: 1.4,
  },

  // Primer encabezado sin margen superior
  '& h1:first-child, & h2:first-child, & h3:first-child, & h4:first-child, & h5:first-child, & h6:first-child': {
    marginTop: 0,
  },

  // Imágenes
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '4px',
    margin: '16px 0',
    display: 'block',
  },

  // Enlaces
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: 'color 0.2s ease',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
  },

  // Líneas horizontales
  '& hr': {
    border: 'none',
    height: '1px',
    backgroundColor: theme.palette.divider,
    margin: '24px 0',
  },

  // Tablas
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '16px',
    borderRadius: '4px',
    overflow: 'hidden',
  },

  '& th, & td': {
    padding: '12px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'left',
  },

  '& th': {
    fontWeight: 600,
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)' 
      : 'rgba(0, 0, 0, 0.02)',
  },

  '& tbody tr': {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.03)'
        : 'rgba(0, 0, 0, 0.01)',
    },
  },

  // Estilos específicos para diagramas Mermaid
  '& .mermaid-diagram': {
    margin: '24px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '16px',
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    '& svg': {
      maxWidth: '100%',
      height: 'auto',
    },
  },

  // Estilos responsivos
  [theme.breakpoints.down('sm')]: {
    '& h1': { fontSize: '2rem' },
    '& h2': { fontSize: '1.75rem' },
    '& h3': { fontSize: '1.5rem' },
    '& h4': { fontSize: '1.25rem' },
    '& h5': { fontSize: '1.125rem' },
    '& h6': { fontSize: '1rem' },
    '& p': { fontSize: '0.9375rem' },
    '& .math.math-display': {
      fontSize: '0.9em',
    },
    '& .math.math-inline': {
      fontSize: '0.9em',
    }
  },
}));

// Wrap the styled component with math plugins
const MarkdownWithMath = props => {
  return (
    <StyledReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      {...props}
    />
  );
};

export default MarkdownWithMath;