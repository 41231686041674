import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Box, IconButton, Avatar, Fade, useMediaQuery, Snackbar, Alert, Button, Typography } from '@mui/material';
import { ThemeProvider, styled, useTheme, createTheme } from '@mui/material/styles';
import { Settings, Plus } from 'lucide-react';
import ProfileDashboard from '../ProfileDashboard';
import HomeInput from '../../home/HomeInput/HomeInput';
import ChatViewNest from '../../chat/core/ChatViewNest';
import BackgroundSelector from '../../navigation/BackgroundSelector';
import NavigationBar from '../../navigation/NavigationBar';
import { getAuth } from 'firebase/auth';
import { 
  sendMessageToAGT, 
  getUserBackgroundPreference, 
  setUserBackgroundPreference, 
  createNewChat,
  checkProStatus 
} from '../../services/AGTService';
import { isAuthorized } from '../../auth/AuthorizedUsers';
import AGTUps from '../../AGTUps/AGTUps';
import AGTEdu from '../../AGTEdu/AGTEdu';
import AGTPromo from '../../AGTPromo/AGTPromo';
import { getDashboardStyles } from './dashboardStyles';
import { getFirstName } from '../../../utils/nameUtils';

const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000;
const MESSAGE_CACHE_SIZE = 100;
const NAVIGATION_DEBOUNCE_TIME = 300;

const StyledContainer = styled(Box)(({ theme }) => ({
  ...getDashboardStyles(theme).fixedContainer
}));

const StyledWindowBar = styled(Box)(({ theme }) => ({
  ...getDashboardStyles(theme).windowBar
}));

const TopBar = styled(Box)(({ theme }) => ({
  ...getDashboardStyles(theme).topBar
}));

const NavContainer = styled(Box)(({ theme }) => ({
  ...getDashboardStyles(theme).navContainer
}));

const ContentArea = styled(Box)(({ theme }) => ({
  ...getDashboardStyles(theme).contentArea
}));

class MessageCache {
  constructor(maxSize = MESSAGE_CACHE_SIZE) {
    this.maxSize = maxSize;
    this.cache = new Map();
  }

  add(chatId, message) {
    if (!this.cache.has(chatId)) {
      this.cache.set(chatId, []);
    }
    const messages = this.cache.get(chatId);
    messages.push(message);
    
    if (messages.length > this.maxSize) {
      messages.shift();
    }
  }

  get(chatId) {
    return this.cache.get(chatId) || [];
  }

  clear() {
    this.cache.clear();
  }
}

class ResourceManager {
  constructor() {
    this.resources = new Set();
    this.cleanupFunctions = new Map();
  }

  register(resource, cleanup) {
    this.resources.add(resource);
    if (cleanup) {
      this.cleanupFunctions.set(resource, cleanup);
    }
  }

  release(resource) {
    const cleanup = this.cleanupFunctions.get(resource);
    if (cleanup) {
      cleanup();
      this.cleanupFunctions.delete(resource);
    }
    this.resources.delete(resource);
  }

  clearAll() {
    this.cleanupFunctions.forEach(cleanup => cleanup());
    this.cleanupFunctions.clear();
    this.resources.clear();
  }
}

const TopBarContent = React.memo(({ 
  handleBackgroundClick, 
  handleProfileClick, 
  user, 
  onNavigate, 
  activeSection, 
  showUpsInNav, 
  showEduInNav 
}) => {
  const theme = useTheme();
  const styles = getDashboardStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TopBar>
      <IconButton 
        onClick={handleBackgroundClick} 
        sx={{
          ...styles.backgroundButton(isMobile),
          '& svg': {
            color: '#007BFE',
            transition: 'all 0.2s'
          },
          '&:hover svg': {
            opacity: 0.8
          }
        }}
      >
        <Settings size={isMobile ? 20 : 24} />
      </IconButton>
      <NavContainer>
        <NavigationBar 
          onNavigate={onNavigate} 
          activeSection={activeSection}
          showUpsInNav={showUpsInNav}
          showEduInNav={showEduInNav}
        />
      </NavContainer>
      <Avatar 
        src={user?.photoURL || "/path-to-default-image.jpg"} 
        alt={user?.displayName || "Profile"} 
        onClick={handleProfileClick}
        sx={styles.avatar(isMobile)}
      />
    </TopBar>
  );
});

const FullDashboard = ({ onLogout, user: initialUser, backgroundType: initialBackgroundType, onBackgroundChange }) => {
  const [activeSection, setActiveSection] = useState(() => {
    return localStorage.getItem('activeSection') || 'home';
  });
  const [user, setUser] = useState(initialUser);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showProfile, setShowProfile] = useState(false);
  const [backgroundSelectorAnchorEl, setBackgroundSelectorAnchorEl] = useState(null);
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatUpdateTrigger, setChatUpdateTrigger] = useState(0);
  const [mostRecentChatId, setMostRecentChatId] = useState(null);
  const [lastActivityTimestamp, setLastActivityTimestamp] = useState(Date.now());
  const [showUpsInNav, setShowUpsInNav] = useState(false);
  const [showEduInNav, setShowEduInNav] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  
  const inactivityTimerRef = useRef(null);
  const messageCache = useRef(new MessageCache());
  const resourceManager = useRef(new ResourceManager());
  const lastNavigationTime = useRef(Date.now());

  // Memo para el nombre del usuario
  const firstName = useMemo(() => {
    console.log("DisplayName recibido:", initialUser?.displayName);
    const name = getFirstName(initialUser?.displayName);
    console.log("Primer nombre extraído:", name);
    return name;
  }, [initialUser?.displayName]);

  const backgroundImage = useMemo(() => ({
    aurora: "/images/background/aurora.png",
    curious: "/images/background/curious.png",
    edge: "/images/background/edge.png",
    eureka: "/images/background/eureka.png",
    hacker: "/images/background/hacker.png",
    horizon: "/images/background/horizon.png",
    ignite: "/images/background/ignite.png",
    mind: "/images/background/mind.png",
    neuro: "/images/background/neuro.png",
    odin: "/images/background/odin.png",
    python: "/images/background/python.png",
    vibe: "/images/background/vibe.png"
  }), []);

  useEffect(() => {
    const handlePaymentReturn = async () => {
      const session_id = new URLSearchParams(window.location.search).get('session_id');
      
      if (session_id && session_id.startsWith('cs_')) {
        try {
          const status = await checkProStatus(user.uid);
          if (status.isPro) {
            setErrorMessage('');
            setActiveSection('chat'); 
            setSuccessMessage('Welcome to AGT Pro! Your account has been upgraded successfully.');
          }
        } catch (error) {
          console.error('Error verifying pro status:', error);
          setErrorMessage('Error verifying subscription status. Please contact support.');
        }
        
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    if (user?.uid) {
      handlePaymentReturn();
    }
  }, [user]);

  const [backgroundType, setBackgroundType] = useState(() => {
    const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
    return validBackgroundTypes.includes(initialBackgroundType) ? initialBackgroundType : 'system';
  });

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getThemeMode = useCallback((bgType) => {
    if (bgType === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    }
    return ['light', 'dark'].includes(bgType) ? bgType : 'light';
  }, [prefersDarkMode]);

  const theme = useMemo(() => createTheme({
    palette: {
      mode: getThemeMode(backgroundType),
    },
  }), [backgroundType, getThemeMode]);

  const styles = getDashboardStyles(theme);

  const resetInactivityTimer = useCallback(() => {
    const now = Date.now();
    setLastActivityTimestamp(now);

    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(() => {
      if (activeSection === 'chat') {
        setActiveSection('home');
        localStorage.setItem('activeSection', 'home');
      }
    }, INACTIVITY_TIMEOUT);
  }, [activeSection]);

  useEffect(() => {
    setUser(initialUser);
    setIsUserAuthorized(isAuthorized(initialUser?.email));

    return () => {
      messageCache.current.clear();
      resourceManager.current.clearAll();
    };
  }, [initialUser]);

  useEffect(() => {
    const loadUserPreference = async () => {
      if (!initialUser) return;

      try {
        const preference = await getUserBackgroundPreference(initialUser.uid);
        const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
        const newBackgroundType = validBackgroundTypes.includes(preference) ? preference : 'system';
        
        setBackgroundType(newBackgroundType);
        onBackgroundChange(newBackgroundType);
      } catch (error) {
        setBackgroundType('system');
        onBackgroundChange('system');
      }
    };
  
    loadUserPreference();
  }, [initialUser, onBackgroundChange, backgroundImage]);

  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    const eventTypes = ['mousemove', 'keypress', 'click', 'scroll'];
    
    eventTypes.forEach(eventType => {
      window.addEventListener(eventType, handleUserActivity);
    });

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }

      eventTypes.forEach(eventType => {
        window.removeEventListener(eventType, handleUserActivity);
      });
    };
  }, [resetInactivityTimer]);

  const handleNavigate = useCallback((section) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= NAVIGATION_DEBOUNCE_TIME) {
      lastNavigationTime.current = now;
      setActiveSection(section);
      setShowProfile(false);
      
      if (section === 'chat' && chatMessages.length === 0) {
        setChatMessages([]);
      }
      
      localStorage.setItem('activeSection', section);
      resetInactivityTimer();
    }
  }, [chatMessages.length, resetInactivityTimer]);

  const handleProfileClick = useCallback(() => {
    setShowProfile(true);
    setActiveSection('profile');
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleSendMessage = useCallback(async (newMessageText, isUser = true) => {
    if (!isUser) return;
  
    try {
      const newChat = await createNewChat(user.uid);
      const chatId = newChat.id;
      
      let messageContent;
      try {
        if (typeof newMessageText === 'string') {
          messageContent = JSON.parse(newMessageText).text;
        } else {
          messageContent = newMessageText.text;
        }
      } catch (error) {
        messageContent = newMessageText;
      }
      
      const userMessage = { 
        text: messageContent, 
        isUser: true, 
        id: Date.now(),
        chatId 
      };
      messageCache.current.add(chatId, userMessage);
      
      // Añadir log para verificar que el nombre está presente
      console.log("Preparando envío de mensaje:", {
        messageContent,
        firstName,
        chatId,
        userId: user.uid
      });
      
      // Asegurarnos de que el nombre se pase explícitamente
      const response = await sendMessageToAGT(
        messageContent,
        user.uid,
        chatId,
        undefined,
        user.isPro ? "claude-3-sonnet-20240229" : "claude-3-haiku-20240307",
        'normal',
        firstName || '' // Asegurarnos de que no sea undefined
      );
      
      const botMessage = { 
        text: response.reply, 
        isUser: false, 
        id: Date.now() + 1, 
        chatId 
      };
      messageCache.current.add(chatId, botMessage);
      
      setChatMessages(prev => [...prev, userMessage, botMessage]);
      setMostRecentChatId(chatId);
      setActiveSection('chat');
      resetInactivityTimer();
      setChatUpdateTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Error en handleSendMessage:", error);
      setErrorMessage("Error sending message. Please try again.");
    }
  }, [user, firstName, resetInactivityTimer]);

  const handleBackgroundClick = useCallback((event) => {
    setBackgroundSelectorAnchorEl(event.currentTarget);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleBackgroundClose = useCallback(() => {
    setBackgroundSelectorAnchorEl(null);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleBackgroundChange = useCallback(async (newBackgroundType) => {
    const validBackgroundTypes = ['system', 'light', 'dark', ...Object.keys(backgroundImage)];
    
    if (validBackgroundTypes.includes(newBackgroundType)) {
      try {
        await setUserBackgroundPreference(user.uid, newBackgroundType);
        setBackgroundType(newBackgroundType);
        onBackgroundChange(newBackgroundType);
      } catch (error) {
        setErrorMessage("Error saving background preference. Please try again.");
      }
    } else {
      setErrorMessage("Invalid background type.");
    }
    
    resetInactivityTimer();
  }, [user, onBackgroundChange, backgroundImage, resetInactivityTimer]);

  const getCurrentBackground = useCallback(() => {
    if (['light', 'dark', 'system'].includes(backgroundType)) {
      return null;
    }
    return backgroundImage[backgroundType];
  }, [backgroundType, backgroundImage]);

  const handleLogout = useCallback(() => {
    localStorage.setItem('activeSection', 'home');
    messageCache.current.clear();
    resourceManager.current.clearAll();
    onLogout();
  }, [onLogout]);

  const handleOpenAGTUps = useCallback(() => {
    setActiveSection('ups');
    setShowUpsInNav(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleOpenAGTEdu = useCallback(() => {
    setActiveSection('edu');
    setShowEduInNav(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleOpenAGTPromo = useCallback(() => {
    setActiveSection('promo');
    setShowPromo(true);
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const renderContent = useCallback(() => {
    switch (activeSection) {
      case 'home':
        return (
          <Box sx={styles.homeBox}>
            <HomeInput 
              onSendMessage={handleSendMessage}
              onOpenAGTPromo={handleOpenAGTPromo}
              userName={firstName} // Pasamos el nombre del usuario
            />
          </Box>
        );
      case 'chat':
        return (
          <Box sx={styles.chatBox}>
            <ChatViewNest 
              key={chatUpdateTrigger}
              user={user}
              userName={firstName} // Pasamos el nombre del usuario
              isAuthorized={isUserAuthorized}
              onSendMessage={handleSendMessage}
              isLimited={false}
              initialView={chatMessages.length === 0}
              messages={chatMessages}
              isLoading={isLoadingChat}
              onNavigate={handleNavigate}
              themeMode={theme.palette.mode}
              mostRecentChatId={mostRecentChatId}
              onOpenAGTPromo={handleOpenAGTPromo}
            />
          </Box>
        );
      case 'profile':
        return (
          <ProfileDashboard 
            user={user}
            onLogout={handleLogout}
            backgroundType={backgroundType}
          />
        );
      case 'ups':
        return <AGTUps user={user} />;
      case 'edu':
        return <AGTEdu />;
      case 'promo':
        return (
          <AGTPromo 
            onOpenAGTUps={handleOpenAGTUps} 
            onOpenAGTEdu={handleOpenAGTEdu}
            userId={user.uid}
          />
        );
      default:
        return null;
    }
  }, [
    activeSection,
    user,
    firstName,
    isUserAuthorized,
    handleSendMessage,
    chatMessages,
    isLoadingChat,
    handleNavigate,
    theme,
    handleLogout,
    backgroundType,
    chatUpdateTrigger,
    mostRecentChatId,
    handleOpenAGTUps,
    handleOpenAGTEdu,
    handleOpenAGTPromo,
    styles
  ]);

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer style={{ 
        backgroundImage: getCurrentBackground() ? `url(${getCurrentBackground()})` : 'none',
        backgroundColor: getCurrentBackground() 
          ? 'transparent' 
          : (backgroundType === 'system'
              ? (prefersDarkMode ? '#171717' : '#FFFFFF')
              : (backgroundType === 'light' ? '#FFFFFF' : '#171717'))
      }}>
        <StyledWindowBar>
          <TopBarContent 
            handleBackgroundClick={handleBackgroundClick}
            handleProfileClick={handleProfileClick}
            user={user}
            onNavigate={handleNavigate}
            activeSection={activeSection}
            showUpsInNav={showUpsInNav}
            showEduInNav={showEduInNav}
          />
          <ContentArea>
            <Fade in={true} timeout={300}>
              <Box sx={styles.contentBox}>
                {renderContent()}
              </Box>
            </Fade>
          </ContentArea>
        </StyledWindowBar>
        <BackgroundSelector
          anchorEl={backgroundSelectorAnchorEl}
          open={Boolean(backgroundSelectorAnchorEl)}
          onClose={handleBackgroundClose}
          onBackgroundChange={handleBackgroundChange}
          currentBackground={backgroundType}
        />
        <Snackbar 
          open={!!errorMessage} 
          autoHideDuration={6000} 
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={styles.snackbarContainer}
        >
          <Alert 
            onClose={() => setErrorMessage('')} 
            severity="error"
            sx={styles.snackbarAlert}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSuccessMessage('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </StyledContainer>
    </ThemeProvider>
  );
};

export default React.memo(FullDashboard);