import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, InputBase, IconButton, Paper } from '@mui/material';
import { Search as SearchIcon, X as CloseIcon } from 'lucide-react';
import AGTFoundService from '../services/AGTUps-product-services/AGTFound/AGTFoundService';

const SearchWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius * 8,
  padding: theme.spacing(0.5, 2),
  transition: 'all 0.3s ease',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  border: 'none',
  '&:focus-within': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  }
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  color: 'white',
  fontSize: '1.1rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: 1,
    }
  }
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  color: 'rgba(255, 255, 255, 0.5)',
}));

const AGTFound = ({ 
  placeholder = "¿Qué necesita tu negocio hoy?", 
  value = '',
  onChange = () => {},
  onResults = () => {},
  setIsSearching = () => {}
}) => {
  const handleSearch = async () => {
    if (!value.trim()) return;

    setIsSearching(true);
    onResults({ recommendations: [] });

    try {
      const result = await AGTFoundService.search(value);
      onResults(result);
    } catch (error) {
      console.error('Error en búsqueda:', error);
      onResults({ error: 'No se pudo procesar tu búsqueda' });
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleClear = () => {
    onChange('');
    onResults({ recommendations: [] });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <SearchWrapper elevation={0}>
        <SearchInput
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          fullWidth
        />
        <IconButton onClick={handleSearch} aria-label="search">
          <SearchIcon size={22} />
        </IconButton>
        {value && (
          <ClearButton 
            onClick={handleClear}
            size="small"
            aria-label="clear search"
          >
            <CloseIcon size={18} />
          </ClearButton>
        )}
      </SearchWrapper>
    </Box>
  );
};

export default AGTFound;