import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Chip, TextField, Avatar, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Search,
  Filter,
  MessageSquare,
  Star,
  Globe,
  Truck,
  DollarSign,
  Shield,
  Clock,
  BadgeCheck
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const SupplierCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  textAlign: 'center',
}));

const AGTSupplierFinder = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeNegotiations, setActiveNegotiations] = useState([
    {
      id: 1,
      name: 'Tech Components Ltd',
      status: 'In Progress',
      lastMessage: 'Discussing volume discounts',
      progress: 65,
    },
    {
      id: 2,
      name: 'Global Materials Inc',
      status: 'Pending',
      lastMessage: 'Awaiting price quote',
      progress: 30,
    },
  ]);

  return (
    <Box className="space-y-6">
      {/* Search and Filter Section */}
      <StyledPaper>
        <SearchBar>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search suppliers by name, location, or product..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <Search size={20} className="mr-2 text-gray-400" />,
            }}
          />
          <ActionButton>
            <Filter size={16} />
            Filters
          </ActionButton>
          <ActionButton>
            <Search size={16} />
            Search
          </ActionButton>
        </SearchBar>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox>
              <Globe size={24} className="mx-auto mb-2 text-blue-500" />
              <Typography variant="h4">150+</Typography>
              <Typography variant="body2" color="text.secondary">
                Countries
              </Typography>
            </StatBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox>
              <Truck size={24} className="mx-auto mb-2 text-green-500" />
              <Typography variant="h4">10k+</Typography>
              <Typography variant="body2" color="text.secondary">
                Suppliers
              </Typography>
            </StatBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox>
              <DollarSign size={24} className="mx-auto mb-2 text-purple-500" />
              <Typography variant="h4">95%</Typography>
              <Typography variant="body2" color="text.secondary">
                Price Match
              </Typography>
            </StatBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatBox>
              <Shield size={24} className="mx-auto mb-2 text-red-500" />
              <Typography variant="h4">100%</Typography>
              <Typography variant="body2" color="text.secondary">
                Verified
              </Typography>
            </StatBox>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Top Suppliers */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recommended Suppliers</Typography>
            
            <SupplierCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Box className="flex items-center gap-3 mb-3">
                    <Avatar sx={{ width: 50, height: 50 }}>TC</Avatar>
                    <Box>
                      <Box className="flex items-center gap-2">
                        <Typography variant="h6">Tech Components Ltd</Typography>
                        <BadgeCheck size={16} className="text-blue-500" />
                      </Box>
                      <Rating value={4.5} readOnly size="small" />
                    </Box>
                  </Box>
                  <Box className="space-y-2">
                    <Box className="flex gap-2">
                      <Chip icon={<Globe size={14} />} label="United States" size="small" />
                      <Chip icon={<Clock size={14} />} label="Fast Response" size="small" color="success" />
                      <Chip icon={<Star size={14} />} label="Top Rated" size="small" color="primary" />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Specialized in electronic components and manufacturing supplies. 
                      ISO 9001 certified with 10+ years of experience.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="space-y-2">
                    <ActionButton className="w-full">
                      <MessageSquare size={16} />
                      Contact Supplier
                    </ActionButton>
                    <ActionButton className="w-full">
                      View Details
                    </ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </SupplierCard>

            <SupplierCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Box className="flex items-center gap-3 mb-3">
                    <Avatar sx={{ width: 50, height: 50 }}>GM</Avatar>
                    <Box>
                      <Box className="flex items-center gap-2">
                        <Typography variant="h6">Global Materials Inc</Typography>
                        <BadgeCheck size={16} className="text-blue-500" />
                      </Box>
                      <Rating value={4.8} readOnly size="small" />
                    </Box>
                  </Box>
                  <Box className="space-y-2">
                    <Box className="flex gap-2">
                      <Chip icon={<Globe size={14} />} label="Germany" size="small" />
                      <Chip icon={<Truck size={14} />} label="Free Shipping" size="small" color="success" />
                      <Chip icon={<Shield size={14} />} label="Verified" size="small" color="primary" />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Leading supplier of industrial materials and custom solutions.
                      Known for quality and reliable delivery times.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="space-y-2">
                    <ActionButton className="w-full">
                      <MessageSquare size={16} />
                      Contact Supplier
                    </ActionButton>
                    <ActionButton className="w-full">
                      View Details
                    </ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </SupplierCard>
          </StyledPaper>
        </Grid>

        {/* Active Negotiations */}
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Active Negotiations</Typography>
            
            {activeNegotiations.map((negotiation) => (
              <Box 
                key={negotiation.id}
                className="p-4 bg-gray-50 rounded-lg mb-3"
              >
                <Box className="flex justify-between items-start mb-2">
                  <Typography variant="subtitle1">{negotiation.name}</Typography>
                  <Chip 
                    label={negotiation.status} 
                    size="small"
                    color={negotiation.status === 'In Progress' ? 'success' : 'warning'}
                  />
                </Box>
                <Box className="flex items-center gap-2 mb-2">
                  <MessageSquare size={14} className="text-gray-400" />
                  <Typography variant="body2" color="text.secondary">
                    {negotiation.lastMessage}
                  </Typography>
                </Box>
                <Box>
                  <Box className="flex justify-between mb-1">
                    <Typography variant="caption" color="text.secondary">
                      Negotiation Progress
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {negotiation.progress}%
                    </Typography>
                  </Box>
                  <Box className="w-full bg-gray-200 rounded-full h-2">
                    <Box
                      className="bg-blue-600 h-2 rounded-full"
                      style={{ width: `${negotiation.progress}%` }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
            
            <ActionButton className="w-full mt-4">
              View All Negotiations
            </ActionButton>
          </StyledPaper>

          {/* Quick Stats */}
          <StyledPaper className="mt-4">
            <Typography variant="h6" gutterBottom>Quick Stats</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box className="p-3 bg-blue-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Average Response Time
                  </Typography>
                  <Typography variant="h6">2.5 hrs</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-green-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Success Rate
                  </Typography>
                  <Typography variant="h6">85%</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-purple-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Active Quotes
                  </Typography>
                  <Typography variant="h6">12</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-yellow-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Pending Reviews
                  </Typography>
                  <Typography variant="h6">3</Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Recent Activity */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>Recent Activity</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <MessageSquare size={16} className="text-blue-500" />
                <Typography variant="subtitle2">New Message</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Tech Components Ltd sent you a new price quote
              </Typography>
              <Typography variant="caption" color="text.secondary">
                2 hours ago
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <Star size={16} className="text-yellow-500" />
                <Typography variant="subtitle2">New Review</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                You received a 5-star rating from Global Materials Inc
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Yesterday
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <Truck size={16} className="text-green-500" />
                <Typography variant="subtitle2">Order Update</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Order #12345 has been shipped and is on its way
              </Typography>
              <Typography variant="caption" color="text.secondary">
                2 days ago
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
};

export default AGTSupplierFinder;