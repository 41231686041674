import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Copy, Check } from 'lucide-react';
import Prism from 'prismjs';

const AGTMakerCode = ({ code, language, lineDelay = 40, isNew = false, baseDelay = 0 }) => {
  const codeRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const [lines, setLines] = useState([]);
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (codeRef.current) {
      // Dividir el código en líneas y pre-procesar con Prism
      const codeLines = code.split('\n');
      const highlightedLines = codeLines.map(line => {
        const tempElement = document.createElement('code');
        tempElement.className = `language-${getLanguageClass(language)}`;
        tempElement.textContent = line;
        Prism.highlightElement(tempElement);
        return tempElement.innerHTML;
      });
      
      setLines(highlightedLines);
      setIsHighlighted(true);
    }
  }, [code, language]);

  const getLanguageClass = (lang) => {
    const languageMap = {
      'js': 'javascript',
      'jsx': 'javascript',
      'ts': 'typescript',
      'tsx': 'typescript',
      'py': 'python',
      'python': 'python',
      'java': 'java',
      'cpp': 'cpp',
      'c++': 'cpp',
      'html': 'markup',
      'css': 'css',
      'json': 'json',
      'yaml': 'yaml',
      'yml': 'yaml',
      'sql': 'sql',
      'shell': 'bash',
      'bash': 'bash',
      'sh': 'bash',
      'markdown': 'markdown',
      'md': 'markdown',
      'xml': 'markup',
      'txt': 'plain',
      'text': 'plain',
      'plain': 'plain'
    };
    
    return languageMap[lang?.toLowerCase()] || 'plain';
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Error copying code:', error);
    }
  };

  // Estilo para la animación de cada línea
  const lineStyle = (index) => ({
    opacity: isNew ? 0 : 1,
    animation: isNew ? 'textFadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards' : 'none',
    animationDelay: isNew ? `${baseDelay + index * lineDelay}ms` : '0ms',
  });

  return (
    <Box 
      className="relative prism-code"
      sx={{
        my: 3,
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: 'rgb(40, 44, 52)',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Box 
        sx={{
          px: 3,
          py: 1.5,
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
        }}
      >
        <span style={{ 
          color: 'rgba(255, 255, 255, 0.7)', 
          fontSize: '0.875rem', 
          fontWeight: 500,
          opacity: isNew ? 0 : 1,
          animation: isNew ? 'textFadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards' : 'none',
          animationDelay: `${baseDelay}ms`
        }}>
          {language || 'plain'}
        </span>
        <button
          onClick={handleCopyClick}
          style={{
            padding: '6px',
            borderRadius: '4px',
            backgroundColor: isCopied ? 'rgba(34, 197, 94, 0.2)' : 'rgba(255, 255, 255, 0.1)',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.2s ease',
            opacity: isNew ? 0 : 1,
            animation: isNew ? 'textFadeIn 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards' : 'none',
            animationDelay: `${baseDelay}ms`
          }}
          onMouseEnter={(e) => !isCopied && (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.2)')}
          onMouseLeave={(e) => !isCopied && (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)')}
        >
          {isCopied ? (
            <Check size={16} style={{ color: 'rgb(34, 197, 94)' }} />
          ) : (
            <Copy size={16} style={{ color: 'rgba(255, 255, 255, 0.7)' }} />
          )}
        </button>
      </Box>

      <Box 
        sx={{
          p: 3,
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
            },
          },
        }}
      >
        <pre style={{ margin: 0, background: 'transparent' }}>
          <code
            ref={codeRef}
            className={`language-${getLanguageClass(language)}`}
            style={{ 
              fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
              fontSize: '0.875rem',
              lineHeight: 1.7,
              tabSize: 2,
            }}
          >
            {lines.map((line, index) => (
              <div 
                key={index}
                style={lineStyle(index)}
                dangerouslySetInnerHTML={{ __html: line || '&#8203;' }}
              />
            ))}
          </code>
        </pre>
      </Box>
    </Box>
  );
};

export default React.memo(AGTMakerCode);