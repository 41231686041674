import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Slider,
  TextField,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  Badge
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  DollarSign, 
  TrendingUp, 
  TrendingDown,
  Calculator,
  LineChart,
  BarChart2,
  Download,
  AlertCircle,
  Check,
  Target,
  Activity,
  Users,
  ShoppingCart,
  PieChart,
  Lock,
  Zap,
  Package,
  RefreshCw,
  Settings,
  Archive
} from 'lucide-react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer, 
  LineChart as RechartsLineChart, 
  Line,
  Legend,
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart as RechartsPieChart
} from 'recharts';

import PricingStrategyService from '../../services/AGTUps-product-services/pricingStrategyService';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const MetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1,
  borderRadius: theme.spacing(2),
}));

const PriceSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-markLabel': {
    fontSize: '0.75rem',
  },
  '& .MuiSlider-markLabelActive': {
    color: theme.palette.primary.main,
  },
}));

const COLORS = {
  primary: '#4f46e5',
  secondary: '#0891b2',
  success: '#059669',
  warning: '#eab308',
  error: '#dc2626'
};

const PricingStrategy = ({ selectedProduct }) => {
  const [currentPrice, setCurrentPrice] = useState(0);
  const [pricingModel, setPricingModel] = useState('fixed');
  const [marginTarget, setMarginTarget] = useState(40);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [priceHistory, setPriceHistory] = useState(null);
  const [optimalPrice, setOptimalPrice] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      loadPricingData();
    }
  }, [selectedProduct]);

  const loadPricingData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [strategyResponse, historyResponse] = await Promise.all([
        PricingStrategyService.getPricingStrategiesByProduct(selectedProduct.id),
        PricingStrategyService.analyzePriceHistory(selectedProduct.id)
      ]);

      setStrategyData(strategyResponse.data);
      setPriceHistory(historyResponse.data);
      
      if (strategyResponse.data) {
        setCurrentPrice(strategyResponse.data.currentPrice);
        setPricingModel(strategyResponse.data.pricingModel);
        setMarginTarget(strategyResponse.data.marginTarget);
      } else {
        setCurrentPrice(selectedProduct.price);
      }

      setLastUpdated(new Date());

    } catch (error) {
      setError(`Error loading pricing data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = async (event, newValue) => {
    setCurrentPrice(newValue);
    calculateOptimalPrice(newValue);
  };

  const calculateOptimalPrice = async (price = currentPrice) => {
    try {
      const marketData = {
        competitorPrices: priceHistory?.competitorPrices || [],
        costData: {
          productionCost: strategyData?.costs?.production || 0,
          operationalCost: strategyData?.costs?.operational || 0
        },
        targetMargin: marginTarget
      };

      const response = await PricingStrategyService.calculateOptimalPrice(
        selectedProduct.id,
        marketData
      );

      setOptimalPrice(response.data);

    } catch (error) {
      setError(`Error calculating optimal price: ${error.message}`);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadPricingData();
      await calculateOptimalPrice();
    } finally {
      setUpdating(false);
    }
  };

  const handleSaveStrategy = async () => {
    try {
      setLoading(true);
      setError(null);

      const strategyUpdate = {
        productId: selectedProduct.id,
        currentPrice,
        pricingModel,
        marginTarget,
        basePrice: selectedProduct.price,
        status: 'active'
      };

      if (strategyData?.strategyId) {
        await PricingStrategyService.updatePricingStrategy(
          strategyData.strategyId,
          selectedProduct.id,
          strategyUpdate
        );
      } else {
        await PricingStrategyService.createPricingStrategy(strategyUpdate);
      }

      await loadPricingData();

    } catch (error) {
      setError(`Error saving pricing strategy: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleExportData = async () => {
    try {
      const data = {
        strategy: strategyData,
        priceHistory,
        optimalPrice,
        product: selectedProduct,
        exportDate: new Date().toISOString()
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `pricing-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      setError(`Error exporting data: ${error.message}`);
    }
  };

  const formattedPriceHistory = useMemo(() => {
    if (!priceHistory) return null;
    return PricingStrategyService.formatChartData(priceHistory);
  }, [priceHistory]);

  if (!selectedProduct) {
    return (
      <StyledPaper>
        <Box className="text-center py-8">
          <Package size={48} className="mx-auto mb-4 text-gray-400" />
          <Typography variant="h6" gutterBottom>
            No Product Selected
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Please select a product to view pricing strategy
          </Typography>
        </Box>
      </StyledPaper>
    );
  }

  return (
    <Box className="space-y-6">
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          action={
            <Button color="inherit" size="small" onClick={() => setError(null)}>
              DISMISS
            </Button>
          }
        >
          {error}
        </Alert>
      )}

<Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {updating && <LoadingOverlay><CircularProgress /></LoadingOverlay>}
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography variant="h5">{selectedProduct.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Price Optimization
                </Typography>
              </Box>
              <Box className="flex gap-2">
                <Tooltip title={lastUpdated ? `Last updated: ${lastUpdated.toLocaleString()}` : 'Never updated'}>
                  <IconButton 
                    onClick={handleRefreshData} 
                    disabled={updating || loading}
                  >
                    <RefreshCw 
                      size={20} 
                      className={updating ? 'animate-spin' : ''} 
                    />
                  </IconButton>
                </Tooltip>
                <IconButton onClick={handleExportData} disabled={loading}>
                  <Download size={20} />
                </IconButton>
                <IconButton disabled={loading}>
                  <Settings size={20} />
                </IconButton>
              </Box>
            </Box>

            <Box className="mb-6">
              <Box className="flex justify-between items-center mb-2">
                <Typography variant="subtitle2">Current Price Point</Typography>
                <Typography variant="h5" color="primary">
                  ${currentPrice.toFixed(2)}
                </Typography>
              </Box>
              <PriceSlider
                value={currentPrice}
                onChange={handlePriceChange}
                min={strategyData?.minimumPrice || selectedProduct.price * 0.5}
                max={strategyData?.maximumPrice || selectedProduct.price * 1.5}
                step={0.01}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `$${value}`}
                marks={[
                  {
                    value: strategyData?.minimumPrice || selectedProduct.price * 0.5,
                    label: 'Min'
                  },
                  {
                    value: optimalPrice?.suggested || selectedProduct.price,
                    label: 'Optimal'
                  },
                  {
                    value: strategyData?.maximumPrice || selectedProduct.price * 1.5,
                    label: 'Max'
                  }
                ]}
                disabled={loading}
              />
            </Box>

            {optimalPrice && (
              <Alert 
                severity={currentPrice === optimalPrice.suggested ? "success" : "info"}
                icon={currentPrice === optimalPrice.suggested ? <Check /> : <AlertCircle />}
                className="mb-4"
              >
                {currentPrice === optimalPrice.suggested 
                  ? "You're at the optimal price point!" 
                  : `AI suggests optimal price point: $${optimalPrice.suggested}`}
              </Alert>
            )}

            <Box className="h-64">
              {priceHistory ? (
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={formattedPriceHistory.priceHistory.data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <RechartsTooltip
                      contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        border: 'none',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="price"
                      stroke={COLORS.primary}
                      fill={COLORS.primary}
                      fillOpacity={0.1}
                      name="Price History"
                    />
                    <Area
                      type="monotone"
                      dataKey="optimal"
                      stroke={COLORS.success}
                      strokeDasharray="5 5"
                      fill="none"
                      name="Optimal Price"
                    />
                    <Legend />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <Box className="h-full flex items-center justify-center">
                  <Typography color="text.secondary">
                    No price history available
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Projected Metrics</Typography>
                {optimalPrice ? (
                  <Box className="space-y-4">
                    <MetricCard>
                      <Box className="flex items-center gap-2 mb-1">
                        <ShoppingCart size={20} className="text-blue-500" />
                        <Typography variant="body2">Sales Volume</Typography>
                      </Box>
                      <Typography variant="h6">
                        {optimalPrice.metrics.estimatedVolume} units
                      </Typography>
                      <Box className="flex items-center gap-1 mt-1">
                        {optimalPrice.metrics.volumeChange >= 0 ? (
                          <TrendingUp size={14} className="text-green-500" />
                        ) : (
                          <TrendingDown size={14} className="text-red-500" />
                        )}
                        <Typography 
                          variant="caption" 
                          color={optimalPrice.metrics.volumeChange >= 0 ? "success.main" : "error"}
                        >
                          {Math.abs(optimalPrice.metrics.volumeChange)}% vs current
                        </Typography>
                      </Box>
                    </MetricCard>

                    <MetricCard>
                      <Box className="flex items-center gap-2 mb-1">
                        <DollarSign size={20} className="text-green-500" />
                        <Typography variant="body2">Revenue</Typography>
                      </Box>
                      <Typography variant="h6">
                        ${optimalPrice.metrics.potentialRevenue.toLocaleString()}
                      </Typography>
                      <Box className="flex items-center gap-1 mt-1">
                        {optimalPrice.metrics.revenueChange >= 0 ? (
                          <TrendingUp size={14} className="text-green-500" />
                        ) : (
                          <TrendingDown size={14} className="text-red-500" />
                        )}
                        <Typography 
                          variant="caption" 
                          color={optimalPrice.metrics.revenueChange >= 0 ? "success.main" : "error"}
                        >
                          {Math.abs(optimalPrice.metrics.revenueChange)}% vs current
                        </Typography>
                      </Box>
                    </MetricCard>

                    <MetricCard>
                      <Box className="flex items-center gap-2 mb-1">
                        <Target size={20} className="text-purple-500" />
                        <Typography variant="body2">Profit Margin</Typography>
                      </Box>
                      <Typography variant="h6">
                        {optimalPrice.metrics.expectedMargin}%
                      </Typography>
                      <Box className="flex items-center gap-1 mt-1">
                        {optimalPrice.metrics.marginChange >= 0 ? (
                          <TrendingUp size={14} className="text-green-500" />
                        ) : (
                          <TrendingDown size={14} className="text-red-500" />
                        )}
                        <Typography 
                          variant="caption" 
                          color={optimalPrice.metrics.marginChange >= 0 ? "success.main" : "error"}
                        >
                          {Math.abs(optimalPrice.metrics.marginChange)}% vs current
                        </Typography>
                      </Box>
                    </MetricCard>
                  </Box>
                ) : (
                  <Box className="p-4 text-center">
                    <CircularProgress size={24} />
                  </Box>
                )}
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Pricing Model</Typography>
                <FormControl fullWidth className="mb-4">
                  <InputLabel>Pricing Strategy</InputLabel>
                  <Select
                    value={pricingModel}
                    label="Pricing Strategy"
                    onChange={(e) => setPricingModel(e.target.value)}
                    disabled={loading}
                  >
                    <MenuItem value="fixed">Fixed Pricing</MenuItem>
                    <MenuItem value="dynamic">Dynamic Pricing</MenuItem>
                    <MenuItem value="tiered">Tiered Pricing</MenuItem>
                    <MenuItem value="subscription">Subscription Based</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Target Profit Margin (%)"
                  type="number"
                  value={marginTarget}
                  onChange={(e) => setMarginTarget(e.target.value)}
                  disabled={loading}
                  InputProps={{
                    endAdornment: "%"
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSaveStrategy}
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Save Strategy'
                  )}
                </Button>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Box className="flex justify-between items-center mb-6">
              <Typography variant="h6">Competitor Price Analysis</Typography>
              <Box className="flex gap-2">
                <Tooltip title="Download Report">
                  <IconButton onClick={handleExportData} disabled={loading}>
                    <Download size={20} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Premium Features">
                  <IconButton disabled={loading}>
                    <Lock size={20} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box className="h-80">
                  {priceHistory?.competitorPrices ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={priceHistory.competitorPrices}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip 
                          formatter={(value) => [`$${value}`, 'Price']}
                        />
                        <Bar dataKey="price" fill={COLORS.primary}>
                          {priceHistory.competitorPrices.map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`}
                              fill={entry.name === selectedProduct.name ? COLORS.primary : COLORS.secondary}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box className="h-full flex items-center justify-center">
                      <Typography color="text.secondary">
                        No competitor data available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="space-y-4">
                  <Box className="p-4 bg-blue-50 rounded-lg">
                    <Box className="flex items-center gap-2 mb-2">
                      <Activity size={20} className="text-blue-500" />
                      <Typography variant="subtitle2">Market Position</Typography>
                    </Box>
                    <Typography variant="body2">
                      {optimalPrice ? (
                        `Your price point is ${optimalPrice.marketAnalysis.positionDescription}. 
                         ${optimalPrice.marketAnalysis.recommendation}`
                      ) : (
                        'Market position analysis not available'
                      )}
                    </Typography>
                  </Box>
                  <Box className="p-4 bg-green-50 rounded-lg">
                    <Box className="flex items-center gap-2 mb-2">
                      <Zap size={20} className="text-green-500" />
                      <Typography variant="subtitle2">Opportunity</Typography>
                    </Box>
                    <Typography variant="body2">
                      {optimalPrice?.marketAnalysis.opportunity || 'Opportunity analysis not available'}
                    </Typography>
                  </Box>
                  {optimalPrice?.marketAnalysis.alert && (
                    <Alert 
                      severity="warning"
                      icon={<AlertCircle size={20} />}
                    >
                      {optimalPrice.marketAnalysis.alert}
                    </Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Box className="flex justify-between items-center mb-4">
              <Typography variant="h6">Price History Analysis</Typography>
              <Box className="flex gap-2">
                <Chip 
                  icon={<TrendingUp size={16} />}
                  label={formattedPriceHistory?.trend || 'No trend'}
                  color={formattedPriceHistory?.trend === 'increasing' ? 'success' : 'default'}
                />
                <Chip 
                  icon={<Activity size={16} />}
                  label={`Volatility: ${formattedPriceHistory?.volatility || 'N/A'}`}
                  variant="outlined"
                />
              </Box>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <Box className="h-64">
                  {formattedPriceHistory ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={formattedPriceHistory.priceHistory.data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis domain={['auto', 'auto']} />
                        <RechartsTooltip />
                        <Line
                          type="monotone"
                          dataKey="price"
                          stroke={COLORS.primary}
                          strokeWidth={2}
                          dot={{ r: 4 }}
                          name="Price"
                        />
                        <Line
                          type="monotone"
                          dataKey="margin"
                          stroke={COLORS.success}
                          strokeWidth={2}
                          dot={{ r: 4 }}
                          name="Margin %"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box className="h-full flex items-center justify-center">
                      <Typography color="text.secondary">
                        No price history available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Box className="space-y-4">
                  <MetricCard>
                    <Typography variant="subtitle2" gutterBottom>Price Range</Typography>
                    <Box className="flex justify-between items-center">
                      <Box>
                        <Typography variant="caption" color="text.secondary">Lowest</Typography>
                        <Typography variant="h6">
                          ${formattedPriceHistory?.summary.lowestPrice || 'N/A'}
                        </Typography>
                      </Box>
                      <Box className="text-right">
                        <Typography variant="caption" color="text.secondary">Highest</Typography>
                        <Typography variant="h6">
                          ${formattedPriceHistory?.summary.highestPrice || 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                  </MetricCard>

                  <MetricCard>
                    <Typography variant="subtitle2" gutterBottom>Performance</Typography>
                    <Box className="space-y-2">
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Average Margin
                        </Typography>
                        <Typography variant="h6">
                          {formattedPriceHistory?.summary.averageMargin || 'N/A'}%
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Price Stability
                        </Typography>
                        <Typography variant="h6">
                          {formattedPriceHistory?.summary.stability || 'N/A'}%
                        </Typography>
                      </Box>
                    </Box>
                  </MetricCard>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingStrategy;