import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/agtups/market`;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Market Analysis API Error:', error);
    const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
    return Promise.reject(new Error(errorMessage));
  }
);

class MarketAnalysisService {
  // Crear nuevo análisis de mercado
  static async createMarketAnalysis(analysisData) {
    try {
      const response = await api.post('/', analysisData);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating market analysis: ${error.message}`);
    }
  }

  // Obtener análisis por ID
  static async getMarketAnalysisById(analysisId, productId) {
    try {
      const response = await api.get(`/${analysisId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching market analysis: ${error.message}`);
    }
  }

  // Obtener análisis por producto
  static async getMarketAnalysisByProduct(productId, params = {}) {
    try {
      const response = await api.get(`/product/${productId}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching market analysis for product: ${error.message}`);
    }
  }

  // Obtener análisis de competidores
  static async getCompetitorAnalysis(productId) {
    try {
      const response = await api.get(`/competitors/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching competitor analysis: ${error.message}`);
    }
  }

  // Obtener tendencias de mercado
  static async getMarketTrends(productId) {
    try {
      const response = await api.get(`/trends/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching market trends: ${error.message}`);
    }
  }

  // Actualizar análisis de mercado
  static async updateMarketAnalysis(analysisId, productId, updateData) {
    try {
      const response = await api.put(`/${analysisId}/${productId}`, updateData);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating market analysis: ${error.message}`);
    }
  }

  // Eliminar análisis de mercado
  static async deleteMarketAnalysis(analysisId, productId) {
    try {
      const response = await api.delete(`/${analysisId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting market analysis: ${error.message}`);
    }
  }

  // Formatear datos de análisis para gráficos
  static formatChartData(analysisData) {
    return {
      marketShare: {
        labels: analysisData.map(data => data.period),
        datasets: [{
          label: 'Market Share %',
          data: analysisData.map(data => data.marketShare),
          borderColor: '#4f46e5',
          tension: 0.1
        }]
      },
      competitors: analysisData.reduce((acc, data) => {
        data.competitorAnalysis.forEach(comp => {
          if (!acc[comp.name]) {
            acc[comp.name] = [];
          }
          acc[comp.name].push(comp.marketShare);
        });
        return acc;
      }, {})
    };
  }

  // Validar datos de análisis
  static validateAnalysisData(analysisData) {
    const errors = [];

    if (!analysisData.productId) {
      errors.push('Product ID is required');
    }

    if (typeof analysisData.marketShare !== 'number') {
      errors.push('Market share must be a number');
    }

    if (!Array.isArray(analysisData.competitorAnalysis)) {
      errors.push('Competitor analysis must be an array');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }
}

export default MarketAnalysisService;
