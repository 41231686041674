import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Tooltip, Paper } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  LineChart,
  Receipt,
  Calendar,
  PieChart,
  Upload,
  RefreshCcw,
  Download,
  Plus,
} from 'lucide-react';

import BalanceOverview from './AGTCashFlow-products/BalanceOverview';
import RecentTransactions from './AGTCashFlow-products/RecentTransactions';
import UpcomingPayments from './AGTCashFlow-products/UpcomingPayments';
import ExpenseCategories from './AGTCashFlow-products/ExpenseCategories';
import QuickUpload from './AGTCashFlow-products/QuickUpload';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'light' 
    ? 'rgba(0, 0, 0, 0.1)'
    : 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '50px',
  margin: '0 auto',
  width: 'fit-content',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
    maxWidth: 'calc(100% - 32px)',
  },
}));

const SlidingBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 5,
  left: 0,
  height: 'calc(100% - 10px)',
  borderRadius: '50px',
  backgroundColor: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.1)'
    : 'rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease, width 0.3s ease',
}));

const NavButton = styled('button')(({ theme, $active }) => ({
  color: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.87)'
    : 'rgba(255, 255, 255, 0.87)',
  margin: theme.spacing(0, 0.5),
  textTransform: 'capitalize',
  borderRadius: '50px',
  zIndex: 1,
  padding: theme.spacing(0.5, 2),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    opacity: 0.8,
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.5,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0.25),
    fontSize: '0.8rem',
  },
}));

const AGTCashFlow = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [slideStyle, setSlideStyle] = useState({});
  const buttonRefs = useRef([]);
  const containerRef = useRef(null);
  const lastNavigationTime = useRef(Date.now());

  const navigationTabs = [
    { id: 0, icon: LineChart, label: 'Balance Overview', disabled: false },
    { id: 1, icon: Receipt, label: 'Recent Transactions', disabled: false },
    { id: 2, icon: Calendar, label: 'Upcoming Payments', disabled: false },
    { id: 3, icon: PieChart, label: 'Expense Categories', disabled: false },
    { id: 4, icon: Upload, label: 'Quick Upload', disabled: false }
  ];

  const updateSlideStyle = useCallback(() => {
    requestAnimationFrame(() => {
      if (buttonRefs.current[activeTab] && containerRef.current) {
        const activeButton = buttonRefs.current[activeTab];
        const buttonRect = activeButton.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const offsetLeft = buttonRect.left - containerRect.left;

        setSlideStyle({
          width: `${buttonRect.width}px`,
          transform: `translateX(${offsetLeft}px)`,
        });
      }
    });
  }, [activeTab]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateSlideStyle);
    };

    updateSlideStyle();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateSlideStyle]);

  const handleTabChange = useCallback((newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      lastNavigationTime.current = now;
      setActiveTab(newValue);
    }
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <BalanceOverview />;
      case 1:
        return <RecentTransactions />;
      case 2:
        return <UpcomingPayments />;
      case 3:
        return <ExpenseCategories />;
      case 4:
        return <QuickUpload />;
      default:
        return null;
    }
  };

  return (
    <Box className="space-y-4">
      <Box className="flex justify-between items-center mb-4">
        <NavigationContainer ref={containerRef}>
          <SlidingBackground style={slideStyle} />
          {navigationTabs.map((tab, index) => {
            const Icon = tab.icon;
            return (
              <NavButton
                key={tab.id}
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={() => handleTabChange(tab.id)}
                disabled={tab.disabled}
                $active={activeTab === tab.id}
              >
                <Icon size={20} />
                <span>{tab.label}</span>
              </NavButton>
            );
          })}
        </NavigationContainer>

        <Box className="flex gap-2">
          <Tooltip title="Refresh Data">
            <IconButton>
              <RefreshCcw size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Report">
            <IconButton>
              <Download size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Transaction">
            <IconButton color="primary">
              <Plus size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {renderContent()}
    </Box>
  );
};

export default AGTCashFlow;