import React, { useState, useEffect } from 'react';
import { Box, Button, Fade, Snackbar, Alert, IconButton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ArrowLeft } from 'lucide-react';
import Auth from '../auth/Auth';
import HomeLimited from '../home/HomeLimited/HomeLimited';

const FixedContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
  transition: 'background-color 0.3s ease',
}));

const StyledWindowBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
  backdropFilter: 'blur(40px)',
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  fontFamily: "'Inter', sans-serif",
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2.06, 2),
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#464649' : '#C7C7CC'}`,
  height: '69px', 
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: 28,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.98)',
  backdropFilter: 'blur(20px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0px 1px 3px rgba(0, 0, 0, 0.5)'
    : '0px 8px 32px rgba(0, 0, 0, 0.08)',
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.06)'
  }`,
  padding: theme.spacing(2, 3),
  '& .MuiAlert-icon': {
    color: theme.palette.mode === 'dark' ? '#fff' : '#0071e3',
    opacity: 0.9
  },
  '& .MuiAlert-action': {
    padding: theme.spacing(0, 0, 0, 2),
    '& .MuiIconButton-root': {
      padding: 8,
      borderRadius: 20,
      color: theme.palette.mode === 'dark' ? '#fff' : '#0071e3',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 113, 227, 0.1)'
      }
    }
  },
  '& .MuiAlert-message': {
    padding: theme.spacing(1, 0),
    fontSize: '0.95rem'
  }
}));

const ContentArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AuthButton = styled(Button)(({ theme, variant }) => ({
  width: '92px',
  height: '40px',
  borderRadius: '20px',
  padding: theme.spacing(0.5, 2),
  margin: theme.spacing(0, 0.5),
  backgroundColor: variant === 'login' 
    ? theme.palette.mode === 'dark' ? '#0385FF' : '#007BFE'
    : 'transparent',
  color: variant === 'login'
    ? '#FFFFFF'
    : theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  '&:hover': {
    backgroundColor: variant === 'login'
      ? theme.palette.mode === 'dark' ? '#0374E0' : '#006BE0'
      : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
  },
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  minWidth: '92px',
  whiteSpace: 'nowrap',
}));

const BackIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.ui.accent,
  '&:hover': {
    backgroundColor: theme.palette.colors.grouped.bgPrimaryBase,
  },
}));

const LimitedDashboard = ({ onLogin, backgroundType, onBackgroundChange }) => {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAuth, setShowAuth] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [agtService, setAgtService] = useState(null);
  const [storedMessage, setStoredMessage] = useState(null);
  
  const auth = getAuth();
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setShowAuth(false);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const handleAuthClick = (mode) => {
    setAuthMode(mode);
    setShowAuth(true);
  };

  const handleAuthClose = () => {
    setShowAuth(false);
    setAgtService(null);
    setStoredMessage(null);
  };

  const handleSendMessage = (message) => {
    console.log('Message sent:', message);
  };

  const handleAgtServiceClick = (service) => {
    setAgtService(service);
    setShowAuth(true);
    setAuthMode('login');
  };

  const handleAuthRequired = (message) => {
    setStoredMessage(message);
    setShowAuth(true);
    setAuthMode('login');
  };

  const handleLogin = (user, isAuthorized) => {
    setUser(user);
    setShowAuth(false);
    if (agtService) {
      console.log(`Logged in to ${agtService} service`);
      setAgtService(null);
    }
    if (storedMessage) {
      handleSendMessage(storedMessage);
      setStoredMessage(null);
    }
    onLogin(user, isAuthorized);
  };

  return (
    <FixedContainer>
      <StyledWindowBar>
        <TopBar>
          <Box>
            {(showAuth || agtService) && (
              <BackIconButton onClick={handleAuthClose}>
                <ArrowLeft />
              </BackIconButton>
            )}
          </Box>
          <Box>
            {!user && !showAuth && (
              <>
                <AuthButton 
                  variant="login" 
                  onClick={() => handleAuthClick('login')}
                >
                  Log in
                </AuthButton>
                <AuthButton 
                  variant="signup"
                  onClick={() => handleAuthClick('signup')}
                >
                  Sign up
                </AuthButton>
              </>
            )}
            {user && (
              <AuthButton 
                variant="login" 
                onClick={onLogin}
              >
                Continue
              </AuthButton>
            )}
          </Box>
        </TopBar>
        <ContentArea>
          <Fade in={true} timeout={300}>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {showAuth ? (
                <Auth 
                  mode={authMode} 
                  onClose={handleAuthClose} 
                  onLogin={handleLogin}
                  onToggleMode={() => setAuthMode(authMode === 'login' ? 'signup' : 'login')}
                />
              ) : (
                <Box sx={{ flexGrow: 1, overflow: 'hidden', mb: 2, width: '100%' }}>
                  <HomeLimited 
                    onSendMessage={handleSendMessage} 
                    onAgtEduClick={() => handleAgtServiceClick('AGT Edu')}
                    onAgtUpsClick={() => handleAgtServiceClick('AGT Ups')}
                    onAuthRequired={handleAuthRequired}
                    backgroundType={backgroundType}
                    onBackgroundChange={onBackgroundChange}
                  />
                </Box>
              )}
            </Box>
          </Fade>
        </ContentArea>
      </StyledWindowBar>
      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'transparent',
            padding: 0,
            minWidth: 'auto',
            boxShadow: 'none'
          }
        }}
      >
        <StyledAlert 
          onClose={() => setErrorMessage('')} 
          severity="error"
        >
          {errorMessage}
        </StyledAlert>
      </Snackbar>
    </FixedContainer>
  );
};

export default LimitedDashboard;