import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, List, ListItem, ListItemText, Typography, IconButton, CircularProgress, Snackbar, Alert, Avatar, useMediaQuery, Menu, MenuItem, TextField, Tooltip, Drawer, InputAdornment, Chip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Menu as MenuIcon, Plus, Search, Calendar, Filter, XCircle, RefreshCw } from 'lucide-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { debounce, throttle } from 'lodash';
import { format, isAfter, isBefore, parseISO, isToday, isYesterday, isThisWeek, isThisMonth, isThisYear, subDays, startOfMonth, endOfMonth, isValid } from 'date-fns';
import { getChats, getChatMessages, sendMessageToAGT, createNewChat, getChatContext, updateChatTitle, deleteChat, updateChatTimestamp, getUserPersonalityPreference, setUserPersonalityPreference } from '../../services/AGTService';
import ThinkingIcon from '../messages/ThinkingIcon';
import PromptCards from '../input/PromptCards';
import MagicalMessage from '../messages/MagicalMessage';
import AGTMaker from '../AGTMaker/AGTMaker';
import OptimizedInput from '../input/OptimizedInput';
import InlineImageSearch from '../../search/InlineImageSearch';
import FileAttachment from '../input/FileAttachment';
import CategorizedChatList from '../list/CategorizedChatList';
import { getThemeStyles } from '../../themes/themeConfig';

const debouncedLoadMore = debounce((callback) => {
  callback();
}, 150);

const SCROLL_THRESHOLD = 100;
const BATCH_SIZE = 10;
const SCROLL_SMOOTH_DURATION = 300;
const INTERSECTION_THRESHOLD = 0.5;

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  ...getThemeStyles('ChatContainer', theme.palette.mode),
}));

// Arreglo para Sidebar
const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isOpen' && prop !== '$isMobile',
})(({ theme, $isOpen, $isMobile }) => {
  const themeStyles = getThemeStyles('Sidebar', theme.palette.mode);
  
  return {
    width: '300px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid var(--system-grey3) !important`,
    borderRadius: '0 !important',
    backdropFilter: $isMobile ? 'none' : 'blur(10px)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 1200,
    transition: 'all 0.3s ease',
    overflow: 'hidden', // Previene scroll horizontal
    transform: $isOpen ? 'translateX(0)' : 'translateX(-300px)',
    visibility: $isOpen ? 'visible' : 'hidden',
    opacity: $isOpen ? 1 : 0,
    ...themeStyles,
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...getThemeStyles('AllChatsText', theme.palette.mode)
    },
    '&&': {
      borderRight: '1px solid var(--system-grey3)',
      borderRadius: 0
    }
  };
});

const SidebarContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',           // Añadido
  flexDirection: 'column',   // Añadido
  overflow: 'hidden'         // Añadido
}));

const SidebarHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0px',
  padding: '12px',
  '& .header-content': {
    paddingLeft: '0px',
  },
  ...getThemeStyles('SidebarHeader', theme.palette.mode),
}));

const ButtonsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '4px',
  padding: '0 8px',
  '& > button': {
    flex: '0 0 36px'
  }
});

const MenuButton = styled(IconButton)(({ theme }) => ({
  color: '#0385FF',
  padding: '8px',
  minWidth: '36px',
  width: '36px',
  height: '36px',
  backgroundColor: 'rgba(3, 133, 255, 0.04)',
  '&:hover': {
    backgroundColor: 'rgba(3, 133, 255, 0.08)',
  },
  '& svg': {
    width: '20px',
    height: '20px',
  }
}));

const ChatList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  paddingTop: '16px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  ...getThemeStyles('ChatList', theme.palette.mode),
}));

const ChatArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isSidebarOpen' && prop !== '$isMobile' && prop !== '$isAGTMakerOpen',
})(({ theme, $isSidebarOpen, $isMobile, $isAGTMakerOpen }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: $isAGTMakerOpen && !$isMobile ? '50%' : '100%',
  transition: 'all 0.3s ease',
  paddingTop: '56px',
  opacity: 1,
  position: $isAGTMakerOpen && !$isMobile ? 'absolute' : 'relative',
  left: $isAGTMakerOpen && !$isMobile ? 0 : 'auto',
  [theme.breakpoints.up('md')]: {
    paddingLeft: $isSidebarOpen ? '300px' : '0',
    transition: 'padding-left 0.3s ease, width 0.3s ease',
  },
  ...getThemeStyles('ChatArea', theme.palette.mode),
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden', // Previene scroll horizontal
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  maxWidth: '800px',
  padding: '20px',
  '& pre': {
    maxWidth: '100%',
    overflowX: 'auto', // Permite scroll solo en bloques de código
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto'
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 0',
  },
  ...getThemeStyles('MessagesContainer', theme.palette.mode),
}));

const MessageBarContainer = styled(Box)(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  ...getThemeStyles('MessageBarContainer', theme.palette.mode),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  borderRadius: '30px',
  padding: '2px 16px',
  overflow: 'hidden',
  '& textarea': {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  },
  ...getThemeStyles('InputContainer', theme.palette.mode),
}));

const InputRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...getThemeStyles('InputRow', theme.palette.mode),
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  marginTop: '8px',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
  '& a': {
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  ...getThemeStyles('InfoText', theme.palette.mode),
}));

const MobileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  backgroundColor: 'transparent',
  overflow: 'hidden', // Previene scroll horizontal
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  ...getThemeStyles('MobileHeader', theme.palette.mode),
}));

const DesktopHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  ...getThemeStyles('DesktopHeader', theme.palette.mode),
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  ...getThemeStyles('ErrorMessage', theme.palette.mode),
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '30px',
  ...getThemeStyles('RetryButton', theme.palette.mode),
}));

const FilterChip = styled('div')(({ theme }) => ({
  margin: '2px 8px 8px 0',
  height: '28px',
  borderRadius: '14px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color 0.2s ease',
  ...getThemeStyles('FilterChip', theme.palette.mode),
}));

const SearchInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: '30px',
  marginBottom: '16px',
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden', // Previene scroll horizontal
  ...getThemeStyles('SearchInputContainer', theme.palette.mode),
}));

const StyledInput = styled('input')(({ theme }) => ({
  flex: 1,
  border: 'none',
  background: 'none',
  outline: 'none',
  fontSize: '14px',
  ...getThemeStyles('StyledInput', theme.palette.mode),
}));

const ClearAllButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  textTransform: 'none',
  padding: '4px 8px',
  minWidth: 'auto',
  marginLeft: 'auto',
  ...getThemeStyles('ClearAllButton', theme.palette.mode),
}));

const AGTMakerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '50%',
  height: '100%',
  transition: 'transform 0.3s ease',
  transform: 'translateX(100%)',
  zIndex: 1300,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const BlueIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#0385FF',
  color: 'white',
  padding: '8px',
  minWidth: '36px',
  width: '36px',
  height: '36px',
  '&:hover': {
    backgroundColor: '#0371db',
  },
  '& svg': {
    width: '20px',
    height: '20px',
  }
}));

const MoreFiltersButton = styled(IconButton)(({ theme }) => ({
  width: '28px',
  height: '28px',
  minWidth: '28px',
  padding: 0,
  margin: '2px 8px 8px 0',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.04)' : 'rgba(0, 0, 0, 0.04)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '14px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)',
  },
  '&.selected': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
  },
  '& svg': {
    width: '16px',
    height: '16px',
  }
}));

const SidebarOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1100,
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  '&.visible': {
    opacity: 1,
    visibility: 'visible',
  }
}));

const hasAGTTag = (content) => {
  if (!content) return false;
  try {
    const parsed = JSON.parse(content);
    if (parsed.text) {
      return typeof parsed.text === 'string' && parsed.text.includes('<agt>');
    }
    return typeof parsed === 'string' && parsed.includes('<agt>');
  } catch {
    return typeof content === 'string' && content.includes('<agt>');
  }
};

const ChatViewNest = React.memo(({ user, isAuthorized, themeMode, onSendMessage, isLimited, initialView, messages: initialMessages, isLoading: initialIsLoading, onNavigate, mostRecentChatId, onOpenAGTPromo, userName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem('sidebarOpen');
    return savedState !== null ? JSON.parse(savedState) : !isMobile;
  });
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [messages, setMessages] = useState(initialMessages);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [error, setError] = useState(null);
  const [chatContext, setChatContext] = useState({});
  const [chatTitle, setChatTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuChatId, setMenuChatId] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [waitingStartTime, setWaitingStartTime] = useState(null);
  const [isNewChatView, setIsNewChatView] = useState(initialView);
  const [retryAction, setRetryAction] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [lastImageRequestId, setLastImageRequestId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isAGTMakerOpen, setIsAGTMakerOpen] = useState(false);
  const [currentAGTContent, setCurrentAGTContent] = useState('');
  const [agtMakerReadyCallback, setAGTMakerReadyCallback] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    messages: false,
    chatInfo: false
  });
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [rateLimitInfo, setRateLimitInfo] = useState(null);
  const [selectedModel, setSelectedModel] = useState(() => {
    // Si el usuario es PRO, comenzar con AGT Pro (sonnet)
    // Si no, comenzar con AGT Normal (haiku)
    return user?.isPro ? 'claude-3-sonnet-20240229' : 'claude-3-haiku-20240307';
  });
  const [selectedPersonality, setSelectedPersonality] = useState('normal');

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatCache = useRef({});
  const imageSearchCache = useRef({});
  const prevAGTMakerState = useRef(isAGTMakerOpen);
  const userToggledSidebar = useRef(false);
  const isFirstRender = useRef(true);
  const messageUpdateQueue = useRef([]);
  const isProcessingQueue = useRef(false);
  const revealTimeoutRef = useRef(null);
  const loadingRef = useRef(false);

  const revealWordsGradually = useCallback((fullResponse, setterFunction, onComplete) => {
    if (!fullResponse) {
      onComplete();
      return () => {};
    }
  
    const words = fullResponse.split(' ');
    const chunks = [];
    let currentChunk = [];
    let currentLength = 0;
  
    words.forEach(word => {
      currentChunk.push(word);
      currentLength += word.length;
      
      if (currentLength >= 50 || word.includes('\n')) {
        chunks.push(currentChunk.join(' '));
        currentChunk = [];
        currentLength = 0;
      }
    });
  
    if (currentChunk.length > 0) {
      chunks.push(currentChunk.join(' '));
    }
  
    let isAnimating = true;
    let currentText = '';
    let currentChunkIndex = 0;
  
    const animate = () => {
      if (!isAnimating) return;
  
      if (currentChunkIndex < chunks.length) {
        currentText += (currentText ? ' ' : '') + chunks[currentChunkIndex];
  
        setterFunction(prevMessages => {
          const newMessages = [...prevMessages];
          const lastIndex = newMessages.length - 1;
          if (lastIndex >= 0) {
            newMessages[lastIndex] = {
              ...newMessages[lastIndex],
              text: currentText,
              animation: {
                fadeIn: true,
                slideUp: true,
                chunk: chunks[currentChunkIndex],
                chunkIndex: currentChunkIndex
              }
            };
          }
          return newMessages;
        });
  
        currentChunkIndex++;
  
        const chunkLength = chunks[currentChunkIndex - 1].length;
        const baseDelay = 30;
        const maxDelay = 200;
        const delay = Math.min(chunkLength * baseDelay, maxDelay);
  
        setTimeout(animate, delay);
      } else {
        setterFunction(prevMessages => {
          const newMessages = [...prevMessages];
          const lastIndex = newMessages.length - 1;
          if (lastIndex >= 0) {
            newMessages[lastIndex] = {
              ...newMessages[lastIndex],
              text: fullResponse,
              animation: {
                fadeIn: false,
                slideUp: false,
                complete: true
              }
            };
          }
          return newMessages;
        });
        onComplete();
      }
    };
  
    requestAnimationFrame(animate);
  
    return () => {
      isAnimating = false;
    };
  }, []);
    
  const filterCategories = useMemo(() => ({
    main: [
      { name: 'Today', tooltip: 'Show chats from today' },
      { name: 'Yesterday', tooltip: 'Show chats from yesterday' }
    ],
    extra: [
      { name: 'Previous 7 Days', tooltip: 'Show chats from the last week' },
      { name: 'Previous 30 Days', tooltip: 'Show chats from the last month' },
      { name: 'Older', tooltip: 'Show older chats' }
    ]
  }), []);
    
    const saveCurrentChatId = useCallback((chatId) => {
      if (chatId) {
        localStorage.setItem('currentChatId', chatId);
      } else {
        localStorage.removeItem('currentChatId');
      }
    }, []);
    
    const updateChatsOrder = useCallback(() => {
      setChats(prevChats => {
        const sortedChats = [...prevChats].sort((a, b) => {
          const dateA = new Date(a.updatedAt || a.createdAt);
          const dateB = new Date(b.updatedAt || b.createdAt);
          return dateB - dateA;
        });
        return sortedChats;
      });
    }, []);
    
    const handleOpenAGTMaker = useCallback((content, readyCallback) => {
      setCurrentAGTContent(content);
      setIsAGTMakerOpen(true);
      setAGTMakerReadyCallback(() => readyCallback);
    }, []);
    
    const handleCloseAGTMaker = useCallback(() => {
      setIsAGTMakerOpen(false);
      setCurrentAGTContent('');
      setAGTMakerReadyCallback(null);
    }, []);
    
    const handleAGTMakerContentReady = useCallback(() => {
      if (agtMakerReadyCallback) {
        agtMakerReadyCallback();
        setAGTMakerReadyCallback(null);
      }
    }, [agtMakerReadyCallback]);
  
    const selectChat = useCallback(async (chatId) => {
      if (!user || !user.uid || chatId === currentChatId) return;
    
      setIsAGTMakerOpen(false);
      setCurrentAGTContent('');
    
      setCurrentChatId(chatId);
      saveCurrentChatId(chatId);
      setLoadingStates({ messages: true, chatInfo: true });
      setError(null);
      setIsNewChatView(false);
    
      try {
        let chatMessages, context;
    
        if (chatCache.current[chatId]) {
          chatMessages = chatCache.current[chatId].messages;
          context = chatCache.current[chatId].context;
        } else {
          [chatMessages, context] = await Promise.all([
            getChatMessages(user.uid, chatId),
            getChatContext(user.uid, chatId)
          ]);
          chatCache.current[chatId] = { messages: chatMessages, context };
        }
    
        setMessages(chatMessages || []);
        setVisibleMessages((chatMessages || []).slice(-20));
        setChatContext(context || {});
    
        const selectedChat = chats.find(chat => chat.id === chatId);
        if (selectedChat) {
          setChatTitle(selectedChat.title || "New chat");
        } else {
          const response = await getChats(user.uid, { limit: 20 });
          const updatedSelectedChat = response.chats.find(chat => chat.id === chatId);
          setChatTitle(updatedSelectedChat ? (updatedSelectedChat.title || "New chat") : "New chat");
          setChats(response.chats);
          updateChatsOrder();
        }
    
      } catch (error) {
        console.error('Error selecting chat:', error);
        setError('Failed to load chat data. Please try again.');
        setRetryAction(() => () => selectChat(chatId));
        setMessages([]);
        setVisibleMessages([]);
        setChatContext({});
      } finally {
        setLoadingStates({ messages: false, chatInfo: false });
        setTimeout(() => {
          inputRef.current?.focus();
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 100);
      }
    }, [user, chats, currentChatId, saveCurrentChatId, updateChatsOrder]);
    
    const loadChats = useCallback(async (loadMore = false) => {
      if (!user || !user.uid || (loadMore && isNextPageLoading)) return;
      
      setIsNextPageLoading(true);
      setError(null);
      
      try {
        const response = await getChats(user.uid, {
          limit: 20,
          lastEvaluatedKey: loadMore ? lastEvaluatedKey : null
        });
    
        if (!loadMore) {
          setChats(response.chats);
          setPersistentChatState(prev => ({
            ...prev,
            loadedChatIds: response.chats.map(chat => chat.id),
            totalChatsLoaded: response.chats.length
          }));
        } else {
          setChats(prevChats => {
            const newChats = [...prevChats, ...response.chats];
            setPersistentChatState(prev => ({
              ...prev,
              loadedChatIds: newChats.map(chat => chat.id),
              totalChatsLoaded: newChats.length
            }));
            return newChats;
          });
        }
        
        setHasNextPage(response.hasMore);
        setLastEvaluatedKey(response.lastEvaluatedKey);
        
        if (!loadMore && response.chats.length > 0 && mostRecentChatId) {
          selectChat(mostRecentChatId);
        }
    
      } catch (error) {
        console.error('Error loading chats:', error);
        setError('Error loading chats. Please try again.');
        setRetryAction(() => () => loadChats(loadMore));
      } finally {
        setIsNextPageLoading(false);
      }
    }, [user, lastEvaluatedKey, selectChat, mostRecentChatId, isNextPageLoading]);
    
    const handleLoadMore = useCallback(() => {
      if (!isNextPageLoading && hasNextPage) {
        return loadChats(true);
      }
      return Promise.resolve();
    }, [loadChats, isNextPageLoading, hasNextPage]);
    
    const loadChatContext = useCallback(async (chatId) => {
      if (!user || !user.uid) return;
      try {
        const context = await getChatContext(user.uid, chatId);
        setChatContext(context || {});
      } catch (error) {
        console.error('Error loading chat context:', error);
        setError('Error loading chat context. Some conversation history may be missing.');
        setRetryAction(() => () => loadChatContext(chatId));
      }
    }, [user]);
    
    const handleUpdateMessage = useCallback((messageId, newContent) => {
      messageUpdateQueue.current.push({ messageId, newContent });
    
      if (!isProcessingQueue.current) {
        isProcessingQueue.current = true;
        requestAnimationFrame(() => {
          const updates = [...messageUpdateQueue.current];
          messageUpdateQueue.current = [];
    
          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            updates.forEach(update => {
              const index = newMessages.findIndex(msg => msg.id === update.messageId);
              if (index !== -1) {
                newMessages[index] = { ...newMessages[index], text: update.newContent };
              }
            });
            return newMessages;
          });
    
          setVisibleMessages(prevMessages => {
            const newMessages = [...prevMessages];
            updates.forEach(update => {
              const index = newMessages.findIndex(msg => msg.id === update.messageId);
              if (index !== -1) {
                newMessages[index] = { ...newMessages[index], text: update.newContent };
              }
            });
            return newMessages;
          });
    
          isProcessingQueue.current = false;
        });
      }
    }, []);

    const handleNewChatInput = useCallback(async (inputMessage) => {
      if (!user || !user.uid || (!inputMessage.trim() && fileContents.length === 0) || isWaitingForResponse) return;
    
      setIsWaitingForResponse(true);
      setWaitingStartTime(Date.now());
    
      try {
        let chatId = currentChatId;
        if (!chatId) {
          const newChat = await createNewChat(user.uid);
          if (!newChat || !newChat.id) {
            throw new Error('Failed to create new chat');
          }
          chatId = newChat.id;
          setCurrentChatId(chatId);
          saveCurrentChatId(chatId);
          setChatTitle("New chat");
    
          const chatsResponse = await getChats(user.uid, { limit: 20 });
          setChats(prevChats => {
            const newChatList = [
              { ...newChat, name: "New chat", updatedAt: new Date().toISOString() },
              ...prevChats
            ];
            return newChatList;
          });
          setHasNextPage(chatsResponse.hasMore);
          setLastEvaluatedKey(chatsResponse.lastEvaluatedKey);
          updateChatsOrder();
        }
    
        const messageWithFiles = {
          text: inputMessage,
          files: fileContents
        };
    
        const newMessage = { 
          id: Date.now(), 
          text: JSON.stringify(messageWithFiles), 
          isUser: true, 
          timestamp: Date.now(),
          files: selectedFiles.map(file => file.name)
        };
    
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 50);
          return updatedMessages;
        });
    
        setVisibleMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          return updatedMessages;
        });
    
        setIsNewChatView(false);
    
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    
        const response = await sendMessageToAGT(JSON.stringify(messageWithFiles), user.uid, chatId, {}, selectedModel, selectedPersonality, userName);
        if (!response || !response.reply) {
          throw new Error('Invalid response from AGT');
        }
    
        const responseMessage = { 
          id: Date.now(), 
          text: '', 
          isUser: false, 
          timestamp: Date.now() 
        };
        setLastMessageId(responseMessage.id);
        
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, responseMessage];
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 50);
          return updatedMessages;
        });
    
        setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);
    
        if (response.reply.includes('<AGTSearch:image:')) {
          setLastImageRequestId(responseMessage.id);
        } else {
          setLastImageRequestId(null);
        }
    
        const cleanupReveal = revealWordsGradually(
          response.reply,
          (updater) => {
            setMessages(updater);
            setVisibleMessages(updater);
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          },
          () => {
            setIsWaitingForResponse(false);
            setLastMessageId(null);
          }
        );
    
        setChatContext(response.updatedContext || {});
    
        if (response.title) {
          setChatTitle(response.title);
          setChats(prevChats => 
            prevChats.map(chat => 
              chat.id === chatId ? { ...chat, title: response.title, name: response.title } : chat
            )
          );
          updateChatsOrder();
        }
    
        chatCache.current[chatId] = { 
          messages: [newMessage, {...responseMessage, text: response.reply}], 
          context: response.updatedContext || {}
        };
    
        const newUpdatedAt = new Date().toISOString();
        await updateChatTimestamp(user.uid, chatId);
    
        const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
        setChats(updatedChatsResponse.chats);
        setHasNextPage(updatedChatsResponse.hasMore);
        setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);
        updateChatsOrder();
    
        if (hasAGTTag(response.reply)) {
          handleOpenAGTMaker(response.reply, () => {});
        }
    
        setSelectedFiles([]);
        setFileContents([]);
      } catch (error) {
        console.error('Error in handleNewChatInput:', error);
        if (error.response?.status === 429) {
          setRateLimitInfo({
            message: error.response.data.message || 'Has alcanzado el límite de mensajes por hora',
            resetIn: error.response.data.resetIn,
            isPro: error.response.data.isPro
          });
        } else {
          setError('Error al crear nuevo chat o enviar mensaje. Por favor, intenta de nuevo.');
          setRetryAction(() => () => handleNewChatInput(inputMessage));
        }
      } finally {
        setIsWaitingForResponse(false);
        inputRef.current?.focus();
      }
    }, [
      user,
      currentChatId,
      sendMessageToAGT,
      saveCurrentChatId,
      revealWordsGradually,
      handleOpenAGTMaker,
      selectedFiles,
      fileContents,
      updateChatTimestamp,
      updateChatsOrder,
      selectedModel,
      selectedPersonality
    ]);
  
    const handleSendMessage = useCallback(async (messageToSend) => {
      if (!user || !user.uid || (!messageToSend.trim() && fileContents.length === 0) || isWaitingForResponse) return;
    
      setIsWaitingForResponse(true);
      setWaitingStartTime(Date.now());
    
      try {
        if (!currentChatId) {
          await handleNewChatInput(messageToSend);
          return;
        }
    
        const messageWithFiles = {
          text: messageToSend,
          files: fileContents
        };
    
        const newMessage = { 
          id: Date.now(), 
          text: JSON.stringify(messageWithFiles), 
          isUser: true, 
          timestamp: Date.now(),
          files: selectedFiles.map(file => file.name)
        };
    
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 50);
          return updatedMessages;
        });
    
        setVisibleMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          return updatedMessages;
        });
    
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    
        const response = await sendMessageToAGT(JSON.stringify(messageWithFiles), user.uid, currentChatId, chatContext, selectedModel, selectedPersonality, userName);
        if (!response || !response.reply) {
          throw new Error('Invalid response from AGT');
        }
    
        const responseMessage = { 
          id: Date.now(), 
          text: '', 
          isUser: false, 
          timestamp: Date.now() 
        };
    
        setLastMessageId(responseMessage.id);
    
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, responseMessage];
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 50);
          return updatedMessages;
        });
    
        setVisibleMessages(prevMessages => [...prevMessages, responseMessage]);
    
        if (response.reply.includes('<AGTSearch:image:')) {
          setLastImageRequestId(responseMessage.id);
        } else {
          setLastImageRequestId(null);
        }
    
        const cleanupReveal = revealWordsGradually(
          response.reply,
          (updater) => {
            setMessages(updater);
            setVisibleMessages(updater);
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          },
          () => {
            setIsWaitingForResponse(false);
            setLastMessageId(null);
          }
        );
    
        setChatContext(response.updatedContext || {});
    
        chatCache.current[currentChatId] = {
          messages: [...(chatCache.current[currentChatId]?.messages || []), newMessage, {...responseMessage, text: response.reply}],
          context: response.updatedContext || {}
        };
    
        const newUpdatedAt = new Date().toISOString();
        await updateChatTimestamp(user.uid, currentChatId);
    
        const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
        setChats(updatedChatsResponse.chats);
        setHasNextPage(updatedChatsResponse.hasMore);
        setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);
        updateChatsOrder();
    
        if (response.title) {
          setChatTitle(response.title);
          setChats(prevChats => 
            prevChats.map(chat => 
              chat.id === currentChatId ? { ...chat, title: response.title, name: response.title, updatedAt: newUpdatedAt } : chat
            )
          );
          updateChatsOrder();
        }
    
        if (hasAGTTag(response.reply)) {
          handleOpenAGTMaker(response.reply, () => {});
        }
    
        setSelectedFiles([]);
        setFileContents([]);
      } catch (error) {
        console.error('Error in handleSendMessage:', error);
        if (error.response?.status === 429) {
          // Manejo específico para rate limit
          setRateLimitInfo({
            message: error.response.data.message || 'Has alcanzado el límite de mensajes por hora',
            resetIn: error.response.data.resetIn,
            isPro: error.response.data.isPro
          });
          // No mostramos el error general cuando es rate limit
        } else {
          // Para cualquier otro error, mostramos el error general
          setError('Error al enviar mensaje. Por favor, intenta de nuevo.');
          setRetryAction(() => () => handleSendMessage(messageToSend));
        }
      } finally {
        setIsWaitingForResponse(false);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }, [
      currentChatId,
      user,
      chatContext,
      isWaitingForResponse,
      handleNewChatInput,
      sendMessageToAGT,
      revealWordsGradually,
      handleOpenAGTMaker,
      selectedFiles,
      fileContents,
      updateChatTimestamp,
      updateChatsOrder,
      selectedModel,
      selectedPersonality
    ]);
  
  const handleFileSelect = useCallback((files, contents) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    if (Array.isArray(contents)) {
      setFileContents(prevContents => [...prevContents, ...contents]);
    } else if (contents) {
      setFileContents(prevContents => [...prevContents, contents]);
    }
  }, []);
  
  const handleRemoveFile = useCallback((fileToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    setFileContents(prevContents => {
      if (Array.isArray(prevContents)) {
        return prevContents.filter(content => content.name !== fileToRemove.name);
      }
      return prevContents;
    });
  }, []);

  const handleTitleEdit = useCallback(() => {
    setIsEditingTitle(true);
    setEditedTitle(chatTitle);
  }, [chatTitle]);

  const handleTitleSave = useCallback(async () => {
    if (!user || !user.uid || !currentChatId) return;
    try {
      const response = await updateChatTitle(user.uid, currentChatId, editedTitle);
      if (response && response.success) {
        setChatTitle(editedTitle);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === currentChatId 
              ? { ...chat, title: editedTitle, name: editedTitle, updatedAt: response.updatedChat.updatedAt }
              : chat
          )
        );
        updateChatsOrder();
        setIsEditingTitle(false);
        setSnackbarMessage('Chat title updated successfully');
        setSnackbarOpen(true);
      } else {
        throw new Error(response?.error || 'Failed to update chat title');
      }
    } catch (error) {
      console.error('Error in handleTitleSave:', error);
      setError('Failed to update chat title. Please try again.');
      setSnackbarMessage('Failed to update chat title. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleTitleSave);
    }
  }, [user, currentChatId, editedTitle, updateChatsOrder]);

  const toggleSidebar = useCallback(() => {
    userToggledSidebar.current = true;
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem('sidebarOpen', JSON.stringify(newState));
    setTimeout(() => {
      userToggledSidebar.current = false;
    }, 100);
  }, [isSidebarOpen]);

  const handleMenuOpen = useCallback((event, chatId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.right,
    });
    setMenuAnchorEl(event.currentTarget);
    setMenuChatId(chatId);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    setMenuChatId(null);
  }, []);

  const handleDeleteChat = useCallback(async () => {
    if (!user || !user.uid || !menuChatId) return;
    try {
      await deleteChat(user.uid, menuChatId);
      
      const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
      setChats(updatedChatsResponse.chats);
      setHasNextPage(updatedChatsResponse.hasMore);
      setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);

      if (currentChatId === menuChatId) {
        setCurrentChatId(null);
        saveCurrentChatId(null);
        setChatTitle('');
        setMessages([]);
        setVisibleMessages([]);
        setChatContext({});
        setIsNewChatView(true);
        setIsAGTMakerOpen(false);
        setCurrentAGTContent('');
      }
      
      delete chatCache.current[menuChatId];
      setSnackbarMessage('Chat deleted successfully');
      setSnackbarOpen(true);
      updateChatsOrder();
    } catch (error) {
      console.error('Error in handleDeleteChat:', error);
      setError('Failed to delete chat. Please try again.');
      setSnackbarMessage('Failed to delete chat. Please try again.');
      setSnackbarOpen(true);
      setRetryAction(() => handleDeleteChat);
    } finally {
      handleMenuClose();
    }
  }, [user, menuChatId, currentChatId, handleMenuClose, saveCurrentChatId, updateChatsOrder]);
  
  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);
  
  const toggleFilter = useCallback(() => {
    setIsFilterOpen(prev => !prev);
  }, []);
  
  const handleFilterToggle = useCallback((filter) => {
    setSelectedFilters(prev => 
      prev.includes(filter)
        ? prev.filter(f => f !== filter)
        : [...prev, filter]
    );
  }, []);
  
  const clearAllFilters = useCallback(() => {
    setSelectedFilters([]);
    setSearchTerm('');
    setDateFilter(null);
  }, []);
  
  const getChatCategory = useCallback((chat) => {
    if (!chat.updatedAt && !chat.createdAt) {
      console.warn('Chat sin fecha:', chat);
      return 'Unknown';
    }
  
    const date = parseISO(chat.updatedAt || chat.createdAt);
  
    if (!isValid(date)) {
      console.warn('Fecha inválida para el chat:', chat);
      return 'Unknown';
    }
  
    const now = new Date();
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    if (isAfter(date, subDays(now, 7))) return 'Previous 7 Days';
    if (isAfter(date, subDays(now, 30))) return 'Previous 30 Days';
    if (isThisYear(date)) {
      return format(date, 'MMMM');
    }
    return format(date, 'yyyy');
  }, []);
  
  const handleRetry = useCallback(() => {
    if (retryAction) {
      retryAction();
      setRetryAction(null);
      setError(null);
    }
  }, [retryAction]);
  
  const renderErrorMessage = useCallback(() => {
    if (!error) return null;
    return (
      <ErrorMessage>
        <Typography color="text.primary" gutterBottom>{error}</Typography>
        {retryAction && (
          <RetryButton
            variant="contained"
            startIcon={<RefreshCw />}
            onClick={handleRetry}
          >
            RETRY
          </RetryButton>
        )}
      </ErrorMessage>
    );
  }, [error, retryAction, handleRetry]);
  
  const createNewChatHandler = useCallback(() => {
    setCurrentChatId(null);
    saveCurrentChatId(null);
    setMessages([]);
    setVisibleMessages([]);
    setChatContext({});
    setChatTitle('');
    setIsNewChatView(true);
    setIsAGTMakerOpen(false);
    setCurrentAGTContent('');
  }, [saveCurrentChatId]);
  
  const loadMoreMessages = useCallback(() => {
    const currentLength = visibleMessages.length;
    const newMessages = messages.slice(-currentLength - 20, -currentLength);
    setVisibleMessages(prevMessages => [...newMessages, ...prevMessages]);
  }, [messages, visibleMessages]);

  const filteredChats = useMemo(() => {
    let filtered = chats;
  
    if (searchTerm.trim()) {
      filtered = filtered.filter(chat => 
        chat.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (selectedFilters.length > 0) {
      filtered = filtered.filter(chat => {
        const category = getChatCategory(chat);
        return selectedFilters.includes(category) || 
               (selectedFilters.includes('Older') && 
                !['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'].includes(category));
      });
    }
  
    if (dateFilter) {
      filtered = filtered.filter(chat => {
        const chatDate = parseISO(chat.updatedAt || chat.createdAt);
        return isValid(chatDate) && 
               isAfter(chatDate, startOfMonth(dateFilter)) && 
               isBefore(chatDate, endOfMonth(dateFilter));
      });
    }
  
    return filtered.sort((a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt);
      const dateB = new Date(b.updatedAt || b.createdAt);
      return dateB - dateA;
    });
  }, [chats, searchTerm, selectedFilters, dateFilter, getChatCategory]);

  const [persistentChatState, setPersistentChatState] = useState(() => {
    try {
      const saved = localStorage.getItem('chatView_state');
      return saved ? JSON.parse(saved) : {
        lastScrollPosition: 0,
        lastVisibleCategory: null,
        loadedChatIds: [],
        totalChatsLoaded: 0
      };
    } catch (error) {
      console.error('Error loading persistent chat state:', error);
      return {
        lastScrollPosition: 0,
        lastVisibleCategory: null,
        loadedChatIds: [],
        totalChatsLoaded: 0
      };
    }
  });

  const renderSidebar = useCallback(() => {
    return (
      <>
        <SidebarHeader>
          <ButtonsContainer>
            <MenuButton onClick={toggleSidebar}>
              <MenuIcon />
            </MenuButton>
            <BlueIconButton onClick={createNewChatHandler}>
              <Plus />
            </BlueIconButton>
          </ButtonsContainer>
          
          <div className="header-content">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: '1.75rem',
                color: 'text.primary',
                marginBottom: '8px',
                paddingLeft: '8px',
              }}
            >
              Chats
            </Typography>
  
            <SearchInputContainer>
              <Search size={20} style={{ marginRight: '8px' }} />
              <StyledInput
                placeholder="Search chats"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </SearchInputContainer>
            <Box display="flex" flexWrap="wrap" mb={1} mt={1} alignItems="center">
              {filterCategories.main.map((category) => (
                <Tooltip key={category.name} title={category.tooltip}>
                  <FilterChip
                    onClick={() => handleFilterToggle(category.name)}
                    className={selectedFilters.includes(category.name) ? 'selected' : ''}
                  >
                    {category.name}
                  </FilterChip>
                </Tooltip>
              ))}
  
              <Tooltip title={showMoreFilters ? "Show less filters" : "Show more filters"}>
                <MoreFiltersButton 
                  onClick={() => setShowMoreFilters(!showMoreFilters)}
                  className={showMoreFilters ? 'selected' : ''}
                  size="small"
                >
                  <Plus />
                </MoreFiltersButton>
              </Tooltip>
  
              {showMoreFilters && filterCategories.extra.map((category) => (
                <Tooltip key={category.name} title={category.tooltip}>
                  <FilterChip
                    onClick={() => handleFilterToggle(category.name)}
                    className={selectedFilters.includes(category.name) ? 'selected' : ''}
                  >
                    {category.name}
                  </FilterChip>
                </Tooltip>
              ))}
  
              {(selectedFilters.length > 0 || searchTerm) && (
                <ClearAllButton 
                  onClick={clearAllFilters} 
                  startIcon={<XCircle size={16} />}
                >
                  Clear all
                </ClearAllButton>
              )}
            </Box>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <CategorizedChatList
            chats={filteredChats}
            currentChatId={currentChatId}
            onSelectChat={selectChat}
            onMenuOpen={handleMenuOpen}
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            onLoadMore={handleLoadMore}
            onSidebarClose={toggleSidebar}
            isMobile={isMobile}
            persistentState={persistentChatState}
            onPersistentStateChange={(newState) => {
              setPersistentChatState(prev => ({
                ...prev,
                ...newState
              }));
            }}
          />
        </SidebarContent>
      </>
    );
  }, [
    toggleSidebar,
    createNewChatHandler,
    searchTerm,
    handleSearchChange,
    filterCategories,
    selectedFilters,
    showMoreFilters,
    handleFilterToggle,
    clearAllFilters,
    filteredChats,
    currentChatId,
    selectChat,
    handleMenuOpen,
    hasNextPage,
    isNextPageLoading,
    handleLoadMore
  ]);
  
  const renderHeader = useCallback(() => (
    <>
      {isMobile ? (
        <MobileHeader>
          <MenuButton onClick={toggleSidebar}>
            <MenuIcon />
          </MenuButton>
          <Typography 
            variant="h6" 
            sx={{ 
              position: 'absolute', 
              left: '50%', 
              transform: 'translateX(-50%)',
              width: '60%',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {currentChatId && !isNewChatView ? (chatTitle || "AGT Chat") : "New Chat"}
          </Typography>
          <BlueIconButton onClick={createNewChatHandler}>
            <Plus />
          </BlueIconButton>
        </MobileHeader>
      ) : (
        <DesktopHeader>
          <Box width="33.33%" display="flex" justifyContent="flex-start">
            {!isSidebarOpen && (
              <>
                <MenuButton onClick={toggleSidebar}>
                  <MenuIcon />
                </MenuButton>
                <BlueIconButton onClick={createNewChatHandler} sx={{ marginLeft: '8px' }}>
                  <Plus />
                </BlueIconButton>
              </>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="center">
            {currentChatId && !isNewChatView ? (
              isEditingTitle ? (
                <TextField
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleSave}
                  onKeyPress={(e) => e.key === 'Enter' && handleTitleSave()}
                  autoFocus
                  variant="standard"
                  sx={{ 
                    input: { textAlign: 'center' },
                    '& .MuiInput-underline:before': { borderBottomColor: 'text.primary' },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'text.primary' },
                  }}
                />
              ) : (
                <Typography 
                  variant="h6" 
                  sx={{ 
                    cursor: 'pointer',
                  }}
                  onClick={handleTitleEdit}
                >
                  {chatTitle}
                </Typography>
              )
            ) : (
              <Typography variant="h6">
                New Chat
              </Typography>
            )}
          </Box>
          <Box width="33.33%" display="flex" justifyContent="flex-end">
          </Box>
        </DesktopHeader>
      )}
    </>
  ), [
    isMobile,
    toggleSidebar,
    isSidebarOpen,
    currentChatId,
    isNewChatView,
    chatTitle,
    createNewChatHandler,
    isEditingTitle,
    editedTitle,
    handleTitleSave,
    handleTitleEdit
  ]);

  useEffect(() => {
    try {
      localStorage.setItem('chatView_state', JSON.stringify(persistentChatState));
    } catch (error) {
      console.error('Error saving persistent chat state:', error);
    }
  }, [persistentChatState]);

  useEffect(() => {
    if (mostRecentChatId && isFirstRender.current) {
      selectChat(mostRecentChatId);
      isFirstRender.current = false;
    }
  }, [mostRecentChatId, selectChat]);
  
  useEffect(() => {
    if (!isMobile && !userToggledSidebar.current) {
      if (isAGTMakerOpen !== prevAGTMakerState.current) {
        const savedSidebarState = localStorage.getItem('sidebarOpen');
        if (isAGTMakerOpen) {
          localStorage.setItem('sidebarStateBeforeAGTMaker', JSON.stringify(isSidebarOpen));
          setIsSidebarOpen(false);
        } else {
          const stateBeforeAGTMaker = localStorage.getItem('sidebarStateBeforeAGTMaker');
          if (stateBeforeAGTMaker !== null) {
            setIsSidebarOpen(JSON.parse(stateBeforeAGTMaker));
          } else if (savedSidebarState !== null) {
            setIsSidebarOpen(JSON.parse(savedSidebarState));
          }
        }
        prevAGTMakerState.current = isAGTMakerOpen;
      }
    }
  }, [isAGTMakerOpen, isMobile, isSidebarOpen]);
  
  useEffect(() => {
    if (!isAGTMakerOpen) {
      localStorage.setItem('sidebarOpen', JSON.stringify(isSidebarOpen));
    }
  }, [isSidebarOpen, isAGTMakerOpen]);

  useEffect(() => {
    if (user && user.uid) {
      if (isInitialLoad) {
        loadChats(false);
        setIsInitialLoad(false);
      }
    }
  }, [user, isInitialLoad, loadChats]);

  useEffect(() => {
    if (!user || !user.uid) return;

    const updateChats = async () => {
      try {
        const response = await getChats(user.uid, { 
          limit: persistentChatState.totalChatsLoaded || 20 
        });
        
        setChats(response.chats);
        updateChatsOrder();
        
        if (currentChatId) {
          const currentChat = response.chats.find(chat => chat.id === currentChatId);
          if (currentChat) {
            setChatTitle(currentChat.title || "New chat");
          }
        }
      } catch (error) {
        console.error('Error updating chats:', error);
        setError('Error updating chats. Please try again.');
      }
    };
  
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        updateChats();
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user, currentChatId, updateChatsOrder, persistentChatState.totalChatsLoaded]);

  const handleModelChange = useCallback((newModel) => {
    if (newModel === 'claude-3-sonnet-20240229' && !user?.isPro) {
      // Si intenta seleccionar AGT Pro sin ser usuario PRO,
      // abrimos el diálogo de promoción
      onOpenAGTPromo();
      return;
    }
    setSelectedModel(newModel);
  }, [user?.isPro, onOpenAGTPromo]);

  const handlePersonalityChange = useCallback(async (newPersonality) => {
    if (newPersonality !== 'normal' && !user?.isPro) {
      onOpenAGTPromo();
      return;
    }
    
    try {
      await setUserPersonalityPreference(user.uid, newPersonality);
      setSelectedPersonality(newPersonality);
    } catch (error) {
      console.error('Error saving personality preference:', error);
      setSnackbarMessage('Error saving personality preference');
      setSnackbarOpen(true);
    }
  }, [user?.uid, user?.isPro, onOpenAGTPromo]);

  useEffect(() => {
    const savedChatId = localStorage.getItem('currentChatId');
    if (savedChatId && user && user.uid) {
      selectChat(savedChatId);
    }
  }, [user, selectChat]);

  useEffect(() => {
    if (!isWaitingForResponse) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isWaitingForResponse]);

  useEffect(() => {
    if (rateLimitInfo) {
      const timer = setTimeout(() => {
        setRateLimitInfo(null);
      }, rateLimitInfo.resetIn * 1000);
      return () => clearTimeout(timer);
    }
  }, [rateLimitInfo]);

  // En ChatViewNest.js
  useEffect(() => {
    if (user?.isPro) {
      setSelectedModel('claude-3-sonnet-20240229');
    } else {
      setSelectedModel('claude-3-haiku-20240307');
    }
  }, [user?.isPro]);

  useEffect(() => {
    if (!user?.isPro) {
      // Si el usuario pierde el status Pro, volver a personalidad normal
      setSelectedPersonality('normal');
    }
  }, [user?.isPro]);

  useEffect(() => {
    const savedPersonality = localStorage.getItem('selectedPersonality');
    if (savedPersonality && (user?.isPro || savedPersonality === 'normal')) {
      setSelectedPersonality(savedPersonality);
    }
  }, [user?.isPro]);

  useEffect(() => {
    const loadUserPreferences = async () => {
      if (user?.uid) {
        try {
          const personalityPref = await getUserPersonalityPreference(user.uid);
          if (personalityPref && (user?.isPro || personalityPref === 'normal')) {
            setSelectedPersonality(personalityPref);
          }
        } catch (error) {
          console.error('Error loading personality preference:', error);
          setSelectedPersonality('normal');
        }
      }
    };
  
    loadUserPreferences();
  }, [user?.uid, user?.isPro]);

  useEffect(() => {
    localStorage.setItem('selectedModel', selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    localStorage.setItem('selectedPersonality', selectedPersonality);
  }, [selectedPersonality]);

  return (
    <ChatContainer>
      {isMobile && (
        <SidebarOverlay 
          className={isSidebarOpen ? 'visible' : ''}
          onClick={toggleSidebar}
        />
      )}
      <Sidebar $isOpen={isSidebarOpen} $isMobile={isMobile}>
        {renderSidebar()}
      </Sidebar>

      <ChatArea 
        $isSidebarOpen={isSidebarOpen && !isMobile}
        $isMobile={isMobile}
        $isAGTMakerOpen={isAGTMakerOpen && !isMobile}
        className={loadingStates.messages || loadingStates.chatInfo ? 'loading' : ''}
      >
        {renderHeader()}

        <Box 
          display="flex" 
          flexDirection="column" 
          height="100%"
        >
          <Box flexGrow={1} overflow="auto">
            {isNewChatView || (!currentChatId && messages.length === 0) ? (
              <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
                padding="20px"
              >
                <PromptCards onSelectPrompt={handleNewChatInput} />
              </Box>
            ) : (
              <MessagesContainer>
                {visibleMessages.map((message, index) => {
                  const isLastAssistantMessage = !message.isUser && message.id === lastMessageId;

                  const hasAGTMaker = (() => {
                    if (!message.text) return false;
                    try {
                      const parsed = JSON.parse(message.text);
                      if (parsed.text) {
                        return typeof parsed.text === 'string' && parsed.text.includes('<agt>');
                      }
                      return typeof parsed === 'string' && parsed.includes('<agt>');
                    } catch {
                      return typeof message.text === 'string' && message.text.includes('<agt>');
                    }
                  })();

                  return (
                    <MagicalMessage
                      key={message.id || index}
                      content={message.text}
                      isUser={message.isUser}
                      isLastMessage={isLastAssistantMessage}
                      onFeedback={(feedback) => {}}
                      userId={user?.uid}
                      messageId={message.id}
                      hasAGTMaker={hasAGTTag(message.text)}  // Usar la misma función aquí
                      onOpenAGTMaker={() => hasAGTTag(message.text) && handleOpenAGTMaker(message.text, () => {})}
                      isLoading={message.isLoading}
                      onUpdateMessage={handleUpdateMessage}
                      lastImageRequestId={lastImageRequestId}
                      files={message.files}
                    />
                  );
                })}
                {isWaitingForResponse && (
                  <ThinkingIcon key="thinking-icon" startTime={waitingStartTime} />
                )}
                {renderErrorMessage()}
                <div ref={messagesEndRef} />
              </MessagesContainer>
            )}
          </Box>

          <MessageBarContainer>
            <InputContainer>
              <OptimizedInput
                onSendMessage={handleSendMessage}
                isWaitingForResponse={isWaitingForResponse}
                inputRef={inputRef}
                selectedFiles={selectedFiles}
                onRemoveFile={handleRemoveFile}
                rateLimitInfo={rateLimitInfo}
                onOpenAGTPromo={onOpenAGTPromo}
                selectedModel={selectedModel}
                onModelChange={handleModelChange}
                selectedPersonality={selectedPersonality}  // Añadir selectedPersonality
                onPersonalityChange={handlePersonalityChange}  // Añadir onPersonalityChange
                isPro={user?.isPro}
                renderFileAttachment={() => (
                  isAuthorized && (
                    <FileAttachment
                      onFileSelect={handleFileSelect}
                      disabled={isWaitingForResponse}
                    />
                  )
                )}
              />
            </InputContainer>
            <InfoText>
              By using AGT, you agree to our <a href="https://directiveai.org/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policies</a>.
            </InfoText>
          </MessageBarContainer>
        </Box>
      </ChatArea>

      <AGTMakerContainer
        sx={{
          transform: isAGTMakerOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        <AGTMaker 
          content={currentAGTContent}
          isOpen={isAGTMakerOpen}
          onClose={handleCloseAGTMaker}
          onContentReady={handleAGTMakerContentReady}
        />
      </AGTMakerContainer>

      <Menu
        anchorEl={menuAnchorEl}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleDeleteChat}
          sx={{ 
            color: 'error.main',
            '&:hover': { backgroundColor: 'error.light' }
          }}
        >
          <DeleteIcon sx={{ marginRight: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={error ? "error" : "success"} 
          sx={{ width: '100%' }}
          action={
            error && retryAction ? (
              <Button 
                color="inherit" 
                size="small" 
                onClick={handleRetry}
                sx={{
                  borderRadius: '30px',
                  backgroundColor: 'action.selected',
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                RETRY
              </Button>
            ) : null
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ChatContainer>
  );
});

export default ChatViewNest;