import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography as MuiTypography, IconButton, Tooltip, Link, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { ThumbsUp, ThumbsDown, Copy, PencilLine, Search, Paperclip } from 'lucide-react';
import { sendFeedback } from '../../services/AGTService';
import InlineImageSearch from '../../search/InlineImageSearch';
import { getThemeStyles } from '../../themes/themeConfig';
import DirectiveLogo from '../../common/DirectiveLogo';
import { FeedbackModalsContainer } from '../feedback/FeedbackModalsContainer';
import AGTMakerButton from '../AGTMaker/AGTMakerButton';
import MagicalMessageRenderer from './MagicalMessageRenderer';

const textFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const glowAnimation = keyframes`
  0% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 0px rgba(255, 255, 255, 0);
  }
  50% { 
    filter: blur(3px) brightness(1.2);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
  }
  100% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  }
`;

const ImageContainer = styled(Box)`
  position: relative;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  
  &.loaded {
    opacity: 1;
  }
`;

const BaseImage = styled('img')`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
`;

const GlowEffect = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${glowAnimation} 1.5s ease-in-out;
  animation-delay: 0.2s;
  border-radius: 8px;
  pointer-events: none;
`;

const MessageContainer = styled(Box, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isUser'].includes(prop)
})(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isUser ? 'flex-end' : 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: isUser ? '100%' : '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '8px',
  paddingBottom: '8px',
  opacity: 1,
  transform: 'none',
  '&:last-child': {
    marginBottom: '12px',
  },
  '@media (max-width: 1200px)': {
    maxWidth: '100%',
    paddingRight: isUser ? '16px' : '0',
    paddingLeft: isUser ? '0' : '0px',
  },
  '@media (max-width: 600px)': {
    paddingRight: isUser ? '0px' : '0',
    paddingLeft: isUser ? '0' : '0px',
    maxWidth: '100%',
  },
  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

const MessageContent = styled(Box, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isUser'].includes(prop)
})(({ theme, isUser }) => ({
  flexGrow: 0,
  maxWidth: isUser ? '60%' : '100%',
  width: isUser ? 'fit-content' : 'auto',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: isUser ? 'auto' : '0',
  marginRight: isUser ? '0px' : '0',
  lineHeight: '1.75',
  opacity: 1,
  transform: 'none',
  '@media (max-width: 1200px)': {
    marginRight: isUser ? '0' : '0',
    maxWidth: isUser ? '60%' : 'calc(100% - 44px)',
    width: isUser ? 'fit-content' : '100%',
  },
  '@media (max-width: 600px)': {
    maxWidth: isUser ? '60%' : 'calc(100% - 34px)',
    width: isUser ? 'fit-content' : '100%',
    marginRight: '0',
  },
  ...(isUser
    ? {
        ...getThemeStyles('UserMessageContent', theme.palette.mode),
        alignSelf: 'flex-end',
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
      }
    : {
        ...getThemeStyles('MessageContent', theme.palette.mode),
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
    }),
}));

const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  paddingRight: '0',
  marginLeft: '0',
  paddingTop: '8px',
  '@media (max-width: 1200px)': {
    paddingRight: '16px',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    paddingRight: '8px',
  },
});

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0px',
  marginTop: '10px',
  opacity: 1,
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#2a2a2a !important'
    : '#fff !important',
  border: theme.palette.mode === 'dark' 
    ? '1px solid rgba(255, 255, 255, 0.1)' 
    : '1px solid rgba(0, 0, 0, 0.1)'
}));

const FeedbackContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '15px',
  bottom: '-5px',
  display: 'flex',
  gap: '5px',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.6s',
  '@media (max-width: 1200px)': {
    position: 'relative',
    left: 'auto',
    bottom: 'auto',
    marginTop: '8px',
    justifyContent: 'flex-start',
  },
}));

const FeedbackButton = styled(IconButton, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['active'].includes(prop)
})(({ theme, active }) => ({
  padding: '2px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  ...getThemeStyles('FeedbackButton', theme.palette.mode),
  color: active ? '#0385FF' : 'inherit',
}));

const Typography = styled(MuiTypography)({
  lineHeight: '1.75',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
});

const FileAttachmentContainer = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  padding: '8px',
  borderRadius: '8px',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.2s',
  ...getThemeStyles('FileAttachmentContainer', theme.palette.mode),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 12px',
  margin: '10px 0',
  borderRadius: '20px',
  transition: 'all 0.2s ease',
  '& .lucide': {
    marginLeft: '8px',
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  ...getThemeStyles('StyledButton', theme.palette.mode),
}));

const FeedbackButtonsSection = ({ feedback, handleFeedback, copied, handleCopy, hasAGTMaker, onOpenAGTMaker }) => (
  <FeedbackContainer>
    <Tooltip title="Like" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('like')} 
        active={feedback === 'like'}
        size="small"
      >
        <ThumbsUp size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title="Dislike" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('dislike')} 
        active={feedback === 'dislike'}
        size="small"
      >
        <ThumbsDown size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title={copied ? "Copied" : "Copy message"} placement="top">
      <FeedbackButton 
        onClick={handleCopy}
        size="small"
      >
        <Copy size={18} />
      </FeedbackButton>
    </Tooltip>
    {hasAGTMaker && (
      <Tooltip title="Open AGT Maker" placement="top">
        <FeedbackButton 
          onClick={onOpenAGTMaker}
          size="small"
        >
          <PencilLine size={18} />
        </FeedbackButton>
      </Tooltip>
    )}
  </FeedbackContainer>
);

const AnimatedImage = ({ src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  return (
    <ImageContainer className={isLoaded ? 'loaded' : ''}>
      <BaseImage
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
        {...props}
      />
      {isLoaded && <GlowEffect />}
    </ImageContainer>
  );
};

const OnDemandImageSearch = ({ initialQuery, onImageSelect, showAutomatically }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(showAutomatically);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSearchClick = useCallback(() => {
    setIsSearchVisible(true);
  }, []);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    onImageSelect(image);
    setIsSearchVisible(false);
  }, [onImageSelect]);

  if (selectedImage) {
    return (
      <div>
        <AnimatedImage 
          src={selectedImage.link} 
          alt={selectedImage.title}
        />
        <Typography variant="caption">
          <Link href={selectedImage.image.contextLink} target="_blank" rel="noopener noreferrer">
            View on web
          </Link>
        </Typography>
      </div>
    );
  }

  if (isSearchVisible) {
    return <InlineImageSearch onImageSelect={handleImageSelect} initialQuery={initialQuery} />;
  }

  return (
    <StyledButton
      startIcon={<Search size={24} style={{ marginRight: '4px' }} />}
      onClick={handleSearchClick}
    >
      Open AGT Search
    </StyledButton>
  );
};

const MagicalMessage = ({ 
  content, 
  isUser, 
  isLastMessage,
  onFeedback, 
  userId, 
  messageId,
  hasAGTMaker,
  onOpenAGTMaker,
  isLoading,
  onUpdateMessage,
  lastImageRequestId,
  files,
  theme
}) => {
  const initialProcessedContent = useMemo(() => {
    const processedMessage = processMessageContent(content, isLastMessage);
    return {
      type: processedMessage.type,
      text: processedMessage.text,
      data: processedMessage.data,
      isNew: processedMessage.isNew
    };
  }, [content, isLastMessage]);

  const [processedContent, setProcessedContent] = useState([]);
  const [copied, setCopied] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [openLikeModal, setOpenLikeModal] = useState(false);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [dislikeReason, setDislikeReason] = useState('Other');

  const handleCopy = useCallback(() => {
    const processedMessage = processMessageContent(content);
    navigator.clipboard.writeText(processedMessage.text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [content]);

  const handleFeedback = useCallback((value) => {
    setFeedback(value);
    if (value === 'like') {
      setOpenLikeModal(true);
    } else if (value === 'dislike') {
      setOpenDislikeModal(true);
    }
  }, []);

  const handleSubmitFeedback = useCallback(async () => {
    try {
      if (!userId || !messageId || !feedback) {
        throw new Error('Missing required fields for feedback');
      }

      await sendFeedback(
        userId,
        messageId,
        feedback,
        feedbackDetails,
        feedback === 'dislike' ? dislikeReason : null
      );

      if (onFeedback) {
        onFeedback({
          type: feedback,
          details: feedbackDetails,
          reason: feedback === 'dislike' ? dislikeReason : null,
        });
      }

      setOpenLikeModal(false);
      setOpenDislikeModal(false);
      setFeedbackDetails('');
      setDislikeReason('Other');
    } catch (error) {
      console.error('Error sending feedback:', error);
      alert('There was an error sending feedback. Please try again.');
    }
  }, [userId, messageId, feedback, feedbackDetails, dislikeReason, onFeedback]);

  const handleImageSelect = useCallback((selectedImage, index) => {
    const imageData = {
      type: 'selected_image',
      data: {
        src: selectedImage.link,
        alt: selectedImage.title,
        contextLink: selectedImage.image.contextLink
      }
    };

    setProcessedContent(prevContent => {
      const newContent = [...prevContent];
      newContent[index] = (
        <React.Fragment key={index}>
          <AnimatedImage 
            src={selectedImage.link} 
            alt={selectedImage.title}
          />
          <br />
          <Link 
            href={selectedImage.image.contextLink} 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{
              display: 'inline-block',
              marginTop: '8px',
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
            View on web
          </Link>
        </React.Fragment>
      );
      return newContent;
    });
    onUpdateMessage(messageId, imageData);
  }, [onUpdateMessage, messageId]);

  useEffect(() => {
    if (initialProcessedContent.type === 'selected_image') {
      setProcessedContent([
        <React.Fragment key="image">
          <AnimatedImage 
            src={initialProcessedContent.data.src} 
            alt={initialProcessedContent.data.alt}
          />
          {!isUser && (
            <>
              <br />
              <Link 
                href={initialProcessedContent.data.contextLink} 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  marginTop: '8px',
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
              >
                View on web
              </Link>
            </>
          )}
        </React.Fragment>
      ]);
      return;
    }

    if (!isUser) {
      const text = String(initialProcessedContent.text || '');
      const processedParts = [];
      let currentIndex = 0;
      let textBuffer = '';
    
      while (currentIndex < text.length) {
        const agtStartIndex = text.indexOf('<agt>', currentIndex);
        
        if (agtStartIndex === -1) {
          textBuffer += text.slice(currentIndex);
          break;
        }
    
        textBuffer += text.slice(currentIndex, agtStartIndex);
        
        if (textBuffer) {
          processedParts.push(
            <MagicalMessageRenderer
              key={processedParts.length}
              content={textBuffer}
              isNew={initialProcessedContent.isNew}
              theme={theme}
              baseDelay={processedParts.length * 40}
            />
          );
          textBuffer = '';
        }
    
        processedParts.push(
          <AGTMakerButton
            key={`agt-${processedParts.length}`}
            onOpenAGTMaker={onOpenAGTMaker}
            isNew={initialProcessedContent.isNew}
          />
        );
    
        const agtEndIndex = text.indexOf('</agt>', agtStartIndex);
        if (agtEndIndex === -1) {
          currentIndex = text.length;
        } else {
          currentIndex = agtEndIndex + 6;
        }
      }
    
      if (textBuffer) {
        processedParts.push(
          <MagicalMessageRenderer
            key={processedParts.length}
            content={textBuffer}
            isNew={initialProcessedContent.isNew}
            theme={theme}
            baseDelay={processedParts.length * 40}
          />
        );
      }
    
      setProcessedContent(processedParts);
    } else {
      setProcessedContent([
        <Typography 
          key="text" 
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          {initialProcessedContent.text}
        </Typography>
      ]);
    }
  }, [
    initialProcessedContent,
    isUser,
    messageId,
    lastImageRequestId,
    onOpenAGTMaker,
    content,
    handleImageSelect,
    theme
  ]);

  return (
    <MessageContainer isUser={isUser}>
      {isUser ? (
        <>
          <MessageContent isUser={isUser}>
            {processedContent}
          </MessageContent>
          {files?.length > 0 && (
            <FileAttachmentContainer>
              <Typography variant="body2" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                <Paperclip size={14} style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                Attached files: {files.join(', ')}
              </Typography>
            </FileAttachmentContainer>
          )}
        </>
      ) : (
        <MessageWrapper>
          <AssistantIconContainer>
            <DirectiveLogo size={18} className="text-current" />
          </AssistantIconContainer>
          <MessageContent isUser={isUser}>
            {processedContent}
            <FeedbackButtonsSection 
              feedback={feedback}
              handleFeedback={handleFeedback}
              copied={copied}
              handleCopy={handleCopy}
              hasAGTMaker={hasAGTMaker}
              onOpenAGTMaker={onOpenAGTMaker}
            />
          </MessageContent>
        </MessageWrapper>
      )}

      <FeedbackModalsContainer
        openLikeModal={openLikeModal}
        openDislikeModal={openDislikeModal}
        setOpenLikeModal={setOpenLikeModal}
        setOpenDislikeModal={setOpenDislikeModal}
        feedbackDetails={feedbackDetails}
        setFeedbackDetails={setFeedbackDetails}
        dislikeReason={dislikeReason}
        setDislikeReason={setDislikeReason}
        handleSubmitFeedback={handleSubmitFeedback}
      />
    </MessageContainer>
  );
};

const processMessageContent = (message, isNew = false) => {
  if (!message) return { text: '', type: 'text', isNew };

  if (typeof message === 'string' && message.startsWith('Image: ')) {
    const imageUrl = message.replace('Image: ', '').trim();
    return {
      type: 'selected_image',
      data: {
        src: imageUrl,
        alt: 'Selected image',
        contextLink: imageUrl
      },
      text: message,
      isNew
    };
  }

  if (typeof message === 'string') {
    try {
      const parsed = JSON.parse(message);
      if (parsed.type === 'selected_image' && parsed.data) {
        return { ...parsed, isNew };
      }
      return { text: parsed.text || parsed, type: 'text', isNew };
    } catch {
      return { text: message, type: 'text', isNew };
    }
  }

  if (message?.type === 'selected_image' && message.data) {
    return { ...message, isNew };
  }

  if (message?.text) {
    return { text: message.text, type: 'text', isNew };
  }

  if (typeof message === 'object') {
    try {
      return { text: JSON.stringify(message), type: 'text', isNew };
    } catch {
      return { text: '', type: 'text', isNew };
    }
  }

  return { text: String(message || ''), type: 'text', isNew };
};

const isImageContent = (content) => {
  if (typeof content === 'string' && content.startsWith('Image: ')) {
    return true;
  }
  return content?.type === 'selected_image' && 
         content?.data?.src && 
         content?.data?.contextLink;
};

const extractImageData = (content) => {
  if (typeof content === 'string' && content.startsWith('Image: ')) {
    const imageUrl = content.replace('Image: ', '').trim();
    return {
      src: imageUrl,
      alt: 'Selected image',
      contextLink: imageUrl
    };
  }
  
  if (!isImageContent(content)) return null;
  
  return {
    src: content.data.src,
    alt: content.data.alt || 'Selected image',
    contextLink: content.data.contextLink
  };
};

const MemoizedMagicalMessage = React.memo(MagicalMessage, (prevProps, nextProps) => {
  const isEqual = (
    prevProps.content === nextProps.content &&
    prevProps.isUser === nextProps.isUser &&
    prevProps.isLastMessage === nextProps.isLastMessage &&
    prevProps.userId === nextProps.userId &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.hasAGTMaker === nextProps.hasAGTMaker &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.lastImageRequestId === nextProps.lastImageRequestId &&
    JSON.stringify(prevProps.files) === JSON.stringify(nextProps.files)
  );
  return isEqual;
});

MemoizedMagicalMessage.displayName = 'MagicalMessage';

export default MemoizedMagicalMessage;