import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  LinearProgress, 
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  AvatarGroup,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  Badge,
  Tab,
  Tabs
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  TrendingUp, 
  Users,
  Target,
  ShoppingCart,
  Calendar,
  Clock,
  BarChart2,
  Download,
  ArrowUp,
  ArrowDown,
  Globe,
  Share2,
  UserCheck,
  UserPlus,
  DollarSign,
  Award,
  Zap,
  Star,
  Package,
  RefreshCw,
  Settings,
  ChevronRight,
  AlertCircle,
  Activity,
  PieChart
} from 'lucide-react';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Legend,
  Line,
  LineChart
} from 'recharts';

import SalesStrategyService from '../../services/AGTUps-product-services/salesStrategyService';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const MetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const ChannelCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1,
  borderRadius: theme.spacing(2),
}));

const COLORS = {
  primary: '#4f46e5',
  secondary: '#0891b2',
  success: '#059669',
  warning: '#eab308',
  error: '#dc2626',
  purple: '#7c3aed',
  pink: '#db2777'
};

const SalesStrategy = ({ selectedProduct }) => {
  const [timeRange, setTimeRange] = useState('6m');
  const [activeTab, setActiveTab] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [salesPerformance, setSalesPerformance] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      loadSalesData();
    }
  }, [selectedProduct, timeRange]);

  const loadSalesData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [strategyResponse, performanceResponse] = await Promise.all([
        SalesStrategyService.getSalesStrategiesByProduct(selectedProduct.id, {
          timeRange
        }),
        SalesStrategyService.analyzeSalesPerformance(selectedProduct.id, {
          timeRange
        })
      ]);

      setStrategyData(strategyResponse.data);
      setSalesPerformance(performanceResponse.data);
      setLastUpdated(new Date());

    } catch (error) {
      setError(`Error loading sales data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadSalesData();
    } finally {
      setUpdating(false);
    }
  };

  const handleExportData = async () => {
    try {
      const data = {
        strategy: strategyData,
        performance: salesPerformance,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `sales-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      setError(`Error exporting data: ${error.message}`);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formattedSalesData = useMemo(() => {
    if (!salesPerformance) return null;
    return SalesStrategyService.formatChartData(salesPerformance);
  }, [salesPerformance]);

  if (!selectedProduct) {
    return (
      <StyledPaper>
        <Box className="text-center py-8">
          <Package size={48} className="mx-auto mb-4 text-gray-400" />
          <Typography variant="h6" gutterBottom>
            No Product Selected
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Please select a product to view sales strategy
          </Typography>
        </Box>
      </StyledPaper>
    );
  }

  return (
    <Box className="space-y-6">
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          action={
            <Button color="inherit" size="small" onClick={() => setError(null)}>
              DISMISS
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              label="Time Range"
              onChange={(e) => setTimeRange(e.target.value)}
              disabled={loading}
            >
              <MenuItem value="1m">Last Month</MenuItem>
              <MenuItem value="3m">Last 3 Months</MenuItem>
              <MenuItem value="6m">Last 6 Months</MenuItem>
              <MenuItem value="1y">Last Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Sales Channel</InputLabel>
            <Select
              value={selectedChannel}
              label="Sales Channel"
              onChange={(e) => setSelectedChannel(e.target.value)}
              disabled={loading}
            >
              <MenuItem value="all">All Channels</MenuItem>
              <MenuItem value="direct">Direct Sales</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="partners">Partners</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} className="flex justify-end gap-2">
          <Tooltip title={lastUpdated ? `Last updated: ${lastUpdated.toLocaleString()}` : 'Never updated'}>
            <IconButton 
              onClick={handleRefreshData} 
              disabled={updating || loading}
            >
              <RefreshCw 
                size={20} 
                className={updating ? 'animate-spin' : ''} 
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export Strategy">
            <IconButton onClick={handleExportData} disabled={loading}>
              <Download size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton disabled={loading}>
              <Settings size={20} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {updating && <LoadingOverlay><CircularProgress /></LoadingOverlay>}
            <Box className="flex justify-between items-center mb-6">
              <Box>
                <Typography variant="h5">{selectedProduct.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Sales Performance Overview
                </Typography>
              </Box>
              <Badge 
                color="primary" 
                badgeContent={salesPerformance?.status || 'Active'} 
                sx={{ '& .MuiBadge-badge': { fontSize: '0.75rem' } }}
              >
                <Chip 
                  icon={<DollarSign size={16} />}
                  label={`$${selectedProduct.price}`}
                  color="primary"
                  variant="outlined"
                />
              </Badge>
            </Box>

            <Box className="h-80">
              {formattedSalesData ? (
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={formattedSalesData.salesTrend}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <RechartsTooltip
                      contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        border: 'none',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="sales"
                      stroke={COLORS.primary}
                      fill={COLORS.primary}
                      fillOpacity={0.1}
                      name="Sales Volume"
                    />
                    <Area
                      type="monotone"
                      dataKey="target"
                      stroke={COLORS.success}
                      strokeDasharray="5 5"
                      fill="none"
                      name="Sales Target"
                    />
                    <Legend />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <Box className="h-full flex items-center justify-center">
                  <Typography color="text.secondary">
                    No sales trend data available
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Performance Metrics</Typography>
                {salesPerformance ? (
                  <Box className="space-y-4">
                    <MetricCard>
                      <Box className="flex justify-between items-center">
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Monthly Sales
                          </Typography>
                          <Typography variant="h4">
                            ${salesPerformance.monthly.total.toLocaleString()}
                          </Typography>
                        </Box>
                        <Box className="flex items-center gap-1 text-green-500">
                          <ArrowUp size={20} />
                          <Typography variant="body2">
                            {salesPerformance.monthly.growth}%
                          </Typography>
                        </Box>
                      </Box>
                    </MetricCard>

                    <MetricCard>
                      <Box className="flex justify-between items-center">
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Conversion Rate
                          </Typography>
                          <Typography variant="h4">
                            {salesPerformance.conversion.rate}%
                          </Typography>
                        </Box>
                        <Box className={`flex items-center gap-1 ${
                          salesPerformance.conversion.trend > 0 ? 'text-green-500' : 'text-red-500'
                        }`}>
                          {salesPerformance.conversion.trend > 0 ? (
                            <ArrowUp size={20} />
                          ) : (
                            <ArrowDown size={20} />
                          )}
                          <Typography variant="body2">
                            {Math.abs(salesPerformance.conversion.trend)}%
                          </Typography>
                        </Box>
                      </Box>
                    </MetricCard>

                    <MetricCard>
                      <Box className="flex justify-between items-center">
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Avg. Deal Size
                          </Typography>
                          <Typography variant="h4">
                            ${salesPerformance.avgDealSize.value}
                          </Typography>
                        </Box>
                        <Box className={`flex items-center gap-1 ${
                          salesPerformance.avgDealSize.trend > 0 ? 'text-green-500' : 'text-red-500'
                        }`}>
                          {salesPerformance.avgDealSize.trend > 0 ? (
                            <ArrowUp size={20} />
                          ) : (
                            <ArrowDown size={20} />
                          )}
                          <Typography variant="body2">
                            {Math.abs(salesPerformance.avgDealSize.trend)}%
                          </Typography>
                        </Box>
                      </Box>
                    </MetricCard>
                  </Box>
                ) : (
                  <Box className="p-4 text-center">
                    <CircularProgress size={24} />
                  </Box>
                )}
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper>
                <Box className="flex justify-between items-center mb-4">
                  <Typography variant="h6">Top Performers</Typography>
                  <AvatarGroup max={3}>
                    {salesPerformance?.topPerformers.map((performer, index) => (
                      <Avatar 
                        key={performer.id} 
                        alt={performer.name}
                        src={performer.avatar}
                      />
                    ))}
                  </AvatarGroup>
                </Box>
                <Box className="space-y-3">
                  {salesPerformance?.topPerformers.slice(0, 2).map((performer, index) => (
                    <Box key={performer.id} className="flex justify-between items-center">
                      <Box className="flex items-center gap-2">
                        <Avatar 
                          sx={{ bgcolor: index === 0 ? 'primary.main' : 'secondary.main' }}
                        >
                          {performer.name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle2">{performer.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {performer.dealsClosed} deals closed
                          </Typography>
                        </Box>
                      </Box>
                      <Award 
                        className={index === 0 ? 'text-yellow-500' : 'text-gray-400'} 
                        size={20} 
                      />
                    </Box>
                  ))}
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Sales Channel Distribution</Typography>
            <Box className="h-80">
              {formattedSalesData?.channelDistribution ? (
                <ResponsiveContainer width="100%" height="100%">
                  <RechartsPieChart>
                    <Pie
                      data={formattedSalesData.channelDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {formattedSalesData.channelDistribution.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={Object.values(COLORS)[index % Object.keys(COLORS).length]}
                        />
                      ))}
                    </Pie>
                    <RechartsTooltip />
                    <Legend />
                  </RechartsPieChart>
                </ResponsiveContainer>
              ) : (
                <Box className="h-full flex items-center justify-center">
                  <Typography color="text.secondary">
                    No channel distribution data available
                  </Typography>
                </Box>
              )}
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Sales Funnel</Typography>
            <Box className="space-y-4">
              {salesPerformance?.funnel ? (
                Object.entries(salesPerformance.funnel).map(([stage, data]) => (
                  <Box key={stage}>
                    <Box className="flex justify-between mb-1">
                      <Typography variant="body2">{stage}</Typography>
                      <Box className="flex items-center gap-2">
                        <Typography variant="body2">{data.count}</Typography>
                        <Typography 
                          variant="caption" 
                          color={data.conversionRate >= data.target ? "success.main" : "error"}
                        >
                          {data.conversionRate}%
                        </Typography>
                      </Box>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={data.conversionRate} 
                      color={data.conversionRate >= data.target ? "success" : "warning"}
                    />
                  </Box>
                ))
              ) : (
                <Box className="text-center py-4">
                  <CircularProgress size={24} />
                </Box>
              )}
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recommended Actions</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <ChannelCard>
                  <Box className="flex items-center gap-2 mb-2">
                    <UserPlus size={20} className="text-blue-500" />
                    <Typography variant="subtitle1">Lead Generation</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {salesPerformance?.recommendations?.leadGen || 
                      'Increase lead generation efforts in direct sales channel'}
                  </Typography>
                  <Button 
                    variant="contained" 
                    className="mt-2"
                    fullWidth
                    disabled={loading}
                  >
                    Start Campaign
                  </Button>
                </ChannelCard>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <ChannelCard>
                  <Box className="flex items-center gap-2 mb-2">
                    <Zap size={20} className="text-purple-500" />
                    <Typography variant="subtitle1">Conversion Optimization</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {salesPerformance?.recommendations?.conversion ||
                      'Implement new sales training program to improve close rates'}
                  </Typography>
                  <Button 
                    variant="contained" 
                    className="mt-2"
                    fullWidth
                    disabled={loading}
                  >
                    View Program
                  </Button>
                </ChannelCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ChannelCard>
                  <Box className="flex items-center gap-2 mb-2">
                    <Star size={20} className="text-yellow-500" />
                    <Typography variant="subtitle1">Customer Retention</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {salesPerformance?.recommendations?.retention ||
                      'Launch customer loyalty program to increase repeat sales'}
                  </Typography>
                  <Button 
                    variant="contained" 
                    className="mt-2"
                    fullWidth
                    disabled={loading}
                  >
                    Launch Program
                  </Button>
                </ChannelCard>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              className="mb-4"
            >
              <Tab 
                icon={<Globe size={16} />} 
                label="Geographic" 
                iconPosition="start"
              />
              <Tab 
                icon={<Calendar size={16} />} 
                label="Seasonal" 
                iconPosition="start"
              />
              <Tab 
                icon={<UserCheck size={16} />} 
                label="Customer Segments" 
                iconPosition="start"
              />
            </Tabs>

            {activeTab === 0 && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Geographic Distribution of Sales
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box className="p-4 bg-blue-50 rounded-lg">
                      <Typography variant="subtitle2" gutterBottom>
                        Top Performing Regions
                      </Typography>
                      <Box className="space-y-2">
                        {salesPerformance?.geographic?.topRegions.map(region => (
                          <Box key={region.name}>
                            <Box className="flex justify-between items-center">
                              <Typography variant="body2">{region.name}</Typography>
                              <Typography variant="body2" color="primary">
                                {region.contribution}%
                              </Typography>
                            </Box>
                            <LinearProgress 
                              variant="determinate" 
                              value={region.contribution} 
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="p-4 bg-green-50 rounded-lg">
                      <Typography variant="subtitle2" gutterBottom>
                        Growth Opportunities
                      </Typography>
                      {salesPerformance?.geographic?.opportunities.map(opportunity => (
                        <Box 
                          key={opportunity.region} 
                          className="flex items-center gap-2 mt-2"
                        >
                          <Target size={16} className="text-green-500" />
                          <Typography variant="body2">
                            {opportunity.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            {activeTab === 1 && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Seasonal Sales Patterns
                </Typography>
                <Box className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={formattedSalesData?.seasonal}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="period" />
                      <YAxis />
                      <RechartsTooltip />
                      <Bar dataKey="sales" fill={COLORS.primary} />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            )}

            {activeTab === 2 && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Customer Segment Analysis
                </Typography>
                <Grid container spacing={3}>
                  {salesPerformance?.segments.map(segment => (
                    <Grid item xs={12} md={4} key={segment.name}>
                      <MetricCard>
                        <Box className="flex items-center gap-2 mb-2">
                          <Users size={20} className={`text-${segment.color}-500`} />
                          <Typography variant="subtitle2">{segment.name}</Typography>
                        </Box>
                        <Typography variant="h6">{segment.revenue}%</Typography>
                        <Typography variant="body2" color="text.secondary">
                          Revenue Share
                        </Typography>
                      </MetricCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesStrategy;