import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Box,
  IconButton,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  styled,
  alpha
} from '@mui/material';
import { X } from 'lucide-react';

const AppleDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 28,
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(255, 255, 255, 0.98)',
    backdropFilter: 'blur(20px)',
    maxWidth: 600,
    width: '100%',
    margin: 16,
    boxShadow: theme.palette.mode === 'dark'
      ? '0px 1px 3px rgba(0, 0, 0, 0.5)'
      : '0px 8px 32px rgba(0, 0, 0, 0.08)'
  }
}));

const AppleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.06)'
  }`,
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const AppleButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  padding: theme.spacing(1.2, 3.5),
  textTransform: 'none',
  fontWeight: 500,
  backgroundColor: theme.palette.mode === 'dark' && 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.mode === 'dark' && '#fff',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' && 'rgba(255, 255, 255, 0.15)'
  }
}));

const FeedbackTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)'
      : alpha(theme.palette.action.hover, 0.05),
    borderRadius: 20,
    '& fieldset': {
      border: 'none'
    },
    '&:hover fieldset': {
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: 'none'
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: '16px 20px'
  }
}));

const FeedbackSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)'
    : alpha(theme.palette.action.hover, 0.05),
  borderRadius: 20,
  marginBottom: theme.spacing(2),
  '& fieldset': {
    border: 'none'
  },
  '&:hover fieldset': {
    border: 'none'
  },
  '&.Mui-focused fieldset': {
    border: 'none'
  },
  '& .MuiSelect-select': {
    padding: '12px 20px',
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '12px 20px',
  borderRadius: 16,
  margin: '4px 8px',
  width: 'calc(100% - 16px)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : alpha(theme.palette.action.hover, 0.1)
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.15)'
      : alpha(theme.palette.action.selected, 0.15),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : alpha(theme.palette.action.selected, 0.2)
    }
  }
}));

export const AppleFeedbackModal = ({
  open,
  onClose,
  title,
  value,
  onChange,
  placeholder,
  onSubmit,
  showSelect = false,
  selectValue,
  onSelectChange,
}) => {
  return (
    <AppleDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          overflow: 'hidden'
        }
      }}
    >
      <AppleDialogTitle>
        <Typography 
          variant="h5" 
          component="h2" 
          fontWeight={500}
        >
          {title}
        </Typography>
        <IconButton 
          onClick={onClose}
          size="large"
          sx={{ 
            color: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : undefined,
            '&:hover': {
              backgroundColor: theme => theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : alpha(theme.palette.action.hover, 0.1)
            }
          }}
        >
          <X />
        </IconButton>
      </AppleDialogTitle>

      <DialogContent sx={{ 
        p: 4,
        pt: 3 
      }}>
        {showSelect && (
          <FeedbackSelect
            fullWidth
            value={selectValue}
            onChange={onSelectChange}
            sx={{ mt: 2 }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: 4,
                  backgroundColor: theme => theme.palette.mode === 'dark'
                    ? 'rgba(0, 0, 0, 0.9)'
                    : 'rgba(255, 255, 255, 0.98)',
                  backdropFilter: 'blur(20px)',
                  mt: 1
                }
              }
            }}
          >
            <StyledMenuItem value="bug">UI Bug</StyledMenuItem>
            <StyledMenuItem value="harmful">Harmful Content</StyledMenuItem>
            <StyledMenuItem value="refusal">Overactive Refusal</StyledMenuItem>
            <StyledMenuItem value="incomplete">Did Not Follow Request</StyledMenuItem>
            <StyledMenuItem value="incorrect">Not Factually Correct</StyledMenuItem>
            <StyledMenuItem value="other">Other</StyledMenuItem>
          </FeedbackSelect>
        )}
        
        <FeedbackTextField
          fullWidth
          multiline
          rows={4}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          variant="outlined"
          sx={{ mt: !showSelect ? 2 : 0 }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          p: 3,
          borderTop: 1,
          borderColor: theme => 
            theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.1)' 
              : 'rgba(0, 0, 0, 0.06)',
          gap: 2
        }}
      >
        <AppleButton 
          onClick={onClose} 
          variant="text"
          sx={{
            color: theme => theme.palette.mode === 'dark' ? '#fff' : '#0071e3'
          }}
        >
          Cancel
        </AppleButton>
        <AppleButton 
          onClick={onSubmit} 
          variant="contained"
          sx={{
            backgroundColor: theme => 
              theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.15)' 
                : '#0071e3',
            '&:hover': {
              backgroundColor: theme => 
                theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.2)' 
                  : '#0077ed'
            }
          }}
        >
          Submit Feedback
        </AppleButton>
      </DialogActions>
    </AppleDialog>
  );
};