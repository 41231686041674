import React, { useRef, useCallback } from 'react';
import { IconButton, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { PaperclipIcon } from 'lucide-react';
import { getThemeStyles } from '../../themes/themeConfig'; // Asegúrate de que la ruta sea correcta

const HiddenInput = styled('input')({
  display: 'none',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('FileAttachmentButton', theme.palette.mode),
  padding: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MAX_FILE_SIZE = 1024 * 1024; // 1MB
const MAX_FILES = 10;

const FileAttachment = ({ onFileSelect, disabled }) => {
  const theme = useTheme();
  const fileInputRef = useRef(null);

  const convertFileToText = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({
          name: file.name,
          type: file.type,
          size: file.size,
          content: e.target.result
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }, []);

  const handleFileSelect = useCallback(async (event) => {
    const allFiles = Array.from(event.target.files);
    const pdfFiles = allFiles.filter(file => file.type === 'application/pdf' && file.size <= MAX_FILE_SIZE);
    
    if (pdfFiles.length > MAX_FILES) {
      alert(`No se pueden seleccionar más de ${MAX_FILES} archivos PDF.`);
      return;
    }

    if (pdfFiles.length < allFiles.length) {
      alert("Algunos archivos no se han seleccionado porque no son PDF o superan el límite de 1MB.");
    }

    const fileContents = await Promise.all(pdfFiles.map(convertFileToText));
    onFileSelect(pdfFiles, fileContents);
  }, [onFileSelect, convertFileToText]);

  const handleButtonClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  return (
    <Box>
      <StyledIconButton
        onClick={handleButtonClick}
        disabled={disabled}
      >
        <PaperclipIcon />
      </StyledIconButton>
      <HiddenInput
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        multiple
        accept=".pdf"
      />
    </Box>
  );
};

export default React.memo(FileAttachment);