import React, { useState, useCallback, useEffect, useContext, createContext, useRef } from 'react';
import { 
  Box, 
  Typography,
  IconButton,
  Paper,
  TextField,
  Button,
  Collapse,
  CircularProgress,
  Fade,
  Tooltip,
  Zoom,
  Chip,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  ArrowUp,
  Lightbulb,
  Settings,
  History,
  Minimize2,
  AlertCircle,
  Brain,
  Zap,
  MessageCircle
} from 'lucide-react';
import axios from 'axios';
import PropTypes from 'prop-types';

axios.defaults.baseURL = 'http://localhost:5001';

const ProductAIContext = createContext();

const ChatContainer = styled(Paper)(({ theme, isminimized }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  left: '50%',
  transform: 'translateX(-50%)',
  width: '600px',
  maxWidth: '90vw',
  borderRadius: isminimized ? 30 : theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1200,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'none',
  boxShadow: 'none',
  '&:hover': {
    transform: 'translateX(-50%)',
    boxShadow: 'none'
  }
}));

const ChatHeader = styled(Box)(({ theme, isminimized }) => ({
  padding: isminimized ? theme.spacing(0.5, 2) : theme.spacing(2),
  backgroundColor: isminimized ? theme.palette.background.paper : theme.palette.primary.main,
  color: isminimized ? theme.palette.text.primary : theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: theme.transitions.create(['background-color', 'color', 'padding']),
  minHeight: isminimized ? '40px' : 'auto',
  backdropFilter: 'none',
  '&:hover': {
    backgroundColor: isminimized ? theme.palette.background.paper : theme.palette.primary.main
  }
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  maxHeight: 400,
  minHeight: 250,
  overflowY: 'auto',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  scrollBehavior: 'smooth'
}));

const Message = styled(Box)(({ theme, isai, iserror }) => ({
  padding: theme.spacing(1.5, 2),
  borderRadius: theme.spacing(2),
  backgroundColor: iserror ? theme.palette.error.light : 
                  isai ? 'transparent' : 
                  theme.palette.grey[100],
  color: iserror ? theme.palette.error.contrastText :
         isai ? theme.palette.common.white : 
         theme.palette.text.primary,
  alignSelf: isai ? 'flex-start' : 'flex-end',
  maxWidth: isai ? '100%' : '80%',
  width: isai ? '100%' : 'auto',
  position: 'relative',
  transition: 'none',
  '&:hover': {
    transform: 'none'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    [isai ? 'left' : 'right']: -8,
    borderStyle: 'solid',
    borderWidth: '8px',
    borderColor: `transparent transparent ${
      iserror ? theme.palette.error.light :
      isai ? 'transparent' : 
      theme.palette.grey[100]
    } transparent`,
  }
}));

const SuggestionChip = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: theme.spacing(0.5, 2),
  textTransform: 'none',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transform: 'none'
  },
  transition: 'none',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    transition: 'none',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused': {
      boxShadow: 'none',
    }
  }
}));

const MinimizedInput = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(0.5),
  '& .MuiTypography-root': {
    fontSize: '1rem',
    lineHeight: '1.4375em',
    fontFamily: theme.typography.fontFamily,
    padding: '8.5px 14px',
  }
}));

const CircularIconWrapper = styled(Box)(({ theme, color }) => ({
  background: color,
  borderRadius: '50%',
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'none',
  cursor: 'pointer',
  '&:hover': {
    transform: 'none',
    boxShadow: 'none'
  }
}));

const MESSAGE_TYPES = {
  WELCOME: 'welcome',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning'
};

const MODULE_SUGGESTIONS = {
  products: [
    "Analizar mi catálogo",
    "Optimizar productos",
    "Ver métricas de productos",
    "Sugerir mejoras"
  ],
  market: [
    "Analizar competencia",
    "Ver tendencias de mercado",
    "Oportunidades detectadas",
    "Riesgos potenciales"
  ],
  pricing: [
    "Optimizar precios",
    "Calcular márgenes",
    "Estrategias de precio",
    "Análisis de rentabilidad"
  ],
  sales: [
    "Análisis de ventas",
    "Optimizar canales",
    "Proyecciones",
    "Rendimiento por canal"
  ]
};

const AGTProductAI = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [currentModule, setCurrentModule] = useState(null);
  const [sessionId] = useState(`sess_${Date.now()}`);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [userTyping, setUserTyping] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [connectionStatus, setConnectionStatus] = useState('connected');
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        await axios.get('/api/agtups/ai/health');
        setConnectionStatus('connected');
        setError(null);
      } catch (err) {
        setConnectionStatus('disconnected');
        setError('No se pudo conectar con el asistente');
      }
    };

    const interval = setInterval(checkConnection, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (!showSuggestions && !loading && !userTyping) {
      const timer = setTimeout(() => {
        setShowSuggestions(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuggestions, loading, userTyping]);

  useEffect(() => {
    const welcomeMessage = {
      id: Date.now(),
      text: `¡Hola! Soy tu asistente de AGT Product. ${
        currentModule 
          ? `Veo que estás en el módulo ${currentModule}.` 
          : 'Estoy aquí para ayudarte con todos los módulos.'
      }\n\nPuedo ayudarte con:\n• Gestión de productos\n• Análisis de mercado\n• Estrategias de precios\n• Optimización de ventas`,
      isAi: true,
      type: MESSAGE_TYPES.WELCOME,
      metadata: {
        model: 'AGT Assistant',
        timestamp: new Date().toISOString()
      }
    };

    setMessages([welcomeMessage]);
    const initialSuggestions = currentModule 
      ? MODULE_SUGGESTIONS[currentModule] 
      : [
          "¿Qué puedes hacer?",
          "Analizar mi catálogo",
          "Optimizar precios",
          "Ver tendencias"
        ];
    setSuggestions(initialSuggestions);
  }, [currentModule]);

  const handleUserInput = async () => {
    if (!inputValue.trim() || loading) return;

    const userMessage = {
      id: Date.now(),
      text: inputValue,
      isAi: false,
      metadata: {
        timestamp: new Date().toISOString()
      }
    };

    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setLoading(true);
    setShowSuggestions(false);
    setUserTyping(false);
    setLastActivity(Date.now());

    try {
      const response = await axios.post('/api/agtups/ai/chat', {
        sessionId,
        question: inputValue,
        module: currentModule,
        context: {
          lastMessages: messages.slice(-3),
          timestamp: new Date().toISOString()
        }
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-session-id': sessionId
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.error);
      }

      const aiResponse = {
        id: Date.now() + 1,
        text: response.data.data.message,
        isAi: true,
        metadata: {
          ...response.data.data.metadata,
          timestamp: new Date().toISOString()
        }
      };

      setMessages(prev => [...prev, aiResponse]);
      setRetryCount(0);
      setError(null);

      if (response.data.data.suggestions?.length) {
        setSuggestions(response.data.data.suggestions);
        setShowSuggestions(true);
      }

    } catch (error) {
      setRetryCount(prev => prev + 1);
      
      const errorMessage = {
        id: Date.now() + 1,
        text: retryCount > 2 
          ? "Parece que estoy teniendo problemas. ¿Podrías intentarlo más tarde?" 
          : "Lo siento, tuve un problema al procesar tu pregunta. ¿Podrías reformularla?",
        isAi: true,
        isError: true,
        type: MESSAGE_TYPES.ERROR,
        metadata: {
          error: error.message,
          timestamp: new Date().toISOString()
        }
      };

      setMessages(prev => [...prev, errorMessage]);
      setError(error.message);
      setSuggestions([
        "Intentar de nuevo",
        "Reformular pregunta",
        "Ver estado del sistema"
      ]);
      setShowSuggestions(true);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setUserTyping(true);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setUserTyping(true);
    setShowSuggestions(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleUserInput();
    }
  };

  const contextValue = {
    observeAction: useCallback((action) => {
      setCurrentModule(action.module);
      setLastActivity(Date.now());
    }, []),
    currentModule,
    setCurrentModule,
    sessionId
  };

  return (
    <ProductAIContext.Provider value={contextValue}>
      <Fade in={true}>
        <ChatContainer elevation={6} isminimized={isMinimized ? 1 : 0}>
          {isMinimized ? (
            <ChatHeader 
              isminimized="true"
              onClick={() => setIsMinimized(false)}
              sx={{ 
                '&:hover': {
                  bgcolor: 'background.default'
                }
              }}
            >
              <MinimizedInput>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ 
                    flexGrow: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Pregúntame lo que necesites...
                </Typography>
                <IconButton 
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMinimized(false);
                  }}
                  sx={{ 
                    color: 'white',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    }
                  }}
                >
                  <ArrowUp size={16} />
                </IconButton>
              </MinimizedInput>
            </ChatHeader>
          ) : (
            <>
              <ChatHeader 
                isminimized="false"
                onClick={() => setIsMinimized(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    AGT Product AI
                  </Typography>
                  {currentModule && (
                    <Chip
                      label={currentModule}
                      size="small"
                      icon={<Zap size={14} />}
                      sx={{ 
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        color: 'inherit',
                        ml: 1
                      }}
                    />
                  )}
                  {connectionStatus === 'disconnected' && (
                    <Chip
                      label="Desconectado"
                      size="small"
                      color="error"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Historial">
                    <IconButton 
                      size="small" 
                      sx={{ 
                        color: 'white',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <History size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Configuración">
                    <IconButton 
                      size="small" 
                      sx={{ 
                        color: 'white',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Settings size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Minimizar">
                    <IconButton 
                      size="small" 
                      sx={{ 
                        color: 'white',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsMinimized(true);
                      }}
                    >
                      <Minimize2 size={20} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ChatHeader>

              <MessageContainer ref={messageContainerRef}>
                {messages.map((message) => (
                  <Message 
                    key={message.id} 
                    isai={message.isAi ? 1 : 0}
                    iserror={message.isError ? 1 : 0}
                  >
                    <Typography variant="body2">
                      {message.text}
                    </Typography>
                    {message.metadata && (
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ 
                          display: 'block', 
                          mt: 1,
                          opacity: 0.7 
                        }}
                      >
                        {message.metadata.model && (
                          <Box component="span" sx={{ mr: 1 }}>
                            <Brain size={12} style={{ marginRight: 4, verticalAlign: 'middle' }} />
                            {message.metadata.model}
                          </Box>
                        )}
                        {new Date(message.metadata.timestamp).toLocaleTimeString()}
                      </Typography>
                    )}
                  </Message>
                ))}
                
                {loading && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                )}

                {error && !loading && (
                  <Alert 
                    severity="error" 
                    sx={{ mt: 2 }}
                    action={
                      <Button 
                        color="inherit" 
                        size="small"
                        onClick={() => setError(null)}
                      >
                        Cerrar
                      </Button>
                    }
                  >
                    {error}
                  </Alert>
                )}
              </MessageContainer>

              {showSuggestions && suggestions.length > 0 && !loading && (
                <Box sx={{ px: 2, py: 1 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {suggestions.map((suggestion, index) => (
                      <Zoom 
                        key={index} 
                        in={true} 
                        style={{ transitionDelay: `${index * 100}ms` }}
                      >
                        <SuggestionChip
                          size="small"
                          onClick={() => handleSuggestionClick(suggestion)}
                          startIcon={<Lightbulb size={16} />}
                        >
                          {suggestion}
                        </SuggestionChip>
                      </Zoom>
                    ))}
                  </Box>
                </Box>
              )}

              <InputContainer>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <StyledTextField
                    fullWidth
                    size="small"
                    placeholder="Pregúntame lo que necesites..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    disabled={loading || connectionStatus === 'disconnected'}
                    multiline
                    maxRows={4}
                    InputProps={{
                      startAdornment: (
                        <MessageCircle 
                          size={20} 
                          style={{ 
                            marginRight: 8, 
                            opacity: 0.5 
                          }} 
                        />
                      )
                    }}
                  />
                  <IconButton 
                    onClick={handleUserInput}
                    disabled={loading || !inputValue.trim() || connectionStatus === 'disconnected'}
                    sx={{ 
                      color: theme => theme.palette.primary.main,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        transform: 'none'
                      },
                      '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                        color: theme => theme.palette.action.disabled,
                      },
                      transition: 'none'
                    }}
                  >
                    <ArrowUp size={20} />
                  </IconButton>
                </Box>
              </InputContainer>
            </>
          )}
        </ChatContainer>
      </Fade>
    </ProductAIContext.Provider>
  );
};

const AI_ACTION_TYPES = {
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  USER_ACTION: 'USER_ACTION',
  ERROR: 'ERROR',
  SUGGESTION: 'SUGGESTION',
  SYSTEM: 'SYSTEM'
};

AGTProductAI.propTypes = {
  activeTab: PropTypes.number,
  selectedProduct: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.number
  }),
  onProductSelect: PropTypes.func,
  onTabChange: PropTypes.func
};

AGTProductAI.defaultProps = {
  activeTab: 0,
  selectedProduct: null,
  onProductSelect: () => {},
  onTabChange: () => {}
};

export const useProductAI = () => {
  const context = useContext(ProductAIContext);
  if (!context) {
    throw new Error('useProductAI must be used within a ProductAIContext.Provider');
  }
  return context;
};

export {
  ProductAIContext,
  MESSAGE_TYPES,
  MODULE_SUGGESTIONS,
  AI_ACTION_TYPES
};

export default AGTProductAI;