import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Chip, Avatar, AvatarGroup, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Brain, 
  MessageSquare,
  FileText,
  TrendingUp,
  PieChart,
  Target,
  Clock,
  Calendar,
  Book,
  Lightbulb
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const ExpertCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ConsultationCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const AGTCouncil = () => {
  const [consultationText, setConsultationText] = useState('');

  return (
    <Box className="space-y-6">
      {/* Quick Consultation Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Start a Consultation</Typography>
            <Box className="space-y-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Describe your business challenge or decision you need help with..."
                value={consultationText}
                onChange={(e) => setConsultationText(e.target.value)}
              />
              <Box className="flex gap-2">
                <ActionButton>
                  <Brain size={16} />
                  Get Expert Advice
                </ActionButton>
                <ActionButton>
                  <Calendar size={16} />
                  Schedule Meeting
                </ActionButton>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Expert Panel</Typography>
            <Box className="space-y-3">
              <ExpertCard>
                <Avatar sx={{ bgcolor: 'primary.main' }}>JD</Avatar>
                <Box>
                  <Typography variant="subtitle2">Dr. John Doe</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Business Strategy
                  </Typography>
                </Box>
              </ExpertCard>
              <ExpertCard>
                <Avatar sx={{ bgcolor: 'secondary.main' }}>MS</Avatar>
                <Box>
                  <Typography variant="subtitle2">Maria Smith</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Market Analysis
                  </Typography>
                </Box>
              </ExpertCard>
              <ExpertCard>
                <Avatar sx={{ bgcolor: 'error.main' }}>RJ</Avatar>
                <Box>
                  <Typography variant="subtitle2">Robert Johnson</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Financial Advisory
                  </Typography>
                </Box>
              </ExpertCard>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Recent Insights */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recent Consultations</Typography>
            
            <ConsultationCard>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="flex justify-between items-start mb-2">
                    <Box>
                      <Typography variant="subtitle1">Market Expansion Strategy</Typography>
                      <Typography variant="caption" color="text.secondary">
                        <Clock size={14} className="inline mr-1" />
                        Completed 2 hours ago
                      </Typography>
                    </Box>
                    <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
                      <Avatar>JD</Avatar>
                      <Avatar>MS</Avatar>
                      <Avatar>RJ</Avatar>
                    </AvatarGroup>
                  </Box>
                  <Box className="mt-3 space-y-2">
                    <Typography variant="body2">
                      Comprehensive analysis of potential markets and entry strategies...
                    </Typography>
                    <Box className="flex gap-2">
                      <Chip size="small" label="Strategy" />
                      <Chip size="small" label="Market Analysis" />
                      <Chip size="small" label="Growth" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ConsultationCard>

            <ConsultationCard>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="flex justify-between items-start mb-2">
                    <Box>
                      <Typography variant="subtitle1">Product Pricing Review</Typography>
                      <Typography variant="caption" color="text.secondary">
                        <Clock size={14} className="inline mr-1" />
                        Completed yesterday
                      </Typography>
                    </Box>
                    <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
                      <Avatar>MS</Avatar>
                      <Avatar>RJ</Avatar>
                    </AvatarGroup>
                  </Box>
                  <Box className="mt-3 space-y-2">
                    <Typography variant="body2">
                      Analysis of current pricing strategy and recommendations...
                    </Typography>
                    <Box className="flex gap-2">
                      <Chip size="small" label="Pricing" />
                      <Chip size="small" label="Revenue" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ConsultationCard>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Quick Stats</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-blue-50 rounded-lg text-center">
                      <Brain size={24} className="mx-auto mb-1 text-blue-500" />
                      <Typography variant="h6">24</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Consultations
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-green-50 rounded-lg text-center">
                      <Lightbulb size={24} className="mx-auto mb-1 text-green-500" />
                      <Typography variant="h6">89%</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Success Rate
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-purple-50 rounded-lg text-center">
                      <Clock size={24} className="mx-auto mb-1 text-purple-500" />
                      <Typography variant="h6">2.5h</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Avg. Response
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-yellow-50 rounded-lg text-center">
                      <Book size={24} className="mx-auto mb-1 text-yellow-500" />
                      <Typography variant="h6">15</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Reports
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Upcoming Sessions</Typography>
                <Box className="space-y-3">
                  <Box className="p-3 border rounded-lg">
                    <Box className="flex justify-between items-center mb-1">
                      <Typography variant="subtitle2">Strategy Review</Typography>
                      <Chip label="Today" color="primary" size="small" />
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                      2:30 PM - 3:30 PM
                    </Typography>
                  </Box>
                  <Box className="p-3 border rounded-lg">
                    <Box className="flex justify-between items-center mb-1">
                      <Typography variant="subtitle2">Financial Planning</Typography>
                      <Chip label="Tomorrow" color="secondary" size="small" />
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                      10:00 AM - 11:00 AM
                    </Typography>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AGTCouncil;