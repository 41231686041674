import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Copy, Check } from 'lucide-react';

const CodeContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '16px 0',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: 'rgb(40, 44, 52)',
  transform: 'translateZ(0)',
  willChange: 'transform',
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  padding: '12px 24px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LanguageLabel = styled('span')({
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '0.875rem',
  fontWeight: 500,
});

const CopyButton = styled('button')(({ isCopied }) => ({
  padding: '6px',
  borderRadius: '4px',
  backgroundColor: isCopied ? 'rgba(34, 197, 94, 0.2)' : 'rgba(255, 255, 255, 0.1)',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: isCopied ? 'rgba(34, 197, 94, 0.2)' : 'rgba(255, 255, 255, 0.2)',
  }
}));

const CodeContent = styled('div')({
  padding: '24px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  
  '& pre': {
    margin: 0,
    padding: 0,
    background: 'transparent',
  },
  
  '& code': {
    fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
    fontSize: '0.875rem',
    lineHeight: 1.7,
    tabSize: 2,
    color: 'rgba(255, 255, 255, 0.9)',
  }
});

const AnimatedCodeBlock = ({ code, language }) => {
  const [isCopied, setIsCopied] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const height = contentRef.current.getBoundingClientRect().height;
      containerRef.current.style.height = `${height}px`;
    }
  }, [code]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Error copying code:', error);
    }
  };

  const getLanguageDisplay = (lang) => {
    const languageMap = {
      'js': 'JavaScript',
      'jsx': 'JavaScript',
      'ts': 'TypeScript',
      'tsx': 'TypeScript',
      'py': 'Python',
      'java': 'Java',
      'cpp': 'C++',
      'html': 'HTML',
      'css': 'CSS',
      'json': 'JSON',
      'yaml': 'YAML',
      'sql': 'SQL',
      'bash': 'Bash',
      'shell': 'Shell',
      'markdown': 'Markdown',
      'xml': 'XML',
    };
    
    return languageMap[lang?.toLowerCase()] || lang || 'Plain Text';
  };

  return (
    <CodeContainer ref={containerRef}>
      <HeaderContainer>
        <LanguageLabel>{getLanguageDisplay(language)}</LanguageLabel>
        <CopyButton
          onClick={handleCopyClick}
          isCopied={isCopied}
        >
          {isCopied ? (
            <Check size={16} style={{ color: 'rgb(34, 197, 94)' }} />
          ) : (
            <Copy size={16} style={{ color: 'rgba(255, 255, 255, 0.7)' }} />
          )}
        </CopyButton>
      </HeaderContainer>
      
      <CodeContent ref={contentRef}>
        <pre>
          <code className={`language-${language}`}>
            {code}
          </code>
        </pre>
      </CodeContent>
    </CodeContainer>
  );
};

export default React.memo(AnimatedCodeBlock);