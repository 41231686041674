// frontend/src/components/chat/input/PersonalitySelector.js
import React from 'react';
import { Box, Select, MenuItem, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Speech } from 'lucide-react';

const PersonalitySelector = ({ selectedPersonality, onPersonalityChange, disabled, isPro }) => {
  const theme = useTheme();

  const personalities = [
    {
      id: 'normal',
      name: 'Normal',
      description: 'Default assistant behavior',
      proOnly: false
    },
    {
      id: 'charismatic',
      name: 'Charismatic',
      description: 'Warm, sociable with high emotional energy',
      proOnly: true
    },
    {
      id: 'stoic',
      name: 'Stoic',
      description: 'Logical, direct and professionally oriented',
      proOnly: true
    }
  ];

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 1,
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.03)',
      }}
    >
      <Speech
        size={16} 
        style={{ 
          color: theme.palette.mode === 'dark' ? '#007BFE' : '#0056b3',
          flexShrink: 0,
        }} 
      />
      <Select
        value={selectedPersonality}
        onChange={(e) => onPersonalityChange(e.target.value)}
        disabled={disabled}
        size="small"
        displayEmpty
        renderValue={() => {
          if (!isPro) {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: 1.2
                  }}
                >
                  Choose personality
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: theme.palette.text.secondary,
                    lineHeight: 1.2
                  }}
                >
                  Normal is set by default
                </Typography>
              </Box>
            );
          }
          const selected = personalities.find(p => p.id === selectedPersonality);
          return selected?.name || 'Normal';
        }}
        sx={{
          fontSize: '0.875rem',
          '& .MuiSelect-select': {
            padding: '4px 8px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(0, 0, 0, 0.05)',
          },
          minWidth: '140px',
          width: 'auto',
        }}
      >
        {personalities.map((personality) => (
          <MenuItem 
            key={personality.id} 
            value={personality.id}
            disabled={personality.proOnly && !isPro}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '8px 16px',
              opacity: personality.proOnly && !isPro ? 0.5 : 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {personality.name}
              </Typography>
              {personality.proOnly && (
                <Chip
                  label="PRO"
                  size="small"
                  sx={{
                    backgroundColor: '#007BFE',
                    color: 'white',
                    height: '20px',
                    fontSize: '0.625rem'
                  }}
                />
              )}
            </Box>
            <Typography 
              variant="caption" 
              sx={{ 
                color: theme.palette.text.secondary,
                fontSize: '0.75rem'
              }}
            >
              {personality.description}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default PersonalitySelector;