import React from 'react';
import { Box, Select, MenuItem, Typography, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Sparkles } from 'lucide-react';

const ModelSelector = ({ selectedModel, onModelChange, disabled, isPro }) => {
  const theme = useTheme();
  
  const models = [
    { 
      id: 'claude-3-haiku-20240307',
      name: 'AGT Normal',
      description: 'Fast responses for daily tasks',
      proOnly: false
    },
    { 
      id: 'claude-3-sonnet-20240229',
      name: 'AGT Pro',
      description: 'Uses advanced reasoning',
      proOnly: true
    }
  ];

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 1,
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.03)',
      }}
    >
      <Sparkles
        size={16} 
        style={{ 
          color: theme.palette.mode === 'dark' ? '#007BFE' : '#0056b3',
          flexShrink: 0,
        }} 
      />
      <Select
        value={selectedModel}
        onChange={(e) => onModelChange(e.target.value)}
        disabled={disabled}
        size="small"
        displayEmpty
        renderValue={() => {
          if (!isPro) {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: 1.2
                  }}
                >
                  Choose model
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: theme.palette.text.secondary,
                    lineHeight: 1.2
                  }}
                >
                  AGT Normal is set by default
                </Typography>
              </Box>
            );
          }
          const selected = models.find(m => m.id === selectedModel);
          return selected?.name || 'AGT Normal';
        }}
        sx={{
          fontSize: '0.875rem',
          '& .MuiSelect-select': {
            padding: '4px 8px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(0, 0, 0, 0.05)',
          },
          minWidth: '140px',
          width: 'auto',
        }}
      >
        {models.map((model) => (
          <MenuItem 
            key={model.id} 
            value={model.id}
            disabled={model.proOnly && !isPro}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '8px 16px',
              opacity: model.proOnly && !isPro ? 0.5 : 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {model.name}
              </Typography>
              {model.proOnly && (
                <Chip
                  label="PRO"
                  size="small"
                  sx={{
                    backgroundColor: '#007BFE',
                    color: 'white',
                    height: '20px',
                    fontSize: '0.625rem'
                  }}
                />
              )}
            </Box>
            <Typography 
              variant="caption" 
              sx={{ 
                color: theme.palette.text.secondary,
                fontSize: '0.75rem'
              }}
            >
              {model.description}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default ModelSelector;