import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  LinearProgress, 
  Chip,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Badge,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  BarChart3, 
  TrendingUp, 
  TrendingDown,
  Users,
  Target,
  LineChart,
  PieChart,
  Download,
  RefreshCcw,
  AlertCircle,
  Globe,
  Share2,
  Eye,
  UserCheck,
  ShoppingCart,
  DollarSign,
  Calendar,
  Info,
  ArrowUpRight,
  ArrowDownRight,
  Package,
  Brain
} from 'lucide-react';
import { 
  LineChart as RechartsLineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer, 
  PieChart as RechartsPieChart, 
  Pie, 
  Cell,
  Legend 
} from 'recharts';

import MarketAnalysisService from '../../services/AGTUps-product-services/marketAnalysisService';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const MetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1,
  borderRadius: theme.spacing(2),
}));

const COLORS = {
  primary: '#4f46e5',
  secondary: '#0891b2',
  success: '#059669',
  warning: '#eab308',
  error: '#dc2626'
};

const MarketAnalysis = ({ selectedProduct }) => {
  const [timeRange, setTimeRange] = useState('6m');
  const [analysisType, setAnalysisType] = useState('market');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [marketData, setMarketData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    if (selectedProduct?.id && !initialLoadDone) {
      loadMarketData();
      setInitialLoadDone(true);
    }
  }, [selectedProduct, initialLoadDone]);

  useEffect(() => {
    if (selectedProduct?.id && initialLoadDone) {
      loadMarketData();
    }
  }, [selectedProduct, timeRange]);

  const loadMarketData = async () => {
    try {
      setLoading(true);
      setError(null);

      const existingAnalysis = await MarketAnalysisService.getMarketAnalysisByProduct(
        selectedProduct.id,
        { timeRange }
      );

      if (!existingAnalysis.data || existingAnalysis.data.length === 0) {
        const newAnalysis = await MarketAnalysisService.createAnalysis(
          selectedProduct.id
        );
        setMarketData(newAnalysis.data);
      } else {
        setMarketData(existingAnalysis.data[0]);
      }

      setLastUpdated(new Date());
    } catch (error) {
      setError(`Error loading market data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      const response = await MarketAnalysisService.createAnalysis(
        selectedProduct.id
      );
      setMarketData(response.data);
      setLastUpdated(new Date());
    } catch (error) {
      setError(`Error updating analysis: ${error.message}`);
    } finally {
      setUpdating(false);
    }
  };

  const handleExportData = async () => {
    try {
      const data = {
        marketAnalysis: marketData,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange,
        metadata: {
          version: '1.0',
          generatedBy: 'AGT Market Analysis',
          lastUpdated: lastUpdated?.toISOString()
        }
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `market-analysis-${selectedProduct.id}-${timeRange}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError(`Error exporting data: ${error.message}`);
    }
  };

  const formattedMarketData = useMemo(() => {
    if (!marketData) return null;
    return MarketAnalysisService.formatChartData(marketData);
  }, [marketData]);

  const metrics = useMemo(() => {
    if (!marketData) return null;
    return MarketAnalysisService.calculateMetrics(marketData);
  }, [marketData]);

  if (!selectedProduct) {
    return (
      <StyledPaper>
        <Box className="text-center py-8">
          <Package size={48} className="mx-auto mb-4 text-gray-400" />
          <Typography variant="h6" gutterBottom>
            No Product Selected
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Please select a product to view market analysis
          </Typography>
        </Box>
      </StyledPaper>
    );
  }

  return (
    <Box className="space-y-6">
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          action={
            <Button color="inherit" size="small" onClick={() => setError(null)}>
              DISMISS
            </Button>
          }
        >
          {error}
        </Alert>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              label="Time Range"
              onChange={(e) => setTimeRange(e.target.value)}
              disabled={loading || updating}
            >
              <MenuItem value="1m">Last Month</MenuItem>
              <MenuItem value="3m">Last 3 Months</MenuItem>
              <MenuItem value="6m">Last 6 Months</MenuItem>
              <MenuItem value="1y">Last Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Analysis Type</InputLabel>
            <Select
              value={analysisType}
              label="Analysis Type"
              onChange={(e) => setAnalysisType(e.target.value)}
              disabled={loading || updating}
            >
              <MenuItem value="market">Market Share</MenuItem>
              <MenuItem value="competitors">Competitor Analysis</MenuItem>
              <MenuItem value="trends">Market Trends</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} className="flex justify-end gap-2">
          <Tooltip title={lastUpdated ? `Last updated: ${lastUpdated.toLocaleString()}` : 'Never updated'}>
            <IconButton 
              onClick={handleRefreshData} 
              disabled={updating || loading}
            >
              <RefreshCcw 
                size={20} 
                className={updating ? 'animate-spin' : ''} 
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export Analysis">
            <IconButton 
              onClick={handleExportData}
              disabled={loading || !marketData}
            >
              <Download size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share Analysis">
            <IconButton disabled={loading}>
              <Share2 size={20} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {loading && !marketData ? (
        <Box className="flex justify-center p-8">
          <CircularProgress />
        </Box>
      ) : (
        <React.Fragment>
          <StyledPaper>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography variant="h5">{selectedProduct.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Market Performance Overview
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <Badge 
                  color="primary" 
                  badgeContent={marketData?.status || 'Active'} 
                  sx={{ '& .MuiBadge-badge': { fontSize: '0.75rem' } }}
                >
                  <Chip 
                    icon={<DollarSign size={16} />}
                    label={`$${selectedProduct.price}`}
                    color="primary"
                    variant="outlined"
                  />
                </Badge>
                {marketData?.insights?.growthPrediction?.confidence && (
                  <Tooltip title="AI Confidence Level">
                    <Chip 
                      icon={<Brain size={16} />}
                      label={marketData.insights.growthPrediction.confidence}
                      color={
                        marketData.insights.growthPrediction.confidence === 'high' 
                          ? 'success' 
                          : marketData.insights.growthPrediction.confidence === 'medium'
                          ? 'warning'
                          : 'error'
                      }
                      variant="outlined"
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>

            {metrics && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <MetricCard>
                    <Box className="flex items-center gap-2">
                      <Target size={20} className="text-blue-500" />
                      <Typography variant="body2">Market Fit</Typography>
                    </Box>
                    <Typography variant="h6">
                      {metrics.marketFit}%
                    </Typography>
                  </MetricCard>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <MetricCard>
                    <Box className="flex items-center gap-2">
                      <TrendingUp size={20} className="text-green-500" />
                      <Typography variant="body2">Growth Rate</Typography>
                    </Box>
                    <Typography variant="h6">
                      {metrics.growthPotential?.potential}%
                    </Typography>
                  </MetricCard>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <MetricCard>
                    <Box className="flex items-center gap-2">
                      <Users size={20} className="text-purple-500" />
                      <Typography variant="body2">Market Share</Typography>
                    </Box>
                    <Typography variant="h6">
                      {marketData?.marketShare}%
                    </Typography>
                  </MetricCard>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <MetricCard>
                    <Box className="flex items-center gap-2">
                      <Globe size={20} className="text-orange-500" />
                      <Typography variant="body2">Market Reach</Typography>
                    </Box>
                    <Typography variant="h6">
                      {Object.keys(marketData?.geographicDistribution || {}).length} Regions
                    </Typography>
                  </MetricCard>
                </Grid>
              </Grid>
            )}
          </StyledPaper>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <StyledPaper>
                {updating && <LoadingOverlay><CircularProgress /></LoadingOverlay>}
                <Box className="flex justify-between items-center mb-6">
                  <Typography variant="h6">Market Share Trends</Typography>
                  <Box className="flex gap-2">
                    <Chip 
                      size="small" 
                      icon={<Eye size={14} />}
                      label="Market Share" 
                      color="primary"
                    />
                    <Chip 
                      size="small" 
                      icon={<Users size={14} />}
                      label="Competitors" 
                      variant="outlined"
                    />
                    <Chip 
                      size="small" 
                      icon={<Target size={14} />}
                      label="Market Potential" 
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box className="h-80">
                  {formattedMarketData?.marketShare?.data ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <RechartsLineChart data={formattedMarketData.marketShare.data}>
                        <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
                        <XAxis 
                          dataKey="name" 
                          label={{ 
                            value: 'Time Period', 
                            position: 'bottom' 
                          }} 
                        />
                        <YAxis 
                          label={{ 
                            value: 'Market Share (%)', 
                            angle: -90, 
                            position: 'insideLeft' 
                          }} 
                        />
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            border: 'none',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                          }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="marketShare" 
                          name="Market Share"
                          stroke={COLORS.primary} 
                          strokeWidth={2} 
                          dot={{ r: 4 }}
                          activeDot={{ r: 8 }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="competitors" 
                          name="Competitors"
                          stroke={COLORS.secondary} 
                          strokeWidth={2} 
                          dot={{ r: 4 }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="potential" 
                          name="Market Potential"
                          stroke={COLORS.success} 
                          strokeWidth={2} 
                          dot={{ r: 4 }}
                        />
                        <Legend />
                      </RechartsLineChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box className="h-full flex items-center justify-center">
                      <Typography color="text.secondary">
                        No market share data available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" gutterBottom>Market Position</Typography>
                    {marketData ? (
                      <Box className="space-y-4">
                        <Box>
                          <Box className="flex justify-between mb-1">
                            <Typography variant="body2">Market Share</Typography>
                            <Box className="flex items-center gap-1">
                              {metrics?.yearOverYear?.growth > 0 ? (
                                <ArrowUpRight size={16} className="text-green-500" />
                              ) : (
                                <ArrowDownRight size={16} className="text-red-500" />
                              )}
                              <Typography 
                                variant="body2" 
                                color={metrics?.yearOverYear?.growth > 0 ? "success.main" : "error"}
                              >
                                {marketData.marketShare}%
                              </Typography>
                            </Box>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={marketData.marketShare} 
                          />
                        </Box>

                        <Box>
                          <Box className="flex justify-between mb-1">
                            <Typography variant="body2">Competitive Position</Typography>
                            <Box className="flex items-center gap-1">
                              <ArrowUpRight size={16} className="text-green-500" />
                              <Typography variant="body2" color="success.main">
                                {metrics?.competitivePosition}%
                              </Typography>
                            </Box>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={metrics?.competitivePosition || 0} 
                            color="success" 
                          />
                        </Box>

                        <Box>
                          <Box className="flex justify-between mb-1">
                            <Typography variant="body2">Market Penetration</Typography>
                            <Box className="flex items-center gap-1">
                              {metrics?.marketPenetration > 0 ? (
                                <ArrowUpRight size={16} className="text-green-500" />
                              ) : (
                                <ArrowDownRight size={16} className="text-red-500" />
                              )}
                              <Typography 
                                variant="body2" 
                                color={metrics?.marketPenetration > 0 ? "success.main" : "error"}
                              >
                                {metrics?.marketPenetration}%
                              </Typography>
                            </Box>
                          </Box>
                          <LinearProgress 
                            variant="determinate" 
                            value={metrics?.marketPenetration || 0} 
                            color="warning" 
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box className="p-4 text-center">
                        <CircularProgress size={24} />
                      </Box>
                    )}
                  </StyledPaper>
                </Grid>

                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" gutterBottom>Key Metrics</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MetricCard>
                          <Box className="flex items-center gap-2 mb-1">
                            <UserCheck size={20} className="text-blue-500" />
                            <Typography variant="body2">Market Fit</Typography>
                          </Box>
                          <Typography variant="h6">
                            {metrics?.marketFit || 'N/A'}%
                          </Typography>
                          {metrics?.yearOverYear?.growth && (
                            <Box className="flex items-center gap-1 mt-1">
                              {metrics.yearOverYear.growth > 0 ? (
                                <TrendingUp size={14} className="text-green-500" />
                              ) : (
                                <TrendingDown size={14} className="text-red-500" />
                              )}
                              <Typography 
                                variant="caption" 
                                color={metrics.yearOverYear.growth > 0 ? "success.main" : "error"}
                              >
                                {Math.abs(metrics.yearOverYear.growth)}% vs last year
                              </Typography>
                            </Box>
                          )}
                        </MetricCard>
                      </Grid>

                      <Grid item xs={6}>
                        <MetricCard>
                          <Box className="flex items-center gap-2 mb-1">
                            <ShoppingCart size={20} className="text-purple-500" />
                            <Typography variant="body2">Growth Potential</Typography>
                          </Box>
                          <Typography variant="h6">
                            {metrics?.growthPotential?.potential || 'N/A'}%
                          </Typography>
                          {metrics?.growthPotential?.confidence && (
                            <Box className="flex items-center gap-1 mt-1">
                              <Brain size={14} className="text-blue-500" />
                              <Typography variant="caption" color="text.secondary">
                                {metrics.growthPotential.confidence} confidence
                              </Typography>
                            </Box>
                          )}
                        </MetricCard>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Market Share Distribution</Typography>
                {formattedMarketData?.competitors ? (
                  <Box className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <RechartsPieChart>
                        <Pie
                          data={formattedMarketData.competitors}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={100}
                          dataKey="value"
                          labelLine={false}
                          label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                        >
                          {formattedMarketData.competitors.map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={entry.color}
                              className="transition-all duration-300 hover:opacity-80"
                            />
                          ))}
                        </Pie>
                        <RechartsTooltip
                          content={({ active, payload }) => {
                            if (!active || !payload?.[0]) return null;
                            const data = payload[0].payload;
                            return (
                              <Box className="bg-white p-3 rounded-lg shadow-lg border">
                                <Typography variant="subtitle2" className="mb-2">
                                  {data.name}
                                </Typography>
                                <Typography variant="body2">
                                  Market Share: {data.value.toFixed(1)}%
                                </Typography>
                                {data.strengths?.length > 0 && (
                                  <Box className="mt-2">
                                    <Typography variant="caption" color="success.main">
                                      Strengths: {data.strengths.join(', ')}
                                    </Typography>
                                  </Box>
                                )}
                                {data.weaknesses?.length > 0 && (
                                  <Box className="mt-1">
                                    <Typography variant="caption" color="error">
                                      Weaknesses: {data.weaknesses.join(', ')}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            );
                          }}
                        />
                        <Legend verticalAlign="bottom" height={36} />
                      </RechartsPieChart>
                    </ResponsiveContainer>
                  </Box>
                ) : (
                  <Box className="h-80 flex items-center justify-center">
                    <Typography color="text.secondary">
                      No competitor data available
                    </Typography>
                  </Box>
                )}
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledPaper>
                <Box className="flex justify-between items-center mb-4">
                  <Typography variant="h6">Market Insights</Typography>
                  <Chip
                    icon={<Brain size={16} />}
                    label="AI Generated"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </Box>
                <Box className="space-y-4">
                  {marketData?.insights ? (
                    <>
                      {marketData.insights.opportunities?.map((opportunity, index) => (
                        <Alert 
                          key={index}
                          severity="info" 
                          icon={<TrendingUp />}
                          className="mb-2"
                        >
                          <Typography variant="subtitle2">
                            Growth Opportunity
                          </Typography>
                          <Typography variant="body2">
                            {opportunity.description}
                          </Typography>
                          <Box className="flex gap-2 mt-1">
                            <Chip 
                              label={`Impact: ${opportunity.impact}`}
                              size="small"
                              color="primary"
                              variant="outlined"
                            />
                            {opportunity.timeframe && (
                              <Chip 
                                label={opportunity.timeframe}
                                size="small"
                                variant="outlined"
                              />
                            )}
                          </Box>
                        </Alert>
                      ))}

                      {marketData.insights.threats?.map((threat, index) => (
                        <Alert 
                          key={index}
                          severity="warning" 
                          icon={<AlertCircle />}
                          className="mb-2"
                        >
                          <Typography variant="subtitle2">
                            Market Threat
                          </Typography>
                          <Typography variant="body2">
                            {threat.description}
                          </Typography>
                          <Box className="flex gap-2 mt-1">
                            <Chip 
                              label={`Severity: ${threat.severity}`}
                              size="small"
                              color="warning"
                              variant="outlined"
                            />
                            <Chip 
                              label={`Probability: ${threat.probability}`}
                              size="small"
                              variant="outlined"
                            />
                          </Box>
                        </Alert>
                      ))}

                      {marketData.insights.recommendations?.map((recommendation, index) => (
                        <Alert 
                          key={index}
                          severity="success" 
                          icon={<Info />}
                          className="mb-2"
                        >
                          <Typography variant="subtitle2">
                            Strategic Recommendation
                          </Typography>
                          <Typography variant="body2">
                            {recommendation.strategy}
                          </Typography>
                          <Box className="flex gap-2 mt-1">
                            <Chip 
                              label={`Priority: ${recommendation.priority}`}
                              size="small"
                              color="success"
                              variant="outlined"
                            />
                            <Chip 
                              label={recommendation.expectedImpact}
                              size="small"
                              variant="outlined"
                            />
                          </Box>
                        </Alert>
                      ))}
                    </>
                  ) : (
                    <Box className="text-center py-4">
                      <Typography color="text.secondary">
                        No market insights available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
};

export default MarketAnalysis;