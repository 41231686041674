import axios from 'axios';

const AGT_API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://hftkafwf64.execute-api.us-east-2.amazonaws.com/prod'
  : 'http://localhost:5000';
const STANDARD_TIMEOUT = 300000; // 5 minutos en milisegundos

const api = axios.create({
  baseURL: AGT_API_URL,
  timeout: STANDARD_TIMEOUT,
  withCredentials: true
});

// Interceptor para manejar errores globalmente
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // No logueamos errores de rate limit ya que son esperados
      if (error.response.status !== 429) {
        console.error('API Error:', error.response.status, error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

// Definimos los tipos de fondo válidos
const VALID_BACKGROUND_TYPES = ['system', 'light', 'dark'];

export const getChats = async (userId, options = {}) => {
  const {
    limit = 20,
    lastEvaluatedKey = null
  } = options;

  try {
    // Construir los parámetros de la query
    const params = new URLSearchParams({
      limit: limit.toString()
    });

    if (lastEvaluatedKey) {
      params.append('lastEvaluatedKey', JSON.stringify(lastEvaluatedKey));
    }

    const response = await api.get(`/api/chats/${userId}?${params}`);

    const chats = Array.isArray(response.data.chats) 
      ? response.data.chats
          .filter(chat => chat && chat.id)
          .map(chat => ({
            ...chat,
            title: chat.title || chat.name || "New chat",
            updatedAt: chat.updatedAt || chat.createdAt
          }))
      : [];

    return {
      chats,
      hasMore: response.data.hasMore,
      lastEvaluatedKey: response.data.lastEvaluatedKey
    };
  } catch (error) {
    console.error('Error fetching chats:', error);
    return {
      chats: [],
      hasMore: false,
      lastEvaluatedKey: null
    };
  }
};

export const getChatMessages = async (userId, chatId) => {
  try {
    if (!chatId) {
      return [];
    }
    const response = await api.get(`/api/chat-messages/${userId}/${chatId}`);
    return response.data.messages || [];
  } catch (error) {
    return [];
  }
};

export const sendMessageToAGT = async (
  message, 
  userId, 
  chatId, 
  context, 
  selectedModel, 
  personality = 'normal',
  userName = ''
) => {
  try {
    console.log("AGTService - Enviando nombre:", userName); // Log para debug
    
    const endpoint = chatId ? '/api/chat' : '/api/create-chat';
    
    const payload = {
      message,
      userId,
      uid: userId,
      chatId,
      context,
      model: selectedModel,
      personality,
      userName // Asegurarse de que esto se incluya en el payload
    };

    console.log("AGTService - Payload completo:", payload); // Log para debug

    const response = await api.post(endpoint, payload);
    
    return {
      reply: response.data.reply,
      updatedContext: response.data.updatedContext,
      title: response.data.title,
      chatId: response.data.chatId || (response.data.chat && response.data.chat.id)
    };
  } catch (error) {
    if (error.response?.status === 429) {
      const rateLimitError = new Error('Rate limit exceeded');
      rateLimitError.response = {
        status: 429,
        data: {
          message: error.response.data.message || 'Has alcanzado el límite de mensajes por hora',
          resetIn: error.response.data.resetIn,
          isPro: error.response.data.isPro,
          currentCount: error.response.data.currentCount,
          limit: error.response.data.limit
        }
      };
      throw rateLimitError;
    }
    
    if (error.code === 'ECONNABORTED') {
      throw new Error('La solicitud ha excedido el tiempo de espera. Por favor, inténtalo de nuevo.');
    }
    
    throw new Error('Error al enviar el mensaje. Por favor, intenta de nuevo.');
  }
};

export const createNewChat = async (userId) => {
  try {
    const payload = {
      userId,
      uid: userId  // Añadimos el uid explícitamente
    };
    
    const response = await api.post('/api/create-chat', payload);
    return {
      id: response.data.chat.id,
      title: response.data.chat.title || "New chat",
      name: response.data.chat.name || "New chat",
      createdAt: response.data.chat.createdAt,
      updatedAt: response.data.chat.updatedAt || response.data.chat.createdAt
    };
  } catch (error) {
    throw new Error('Failed to create new chat. Please try again.');
  }
};

export const getChatContext = async (userId, chatId) => {
  try {
    if (!chatId) {
      return null;
    }
    const response = await api.get(`/api/chat-context/${userId}/${chatId}`);
    return response.data.context;
  } catch (error) {
    return null;
  }
};

export const getAnonymousChatHistory = async (sessionId) => {
  try {
    const response = await api.get(`/api/anonymous-chat/${sessionId}`);
    return response.data.messages || [];
  } catch (error) {
    return [];
  }
};

export const updateChatTitle = async (userId, chatId, newTitle) => {
  try {
    const payload = {
      userId,
      uid: userId,  // Añadimos el uid explícitamente
      chatId,
      newTitle
    };
    
    const response = await api.put('/api/update-chat-title', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update chat title');
  }
};

export const generateChatSummary = async (userId, chatId, generateSummary = false, chatTitle) => {
  try {
    const payload = {
      userId,
      uid: userId,  // Añadimos el uid explícitamente
      chatId,
      generateSummary,
      chatTitle,
      isFinal: false
    };
    
    const response = await api.post('/api/generate-summary', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate chat summary');
  }
};

export const deleteChat = async (userId, chatId) => {
  try {
    // Añadimos el uid como query parameter
    const response = await api.delete(`/api/delete-chat/${userId}/${chatId}?uid=${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete chat');
  }
};

export const sendFeedback = async (userId, messageId, feedbackType, details, reason) => {
  if (!userId || !messageId || !feedbackType) {
    throw new Error('userId, messageId, and feedbackType are required');
  }

  try {
    const payload = {
      userId,
      uid: userId,  // Añadimos el uid explícitamente
      messageId,
      feedbackType,
      details,
      reason
    };
    
    const response = await api.post('/api/feedback', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to send feedback');
  }
};

export const setUserBackgroundPreference = async (userId, backgroundType) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  if (!VALID_BACKGROUND_TYPES.includes(backgroundType)) {
    backgroundType = 'system';
  }

  try {
    const payload = { 
      userId, 
      uid: userId,  // Añadimos el uid explícitamente
      backgroundType
    };

    const response = await api.put('/api/user-background', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to set user background preference');
  }
};

export const getUserBackgroundPreference = async (userId) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    // Añadimos el uid como query parameter
    const response = await api.get(`/api/user-background/${userId}?uid=${userId}`);
    const backgroundType = response.data.backgroundType;
    
    if (!backgroundType || !VALID_BACKGROUND_TYPES.includes(backgroundType)) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }
    
    return backgroundType;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }
    return 'system';
  }
};

export const updateChatTimestamp = async (userId, chatId) => {
  try {
    // Añadimos el uid como query parameter
    const response = await api.put(`/api/update-chat-timestamp/${userId}/${chatId}?uid=${userId}`);
    return response.data.updatedAt;
  } catch (error) {
    throw new Error('Failed to update chat timestamp');
  }
};

export const createProCheckoutSession = async (userId) => {
  try {
    const payload = {
      userId,
      uid: userId,  // Añadimos el uid explícitamente
      planType: 'pro'
    };
    
    const response = await api.post('/api/create-checkout-session', payload);
    return response.data.url;
  } catch (error) {
    throw new Error('Failed to create checkout session');
  }
};

export const checkProStatus = async (userId) => {
  try {
    // Añadimos el uid como query parameter
    const response = await api.get(`/api/check-pro-status/${userId}?uid=${userId}`);
    return {
      isPro: response.data.isPro,
      proExpirationDate: response.data.proExpirationDate
    };
  } catch (error) {
    return { isPro: false };
  }
};

export const getUserPersonalityPreference = async (userId) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const response = await api.get(`/api/user-personality/${userId}?uid=${userId}`);
    return response.data.personalityPreference;
  } catch (error) {
    console.error('Error getting personality preference:', error);
    return 'normal';
  }
};

export const setUserPersonalityPreference = async (userId, personalityPreference) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const payload = {
      userId,
      uid: userId,
      personalityPreference
    };

    const response = await api.put('/api/user-personality', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to set user personality preference');
  }
};

export default {
  getChats,
  getChatMessages,
  sendMessageToAGT,
  createNewChat,
  getChatContext,
  getAnonymousChatHistory,
  updateChatTitle,
  generateChatSummary,
  deleteChat,
  sendFeedback,
  setUserBackgroundPreference,
  getUserBackgroundPreference,
  updateChatTimestamp,
  createProCheckoutSession,
  checkProStatus,
  getUserPersonalityPreference,
  setUserPersonalityPreference
};