import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, IconButton, Tooltip, Typography, Popover, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X, Copy, Download } from 'lucide-react';
import mermaid from 'mermaid';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import StyledReactMarkdown from './AGTMakerStyles';
import AGTMakerCode from './AGTMakerCode';
import AGTMakerExport from './AGTMakerExport';
import AGTMakerSearch from './AGTMakerSearch';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

const AGTMaker = ({ content, onDelete, isOpen, onClose, onContentReady = () => {} }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [isContentReady, setIsContentReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [mermaidSvgs, setMermaidSvgs] = useState({});
  const [hasContent, setHasContent] = useState(false);
  const contentRef = useRef(null);
  const searchContentRef = useRef(null);

  useEffect(() => {
    mermaid.initialize({ 
      startOnLoad: false,
      theme: 'default',
      securityLevel: 'loose',
    });

    Prism.highlightAll();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const agtContent = extractAGTContent(content);
      setOriginalContent(agtContent);
      setDisplayedContent(agtContent);
      setHasContent(agtContent.trim().length > 0);
      renderMermaidDiagrams(agtContent).then(() => {
        setIsLoading(false);
        setIsContentReady(true);
        onContentReady();
        Prism.highlightAll();
      });
    }
  }, [isOpen, content, onContentReady]);

  const extractAGTContent = useCallback((text) => {
    const agtRegex = /<agt>([\s\S]*?)<\/agt>/g;
    const matches = text.match(agtRegex);
    if (matches) {
      return matches.map(match => match.replace(/<\/?agt>/g, '')).join('\n\n');
    }
    return '';
  }, []);

  const renderMermaidDiagrams = useCallback(async (text) => {
    const mermaidRegex = /```mermaid([\s\S]*?)```/g;
    const matches = text.matchAll(mermaidRegex);
    const newSvgs = {};
    let index = 0;
    
    for (const match of matches) {
      try {
        const { svg } = await mermaid.render(`mermaid-${index}`, match[1].trim());
        newSvgs[index] = svg;
        index++;
      } catch (error) {
        console.error('Error rendering Mermaid diagram:', error);
        newSvgs[index] = `<div>Error rendering diagram</div>`;
        index++;
      }
    }
    setMermaidSvgs(newSvgs);
  }, []);

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(displayedContent);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }, [displayedContent]);

  const handleDownloadClick = useCallback((event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setAnchorEl(event.currentTarget);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDownloadTxt = useCallback(async () => {
    try {
      await AGTMakerExport.exportToTxt(displayedContent);
      handleDownloadClose();
    } catch (error) {
      console.error('Error exporting TXT:', error);
    }
  }, [displayedContent, handleDownloadClose]);

  const handleDownloadPdf = useCallback(async () => {
    try {
      await AGTMakerExport.exportToPdf(displayedContent, mermaidSvgs);
      handleDownloadClose();
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  }, [displayedContent, mermaidSvgs, handleDownloadClose]);

  const handleDownloadDocx = useCallback(async () => {
    try {
      await AGTMakerExport.exportToDocx(displayedContent);
      handleDownloadClose();
    } catch (error) {
      console.error('Error exporting DOCX:', error);
    }
  }, [displayedContent, handleDownloadClose]);

  const renderContent = useCallback(() => {
    if (isLoading || !isContentReady) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography>Loading...</Typography>
        </Box>
      );
    }
  
    const blocks = displayedContent.split(/(```[\s\S]*?```)/);
    
    return (
      <Box>
        {blocks.map((block, index) => {
          if (block.startsWith('```') && block.endsWith('```')) {
            const [firstLine, ...codeLines] = block
              .slice(3, -3)
              .split('\n');
            
            const language = firstLine.trim();
            const code = codeLines.join('\n');
            
            if (language === 'mermaid') {
              return null;
            }
  
            return (
              <Box key={`code-block-${index}`} sx={{ position: 'relative', mb: 4 }}>
                <Box className="code-container searchable-content">
                  <AGTMakerCode
                    code={code}
                    language={language}
                    searchable={true}
                  />
                </Box>
              </Box>
            );
          }
  
          return (
            <div key={`content-${index}`} className="content-block content-line searchable-content">
              <StyledReactMarkdown
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
                isNew={true}
              >
                {block}
              </StyledReactMarkdown>
            </div>
          );
        })}
        
        {Object.entries(mermaidSvgs).map(([index, svg]) => (
          <div 
            key={`mermaid-${index}`}
            className="mermaid-diagram searchable-content"
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        ))}
      </Box>
    );
  }, [displayedContent, mermaidSvgs, isLoading, isContentReady]);

  const WhiteboardContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '10px',
    left: '10px',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    border: 'none',
    color: theme.palette.text.primary,
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease',
    transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
    opacity: isOpen ? 1 : 0,
    zIndex: 1300,
    borderRadius: '12px',
    ...(theme.palette.mode === 'dark' && {
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
      border: '1px solid rgba(255, 255, 255, 0.05)',
    }),
  }));

  const WhiteboardHeader = styled(Box)(({ theme }) => ({
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : theme.palette.divider}`,
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    position: 'relative',
  }));

  const WhiteboardTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: '1.25rem',
  }));

  const ContentArea = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: '24px',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    position: 'relative',
    height: 'calc(100vh - 200px)',
    '& .katex': {
      fontSize: '1.1em',
      fontFamily: 'KaTeX_Math',
    },
    '& .katex-display': {
      margin: '1em 0',
      overflow: 'auto hidden',
      '& > .katex': {
        maxWidth: '100%',
      }
    },
    '& .math-inline': {
      padding: '0 0.2em',
    },
    '& .search-highlight-overlay': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 0, 0.15)' : 'rgba(255, 255, 0, 0.3)',
      borderRadius: '2px',
    },
    '& .search-highlight-active': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 165, 0, 0.3)' : 'rgba(255, 165, 0, 0.5) !important',
    },
    '& .content-line': {
      padding: '2px 0',
      transition: 'background-color 0.3s ease',
    },
    '& .code-container': {
      borderRadius: '8px',
      overflow: 'hidden',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
      },
    },
    '& .searchable-content': {
      position: 'relative',
      '& .search-highlight-overlay': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 0, 0.15)' : 'rgba(255, 255, 0, 0.3)',
        borderRadius: '2px',
      },
      '& .search-highlight-active': {
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 165, 0, 0.3)' : 'rgba(255, 165, 0, 0.5) !important',
      },
    },
  }));

  const WhiteboardFooter = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderTop: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
  }));

  if (!isOpen || !hasContent) return null;

  return (
    <WhiteboardContainer>
      <WhiteboardHeader>
        <WhiteboardTitle>AGT Maker</WhiteboardTitle>
        <IconButton 
          onClick={onClose}
          sx={{ 
            position: 'absolute', 
            right: '8px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <X />
        </IconButton>
      </WhiteboardHeader>
      
      <ContentArea>
        <Box ref={searchContentRef}>
          {renderContent()}
        </Box>
      </ContentArea>

      {isContentReady && (
        <WhiteboardFooter>
          <Tooltip title="Copy content">
            <IconButton onClick={handleCopy}>
              <Copy />
            </IconButton>
          </Tooltip>
          <Tooltip title="Search content">
            <span>
              <AGTMakerSearch 
                content={displayedContent} 
                contentRef={searchContentRef}
              />
            </span>
          </Tooltip>
          <Tooltip title="Download content">
            <IconButton onClick={handleDownloadClick}>
              <Download />
            </IconButton>
          </Tooltip>
        </WhiteboardFooter>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDownloadClose}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleDownloadTxt}>Download as TXT</MenuItem>
        <MenuItem onClick={handleDownloadPdf}>Download as PDF</MenuItem>
        <MenuItem onClick={handleDownloadDocx}>Download as DOCX</MenuItem>
      </Popover>
    </WhiteboardContainer>
  );
};

export default React.memo(AGTMaker);