import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/agtups/products`;

class CooldownError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CooldownError';
  }
}

class SmartRateLimiter {
  constructor() {
    this.activityLog = new Map();
    this.suspiciousPatterns = new Map();
    this.cleanupInterval = setInterval(() => this.cleanup(), 30 * 60 * 1000);
  }

  cleanup() {
    const now = Date.now();
    const timeWindow = 30 * 60 * 1000;
    
    for (const [key, activities] of this.activityLog.entries()) {
      const recentActivities = activities.filter(activity => 
        now - activity.timestamp < timeWindow
      );
      
      if (recentActivities.length === 0) {
        this.activityLog.delete(key);
        this.suspiciousPatterns.delete(key);
      } else {
        this.activityLog.set(key, recentActivities);
      }
    }
  }

  logActivity(userId, action, details) {
    if (!this.activityLog.has(userId)) {
      this.activityLog.set(userId, []);
    }

    const activities = this.activityLog.get(userId);
    activities.push({
      timestamp: Date.now(),
      action,
      details
    });

    this.analyzePattern(userId);
  }

  analyzePattern(userId) {
    const activities = this.activityLog.get(userId);
    if (!activities || activities.length < 5) return false;

    const recentActivities = activities.slice(-10);
    
    const suspiciousFactors = {
      rapidActions: 0,
      repeatedPatterns: 0,
      deleteAfterCreate: 0,
      multipleEdits: 0
    };

    for (let i = 1; i < recentActivities.length; i++) {
      const timeDiff = recentActivities[i].timestamp - recentActivities[i-1].timestamp;
      
      if (timeDiff < 1000) {
        suspiciousFactors.rapidActions++;
      }

      if (recentActivities[i].action === 'create' && 
          recentActivities[i-1].action === 'delete') {
        suspiciousFactors.deleteAfterCreate++;
      }

      if (recentActivities[i].action === 'update' && 
          recentActivities[i-1].action === 'update' &&
          recentActivities[i].details.productId === recentActivities[i-1].details.productId) {
        suspiciousFactors.multipleEdits++;
      }
    }

    const isSuspicious = 
      suspiciousFactors.rapidActions > 5 ||
      suspiciousFactors.deleteAfterCreate > 3 ||
      suspiciousFactors.multipleEdits > 4;

    this.suspiciousPatterns.set(userId, {
      isSuspicious,
      factors: suspiciousFactors,
      lastUpdate: Date.now()
    });

    return isSuspicious;
  }

  canPerformAction(userId, action, details) {
    this.logActivity(userId, action, details);
    
    const pattern = this.suspiciousPatterns.get(userId);
    if (!pattern) return true;

    if (pattern.isSuspicious) {
      const cooldownPeriod = 5 * 60 * 1000;
      if (Date.now() - pattern.lastUpdate < cooldownPeriod) {
        return false;
      }
      this.suspiciousPatterns.delete(userId);
    }

    return true;
  }

  getActivityStats(userId) {
    const activities = this.activityLog.get(userId) || [];
    const pattern = this.suspiciousPatterns.get(userId);

    return {
      totalActivities: activities.length,
      recentActivities: activities.slice(-10),
      isSuspicious: pattern?.isSuspicious || false,
      factors: pattern?.factors || null,
      recommendedCooldown: pattern?.isSuspicious ? 
        Math.max(0, 5 * 60 * 1000 - (Date.now() - pattern.lastUpdate)) : 0
    };
  }

  destroy() {
    clearInterval(this.cleanupInterval);
    this.activityLog.clear();
    this.suspiciousPatterns.clear();
  }
}

class Cache {
  constructor() {
    this.cache = new Map();
    this.maxAge = 30 * 60 * 1000;
    this.cleanupInterval = setInterval(() => this.cleanup(), 60 * 1000);
  }

  cleanup() {
    const now = Date.now();
    for (const [key, entry] of this.cache.entries()) {
      if (now - entry.timestamp > this.maxAge) {
        this.cache.delete(key);
      }
    }
  }

  set(key, value) {
    this.cache.set(key, {
      value,
      timestamp: Date.now()
    });
  }

  get(key) {
    const entry = this.cache.get(key);
    if (!entry) return null;
    
    if (Date.now() - entry.timestamp > this.maxAge) {
      this.cache.delete(key);
      return null;
    }
    
    return entry.value;
  }

  clear() {
    this.cache.clear();
  }

  destroy() {
    clearInterval(this.cleanupInterval);
    this.clear();
  }
}

const smartLimiter = new SmartRateLimiter();
const cache = new Cache();

const formatCooldownTime = (milliseconds) => {
  const minutes = Math.ceil(milliseconds / 60000);
  if (minutes < 1) return 'less than a minute';
  if (minutes === 1) return '1 minute';
  return `${minutes} minutes`;
};

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000
});

api.interceptors.request.use(async config => {
  const userId = localStorage.getItem('userId') || 'anonymous';
  const action = config.method === 'post' ? 'create' :
                 config.method === 'put' ? 'update' :
                 config.method === 'delete' ? 'delete' : 'read';

  const canProceed = smartLimiter.canPerformAction(userId, action, {
    url: config.url,
    method: config.method,
    productId: config.url.split('/').pop()
  });

  if (!canProceed) {
    const stats = smartLimiter.getActivityStats(userId);
    throw new CooldownError(
      `We know ideas flow fast. Take a brief pause to keep things running smoothly. You'll be back in ${formatCooldownTime(stats.recommendedCooldown)}.`
    );
  }

  return config;
});

api.interceptors.response.use(
  response => {
    if (response.status === 200) {
      cache.set(response.config.url, response.data);
    }
    return response;
  },
  async error => {
    if (error.response?.status === 429) {
      const cachedData = cache.get(error.config.url);
      if (cachedData) {
        return {
          data: cachedData,
          fromCache: true
        };
      }
    }

    if (error.code === 'ECONNABORTED') {
      const cachedData = cache.get(error.config.url);
      if (cachedData) {
        return {
          data: cachedData,
          fromCache: true
        };
      }
    }

    throw error;
  }
);

class ProductService {
  static normalizeProduct(product) {
    if (!product) {
      throw new Error('Los datos del producto son requeridos para la normalización');
    }

    return {
      id: product.productId || product.id,
      name: product.name || '',
      description: product.description || '',
      category: product.category || '',
      price: typeof product.price === 'number' ? product.price : 0,
      customFields: product.customFields || {},
      status: product.status || 'active',
      createdAt: product.createdAt || new Date().toISOString(),
      updatedAt: product.updatedAt || new Date().toISOString()
    };
  }

  static async getAllProducts(params = {}) {
    const cacheKey = `products:${JSON.stringify(params)}`;
    let cachedData = cache.get(cacheKey);
    
    if (cachedData) {
      return { ...cachedData, fromCache: true };
    }

    try {
      const response = await api.get('/', { params });

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Respuesta inválida del servidor');
      }

      if (response.data.fromCache) {
        return response.data;
      }

      const result = {
        success: true,
        data: {
          products: Array.isArray(response.data.data) 
            ? response.data.data.map(this.normalizeProduct)
            : [],
          lastEvaluatedKey: response.data.lastEvaluatedKey
        }
      };

      cache.set(cacheKey, result);
      return result;
    } catch (error) {
      const cachedResult = cache.get(cacheKey);
      if (cachedResult) {
        return { ...cachedResult, fromCache: true };
      }
      throw new Error(`Error al obtener productos: ${error.message}`);
    }
  }

  static async getProductById(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido');
    }

    const cacheKey = `product:${productId}`;
    let cachedData = cache.get(cacheKey);
    
    if (cachedData) {
      return { ...cachedData, fromCache: true };
    }

    try {
      const response = await api.get(`/${productId}`);

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Producto no encontrado');
      }

      if (response.data.fromCache) {
        return response.data;
      }

      const result = {
        success: true,
        data: this.normalizeProduct(response.data.data)
      };

      cache.set(cacheKey, result);
      return result;
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('Producto no encontrado');
      }
      const cachedResult = cache.get(cacheKey);
      if (cachedResult) {
        return { ...cachedResult, fromCache: true };
      }
      throw new Error(`Error al obtener el producto: ${error.message}`);
    }
  }

  static async createProduct(productData) {
    if (!productData) {
      throw new Error('Los datos del producto son requeridos');
    }

    try {
      const formattedData = this.formatProductData(productData);
      
      const response = await api.post('/', formattedData);

      if (!response?.data?.success || !response?.data?.data) {
        throw new Error('Respuesta inválida del servidor');
      }

      cache.clear();
      
      return {
        success: true,
        data: this.normalizeProduct(response.data.data),
        message: 'Producto creado exitosamente'
      };
    } catch (error) {
      throw new Error(`Error al crear el producto: ${error.message}`);
    }
  }

  static async updateProduct(productId, updateData) {
    if (!productId) {
      throw new Error('El ID del producto es requerido');
    }

    if (!updateData) {
      throw new Error('Los datos de actualización son requeridos');
    }

    try {
      const formattedData = this.formatProductData(updateData);

      const response = await api.put(`/${productId}`, formattedData);

      if (!response?.data?.success || !response?.data?.data) {
        throw new Error('Respuesta inválida del servidor');
      }

      cache.clear();

      return {
        success: true,
        data: this.normalizeProduct(response.data.data),
        message: 'Producto actualizado exitosamente'
      };
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('El producto a actualizar no existe');
      }
      throw new Error(`Error al actualizar el producto: ${error.message}`);
    }
  }

  static async deleteProduct(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido');
    }

    try {
      const response = await api.delete(`/${productId}`);
      
      if (!response?.data?.success && response.status !== 404) {
        throw new Error('Error al eliminar el producto');
      }

      cache.clear();
      
      return {
        success: true,
        data: { productId },
        message: 'Producto eliminado exitosamente'
      };
    } catch (error) {
      if (error.response?.status === 404) {
        cache.clear();
        return {
          success: true,
          data: { productId },
          message: 'Producto eliminado exitosamente'
        };
      }
      throw new Error(`Error al eliminar el producto: ${error.message}`);
    }
  }

  static validateProduct(productData) {
    const errors = [];

    if (!productData?.name?.trim()) {
      errors.push('El nombre del producto es requerido');
    }

    if (!productData?.category?.trim()) {
      errors.push('La categoría del producto es requerida');
    }

    if (typeof productData?.price !== 'number' || productData.price < 0) {
      errors.push('El precio del producto debe ser un número positivo');
    }

    if (productData?.description && typeof productData.description !== 'string') {
      errors.push('La descripción debe ser texto');
    }

    if (productData?.customFields && typeof productData.customFields !== 'object') {
      errors.push('Los campos personalizados deben ser un objeto');
    }

    if (productData?.status && !['active', 'inactive', 'draft'].includes(productData.status)) {
      errors.push('El estado del producto debe ser: active, inactive o draft');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  static formatProductData(productData) {
    if (!productData) {
      throw new Error('Los datos del producto son requeridos para el formateo');
    }

    const formattedData = {
      name: productData.name?.trim() || '',
      description: productData.description?.trim() || '',
      category: productData.category?.trim() || '',
      price: typeof productData.price === 'number' ? productData.price : 
             typeof productData.price === 'string' ? parseFloat(productData.price) || 0 : 0,
      customFields: productData.customFields || {},
      status: productData.status || 'active'
    };

    const validation = this.validateProduct(formattedData);
    if (!validation.isValid) {
      throw new Error(validation.errors.join(', '));
    }

    return formattedData;
  }

  static async searchProducts(searchParams) {
    if (!searchParams?.query?.trim()) {
      throw new Error('El término de búsqueda es requerido');
    }

    const cacheKey = `search:${JSON.stringify(searchParams)}`;
    let cachedData = cache.get(cacheKey);
    
    if (cachedData) {
      return { ...cachedData, fromCache: true };
    }

    try {
      const response = await api.get('/search', { 
        params: {
          query: searchParams.query.trim(),
          limit: searchParams.limit || 50
        }
      });

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Respuesta de búsqueda inválida');
      }

      if (response.data.fromCache) {
        return response.data;
      }

      const result = {
        success: true,
        data: {
          products: Array.isArray(response.data.data) 
            ? response.data.data.map(this.normalizeProduct)
            : [],
          total: response.data.total || 0
        }
      };

      cache.set(cacheKey, result);
      return result;
    } catch (error) {
      const cachedResult = cache.get(cacheKey);
      if (cachedResult) {
        return { ...cachedResult, fromCache: true };
      }
      throw new Error(`Error al buscar productos: ${error.message}`);
    }
  }

  static async getProductsByCategory(category) {
    if (!category?.trim()) {
      throw new Error('La categoría es requerida');
    }

    const cacheKey = `category:${category.trim()}`;
    let cachedData = cache.get(cacheKey);
    
    if (cachedData) {
      return { ...cachedData, fromCache: true };
    }

    try {
      const response = await api.get('/', { 
        params: { 
          category: category.trim(),
          limit: 50
        }
      });

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Respuesta inválida para la categoría');
      }

      if (response.data.fromCache) {
        return response.data;
      }

      const result = {
        success: true,
        data: {
          products: Array.isArray(response.data.data) 
            ? response.data.data.map(this.normalizeProduct)
            : [],
          total: response.data.total || 0
        }
      };

      cache.set(cacheKey, result);
      return result;
    } catch (error) {
      const cachedResult = cache.get(cacheKey);
      if (cachedResult) {
        return { ...cachedResult, fromCache: true };
      }
      throw new Error(`Error al obtener productos por categoría: ${error.message}`);
    }
  }

  static async healthCheck() {
    try {
      const response = await api.get('/health');
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      throw new Error(`Error en health check: ${error.message}`);
    }
  }

  static getUsageStats() {
    const userId = localStorage.getItem('userId') || 'anonymous';
    return smartLimiter.getActivityStats(userId);
  }

  static clearCache() {
    cache.clear();
  }

  static clearRestrictions() {
    const userId = localStorage.getItem('userId') || 'anonymous';
    smartLimiter.suspiciousPatterns.delete(userId);
  }

  static resetAll() {
    this.clearCache();
    this.clearRestrictions();
  }
}

window.addEventListener('beforeunload', () => {
  cache.destroy();
  smartLimiter.destroy();
});

export default ProductService;