import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Chip, LinearProgress, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
    TrendingUp, 
    Target,
    Award,
    BarChart2,
    Users,
    Rocket,
    Flag,
    CheckCircle,
    Clock,
    AlertCircle,
    PieChart,
    Zap,
    MessageSquare
  } from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const MilestoneCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const GrowthMetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  height: '100%',
}));

const AGTGrowth = () => {
  const [activeStage] = useState('Scale-Up');
  
  const growthStages = {
    'Pre-Seed': 0,
    'Seed': 1,
    'Early': 2,
    'Scale-Up': 3,
    'Expansion': 4
  };

  return (
    <Box className="space-y-6">
      {/* Growth Stage Overview */}
      <StyledPaper>
        <Box className="flex justify-between items-start mb-6">
          <Box>
            <Typography variant="h6" gutterBottom>Current Growth Stage</Typography>
            <Typography variant="h4" className="mb-2">{activeStage}</Typography>
            <Box className="flex gap-2">
              <Chip icon={<Rocket size={14} />} label="High Growth" color="success" />
              <Chip icon={<Target size={14} />} label="Series A Ready" color="primary" />
            </Box>
          </Box>
          <ActionButton>
            <BarChart2 size={16} />
            View Detailed Analysis
          </ActionButton>
        </Box>

        <Box className="mt-6">
          <Box className="flex justify-between mb-2">
            {Object.keys(growthStages).map((stage) => (
              <Typography
                key={stage}
                variant="caption"
                color={stage === activeStage ? 'primary' : 'text.secondary'}
                sx={{ fontWeight: stage === activeStage ? 600 : 400 }}
              >
                {stage}
              </Typography>
            ))}
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={((growthStages[activeStage] + 1) / Object.keys(growthStages).length) * 100} 
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Box>
      </StyledPaper>

      {/* Key Metrics */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Growth Metrics</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <GrowthMetricCard>
                  <Box className="flex items-center gap-2 mb-3">
                    <TrendingUp size={20} className="text-green-500" />
                    <Typography variant="subtitle1">Monthly Revenue Growth</Typography>
                  </Box>
                  <Typography variant="h4" className="mb-2" color="success.main">+32%</Typography>
                  <Typography variant="caption" color="text.secondary">
                    15% higher than industry average
                  </Typography>
                </GrowthMetricCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GrowthMetricCard>
                  <Box className="flex items-center gap-2 mb-3">
                    <Users size={20} className="text-blue-500" />
                    <Typography variant="subtitle1">Customer Acquisition</Typography>
                  </Box>
                  <Typography variant="h4" className="mb-2">+126</Typography>
                  <Typography variant="caption" color="text.secondary">
                    New customers this month
                  </Typography>
                </GrowthMetricCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GrowthMetricCard>
                  <Box className="flex items-center gap-2 mb-3">
                    <Award size={20} className="text-purple-500" />
                    <Typography variant="subtitle1">Market Position</Typography>
                  </Box>
                  <Typography variant="h4" className="mb-2">Top 10%</Typography>
                  <Typography variant="caption" color="text.secondary">
                    In your industry segment
                  </Typography>
                </GrowthMetricCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GrowthMetricCard>
                  <Box className="flex items-center gap-2 mb-3">
                    <PieChart size={20} className="text-orange-500" />
                    <Typography variant="subtitle1">Resource Efficiency</Typography>
                  </Box>
                  <Typography variant="h4" className="mb-2">94%</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Operational efficiency score
                  </Typography>
                </GrowthMetricCard>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Next Milestones</Typography>
            
            <MilestoneCard>
              <Box className="flex justify-between items-center mb-2">
                <Box className="flex items-center gap-2">
                  <Flag size={16} className="text-blue-500" />
                  <Typography variant="subtitle2">Series A Funding</Typography>
                </Box>
                <Chip label="Priority" color="error" size="small" />
              </Box>
              <Box className="space-y-2">
                <Box className="flex justify-between">
                  <Typography variant="caption" color="text.secondary">Progress</Typography>
                  <Typography variant="caption" color="text.secondary">75%</Typography>
                </Box>
                <LinearProgress variant="determinate" value={75} />
              </Box>
            </MilestoneCard>

            <MilestoneCard>
              <Box className="flex justify-between items-center mb-2">
                <Box className="flex items-center gap-2">
                  <Users size={16} className="text-green-500" />
                  <Typography variant="subtitle2">Team Expansion</Typography>
                </Box>
                <Chip label="In Progress" color="primary" size="small" />
              </Box>
              <Box className="space-y-2">
                <Box className="flex justify-between">
                  <Typography variant="caption" color="text.secondary">Progress</Typography>
                  <Typography variant="caption" color="text.secondary">40%</Typography>
                </Box>
                <LinearProgress variant="determinate" value={40} />
              </Box>
            </MilestoneCard>

            <MilestoneCard>
              <Box className="flex justify-between items-center mb-2">
                <Box className="flex items-center gap-2">
                  <Target size={16} className="text-purple-500" />
                  <Typography variant="subtitle2">Market Expansion</Typography>
                </Box>
                <Chip label="Planned" color="default" size="small" />
              </Box>
              <Box className="space-y-2">
                <Box className="flex justify-between">
                  <Typography variant="caption" color="text.secondary">Progress</Typography>
                  <Typography variant="caption" color="text.secondary">15%</Typography>
                </Box>
                <LinearProgress variant="determinate" value={15} />
              </Box>
            </MilestoneCard>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Growth Strategy */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Growth Strategy Recommendations</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box className="p-4 border rounded-lg">
                  <Box className="flex items-center gap-2 mb-3">
                    <Zap size={20} className="text-yellow-500" />
                    <Typography variant="subtitle1">High-Impact Opportunities</Typography>
                  </Box>
                  <Box className="space-y-3">
                    <Box className="flex items-center gap-3">
                      <CheckCircle size={16} className="text-green-500" />
                      <Typography variant="body2">
                        Expand current market presence through strategic partnerships
                      </Typography>
                    </Box>
                    <Box className="flex items-center gap-3">
                      <Clock size={16} className="text-blue-500" />
                      <Typography variant="body2">
                        Implement automated customer onboarding to scale efficiently
                      </Typography>
                    </Box>
                    <Box className="flex items-center gap-3">
                      <AlertCircle size={16} className="text-orange-500" />
                      <Typography variant="body2">
                        Develop new revenue streams through product diversification
                      </Typography>
                    </Box>
                  </Box>
                  <ActionButton className="mt-4">
                    <Target size={16} />
                    Create Action Plan
                  </ActionButton>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Growth Advisors</Typography>
            <Box className="space-y-3">
              {[
                { name: 'Sarah Johnson', role: 'Growth Strategy', avatar: 'SJ' },
                { name: 'Mike Chen', role: 'Market Expansion', avatar: 'MC' },
                { name: 'Lisa Thompson', role: 'Financial Planning', avatar: 'LT' }
              ].map((advisor) => (
                <Box key={advisor.name} className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                  <Avatar>{advisor.avatar}</Avatar>
                  <Box>
                    <Typography variant="subtitle2">{advisor.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {advisor.role}
                    </Typography>
                  </Box>
                  <ActionButton className="ml-auto py-1 px-2">
                    <MessageSquare size={14} />
                  </ActionButton>
                </Box>
              ))}
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AGTGrowth;