import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Avatar,
  Divider,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack
} from '@mui/material';
import { 
  Building2, 
  Mail, 
  Phone, 
  Globe, 
  Calendar,
  Users,
  Target,
  TrendingUp,
  Edit,
  Save,
  Image,
  Upload
} from 'lucide-react';
import profileService from '../services/AGTUps-product-services/profileService';

const AGTProfile = ({ user, onProfileUpdate }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [profileData, setProfileData] = useState({
    profileId: null,
    founderName: '',
    founderRole: '',
    founderEmail: '',
    founderPhone: '',
    founderLinkedIn: '',
    companyName: '',
    companyDescription: '',
    industry: '',
    foundedDate: '',
    companySize: 'small',
    website: '',
    location: '',
    businessModel: '',
    targetMarket: '',
    revenue: '',
    stage: 'startup',
    goals: '',
    challenges: '',
    business: {
      model: '',
      stage: 'startup',
      targetMarket: '',
      goals: [],
      challenges: []
    }
  });

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (user) {
      profileService.initialize({ uid: user.uid });
      loadProfile();
    }
  }, [user]);

  const loadProfile = async () => {
    try {
      setIsLoading(true);
      const response = await profileService.getProfile();
      
      console.log('[AGTProfile] Load profile response:', response);

      if (response && response.success) {
        if (!response.exists || response.isNewProfile) {
          console.log('[AGTProfile] No existing profile found - showing creation form');
          setIsNewProfile(true);
          setProfileData(prev => ({
            ...prev,
            founderEmail: user?.email || ''
          }));
        } else if (response.data?.profile) {
          console.log('[AGTProfile] Existing profile found - loading data');
          const data = response.data.profile;
          
          const transformedData = {
            profileId: data.profileId || null,
            founderName: data['founder.name'] || '',
            founderRole: data['founder.role'] || '',
            founderEmail: data.contactEmail || user?.email || '',
            founderPhone: data['founder.phone'] || '',
            founderLinkedIn: data['founder.linkedin'] || '',
            companyName: data.companyName || '',
            companyDescription: data.description || '',
            industry: data.industry || '',
            foundedDate: data.foundingDate || '',
            companySize: data.size || 'small',
            website: data.website || '',
            location: data.location || '',
            businessModel: data['business.model'] || '',
            targetMarket: data['business.targetMarket'] || '',
            revenue: data['business.revenue'] || '',
            stage: data['business.stage'] || 'startup',
            goals: data['business.goals'] || [],
            challenges: data['business.challenges'] || [],
            business: {
              model: data['business.model'] || '',
              targetMarket: data['business.targetMarket'] || '',
              revenue: data['business.revenue'] || '',
              stage: data['business.stage'] || 'startup',
              goals: data['business.goals'] || [],
              challenges: data['business.challenges'] || []
            }
          };

          setProfileData(transformedData);
          setProfileImage(data.profileImage);
          setIsNewProfile(false);
          onProfileUpdate?.(transformedData);
        }
      } else {
        throw new Error(response.error?.message || 'Error cargando perfil');
      }
    } catch (error) {
      console.error('[AGTProfile] Error loading profile:', error);
      setAlert({
        show: true,
        message: error.message,
        severity: 'error'
      });
      setIsNewProfile(true);
    } finally {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setProfileData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setProfileData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      
      const profileToUpdate = {
        companyName: profileData.companyName,
        industry: profileData.industry,
        contactEmail: profileData.founderEmail,
        description: profileData.companyDescription,
        foundingDate: profileData.foundedDate,
        size: profileData.companySize,
        location: profileData.location,
        website: profileData.website,
        profileImage,
        founder: {
          name: profileData.founderName,
          role: profileData.founderRole,
          phone: profileData.founderPhone,
          linkedin: profileData.founderLinkedIn
        },
        business: {
          model: profileData.businessModel,
          targetMarket: profileData.targetMarket,
          revenue: profileData.revenue,
          stage: profileData.stage,
          goals: profileData.goals,
          challenges: profileData.challenges
        }
      };

      if (!profileToUpdate.companyName || !profileToUpdate.industry || !profileToUpdate.contactEmail) {
        throw new Error('Por favor complete los campos requeridos: Nombre de la empresa, Industria y Email de contacto');
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(profileToUpdate.contactEmail)) {
        throw new Error('Por favor ingrese un email válido');
      }

      let result;
      if (isNewProfile) {
        console.log('[AGTProfile] Creating new profile...');
        result = await profileService.createProfile(profileToUpdate);
      } else {
        console.log('[AGTProfile] Updating existing profile...');
        result = await profileService.updateProfile(profileData.profileId, profileToUpdate);
      }

      if (result.success) {
        const responseData = result.data.data;
        
        const transformedData = {
          profileId: responseData.profileId,
          founderName: responseData['founder.name'] || '',
          founderRole: responseData['founder.role'] || '',
          founderEmail: responseData.contactEmail || '',
          founderPhone: responseData['founder.phone'] || '',
          founderLinkedIn: responseData['founder.linkedin'] || '',
          companyName: responseData.companyName || '',
          companyDescription: responseData.description || '',
          industry: responseData.industry || '',
          foundedDate: responseData.foundingDate || '',
          companySize: responseData.size || 'small',
          website: responseData.website || '',
          location: responseData.location || '',
          businessModel: responseData['business.model'] || '',
          targetMarket: responseData['business.targetMarket'] || '',
          revenue: responseData['business.revenue'] || '',
          stage: responseData['business.stage'] || 'startup',
          goals: responseData['business.goals'] || [],
          challenges: responseData['business.challenges'] || [],
          business: {
            model: responseData['business.model'] || '',
            targetMarket: responseData['business.targetMarket'] || '',
            revenue: responseData['business.revenue'] || '',
            stage: responseData['business.stage'] || 'startup',
            goals: responseData['business.goals'] || [],
            challenges: responseData['business.challenges'] || []
          }
        };

        setProfileData(transformedData);
        
        if (isNewProfile) {
          await loadProfile();
        }
        
        setIsEditing(false);
        onProfileUpdate?.(transformedData);
        
        setAlert({
          show: true,
          message: 'Perfil actualizado exitosamente',
          severity: 'success'
        });
      } else {
        throw new Error(result.error?.message || 'Error guardando perfil');
      }
    } catch (error) {
      console.error('[AGTProfile] Error saving profile:', error);
      setAlert({
        show: true,
        message: error.message,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderFounderInfo = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Paper className="p-4 text-center">
          <Box className="relative inline-block">
            <Avatar
              src={profileImage || profileData.profileImage}
              sx={{ width: 120, height: 120, margin: '0 auto' }}
            />
            {isEditing && (
              <label htmlFor="profile-image">
                <input
                  type="file"
                  id="profile-image"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
                <IconButton 
                  component="span"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'white'
                  }}
                >
                  <Upload size={20} />
                </IconButton>
              </label>
            )}
          </Box>
          <Typography variant="h6" className="mt-4">
            {profileData.founderName || 'Tu Nombre'}
          </Typography>
          <Typography color="textSecondary">
            {profileData.founderRole || 'Tu Rol'}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Información Personal</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nombre Completo"
                  name="founderName"
                  value={profileData.founderName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Rol"
                  name="founderRole"
                  value={profileData.founderRole}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="founderEmail"
                  type="email"
                  value={profileData.founderEmail}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                  error={isEditing && (!profileData.founderEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.founderEmail))}
                  helperText={isEditing && (!profileData.founderEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.founderEmail)) ? 'Email válido requerido' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Teléfono"
                  name="founderPhone"
                  value={profileData.founderPhone}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="LinkedIn"
                  name="founderLinkedIn"
                  value={profileData.founderLinkedIn}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const renderCompanyInfo = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Información de la Empresa</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nombre de la Empresa"
                  name="companyName"
                  value={profileData.companyName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                  error={isEditing && !profileData.companyName}
                  helperText={isEditing && !profileData.companyName ? 'Nombre de empresa requerido' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Descripción de la Empresa"
                  name="companyDescription"
                  value={profileData.companyDescription}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Industria</InputLabel>
                  <Select
                    name="industry"
                    value={profileData.industry}
                    onChange={handleInputChange}
                    label="Industria"
                    disabled={!isEditing}
                  >
                    <MenuItem value="technology">Tecnología</MenuItem>
                    <MenuItem value="retail">Retail</MenuItem>
                    <MenuItem value="manufacturing">Manufactura</MenuItem>
                    <MenuItem value="services">Servicios</MenuItem>
                    <MenuItem value="other">Otra</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Fecha de Fundación"
                  name="foundedDate"
                  type="date"
                  value={profileData.foundedDate}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Tamaño de la Empresa</InputLabel>
                  <Select
                    name="companySize"
                    value={profileData.companySize}
                    onChange={handleInputChange}
                    label="Tamaño de la Empresa"
                    disabled={!isEditing}
                  >
                    <MenuItem value="small">Pequeña</MenuItem>
                    <MenuItem value="medium">Mediana</MenuItem>
                    <MenuItem value="large">Grande</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Sitio Web"
                  name="website"
                  value={profileData.website}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Ubicación"
                  name="location"
                  value={profileData.location}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const renderBusinessInfo = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Información de Negocio</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Modelo de Negocio"
                  name="businessModel"
                  multiline
                  rows={3}
                  value={profileData.businessModel}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mercado Objetivo"
                  name="business.targetMarket"
                  multiline
                  rows={2}
                  value={profileData.business.targetMarket}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ingresos Anuales"
                  name="revenue"
                  value={profileData.revenue}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Etapa de la Empresa</InputLabel>
                  <Select
                    name="business.stage"
                    value={profileData.business.stage}
                    onChange={handleInputChange}
                    label="Etapa de la Empresa"
                    disabled={!isEditing}
                  >
                    <MenuItem value="startup">Startup</MenuItem>
                    <MenuItem value="growth">Crecimiento</MenuItem>
                    <MenuItem value="established">Establecido</MenuItem>
                    <MenuItem value="expanding">En expansión</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Objetivos a Corto/Mediano Plazo"
                  name="goals"
                  multiline
                  rows={3}
                  value={profileData.goals}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Desafíos Principales"
                  name="challenges"
                  multiline
                  rows={3}
                  value={profileData.challenges}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  if (!initialLoadComplete && isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (initialLoadComplete && isNewProfile) {
    console.log('[AGTProfile] Rendering new profile form');
    return (
      <Box p={3}>
        <Paper elevation={3} sx={{ p: 4, maxWidth: 800, mx: 'auto' }}>
          <Typography variant="h5" gutterBottom>
            Configura tu Perfil
          </Typography>

          {alert.show && (
            <Alert 
              severity={alert.severity} 
              sx={{ mb: 3 }}
              onClose={() => setAlert({ show: false, message: '', severity: 'success' })}
            >
              {alert.message}
            </Alert>
          )}

          <form onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}>
            <Stack spacing={3}>
              <TextField
                required
                fullWidth
                label="Nombre de la Empresa"
                name="companyName"
                value={profileData.companyName}
                onChange={handleInputChange}
              />

              <FormControl fullWidth required>
                <InputLabel>Industria</InputLabel>
                <Select
                  name="industry"
                  value={profileData.industry}
                  onChange={handleInputChange}
                  label="Industria"
                >
                  <MenuItem value="technology">Tecnología</MenuItem>
                  <MenuItem value="retail">Retail</MenuItem>
                  <MenuItem value="manufacturing">Manufactura</MenuItem>
                  <MenuItem value="services">Servicios</MenuItem>
                  <MenuItem value="other">Otra</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth required>
                <InputLabel>Etapa del Negocio</InputLabel>
                <Select
                  name="business.stage"
                  value={profileData.business.stage}
                  onChange={handleInputChange}
                  label="Etapa del Negocio"
                >
                  <MenuItem value="startup">Startup</MenuItem>
                  <MenuItem value="growth">Crecimiento</MenuItem>
                  <MenuItem value="established">Establecido</MenuItem>
                  <MenuItem value="expanding">En expansión</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Mercado Objetivo"
                name="business.targetMarket"
                value={profileData.business.targetMarket}
                onChange={handleInputChange}
                multiline
                rows={2}
              />

              <TextField
                fullWidth
                label="Descripción del Negocio"
                name="companyDescription"
                value={profileData.companyDescription}
                onChange={handleInputChange}
                multiline
                rows={3}
              />

              <Button 
                type="submit" 
                variant="contained" 
                size="large"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Crear Perfil'}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Box>
    );
  }

  return (
    <Box className="p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h5">Perfil Empresarial</Typography>
        <Button
          variant={isEditing ? "contained" : "outlined"}
          startIcon={isEditing ? <Save /> : <Edit />}
          onClick={isEditing ? handleSave : () => setIsEditing(true)}
        >
          {isEditing ? "Guardar Cambios" : "Editar Perfil"}
        </Button>
      </Box>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        className="mb-4"
      >
        <Tab 
          icon={<Users className="h-4 w-4 mr-2" />}
          label="Emprendedor" 
          iconPosition="start"
        />
        <Tab 
          icon={<Building2 className="h-4 w-4 mr-2" />}
          label="Empresa" 
          iconPosition="start"
        />
        <Tab 
          icon={<TrendingUp className="h-4 w-4 mr-2" />}
          label="Negocio" 
          iconPosition="start"
        />
      </Tabs>

      {alert.show && (
        <Alert 
          severity={alert.severity} 
          className="mb-4"
          onClose={() => setAlert({ show: false, message: '', severity: 'success' })}
        >
          {alert.message}
        </Alert>
      )}

      <Box className="mt-4">
        {activeTab === 0 && renderFounderInfo()}
        {activeTab === 1 && renderCompanyInfo()}
        {activeTab === 2 && renderBusinessInfo()}
      </Box>

      {isEditing && (
        <Box className="fixed bottom-4 right-4">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Save />}
            onClick={handleSave}
          >
            Guardar Cambios
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AGTProfile;