import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { format, isToday, isYesterday, isThisWeek, isThisMonth, isThisYear, parseISO, compareDesc } from 'date-fns';
import { List, ListItemButton, ListSubheader, Typography, Box, IconButton, CircularProgress, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { Archive, MessageSquare, Stars } from 'lucide-react';
import { getThemeStyles } from '../../themes/themeConfig';

const STORAGE_KEY = 'chatList_state';
const CHATS_PER_PAGE = 10;

const StyledBox = styled(Box)(({ theme }) => ({
  flex: 1,                    
  overflowY: 'auto',
  display: 'flex',           
  flexDirection: 'column',   
  minHeight: 0,             
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const StyledList = styled(List)({
  padding: 0,
  width: '100%',
});

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  bgcolor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(8px)',
  position: 'sticky',
  top: 0,
  zIndex: 2,                
  padding: '8px 16px',
  fontWeight: 600,
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
  flexGrow: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const LoadingIndicator = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  width: '100%',
});

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: '16px',
  marginTop: '8px',
  marginLeft: '12px',
  marginRight: '12px',
  marginBottom: '16px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.8)',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
}));

const getChatCategory = (date) => {
  if (!date) {
    return 'No Date';
  }

  try {
    const parsedDate = parseISO(date);
    return isToday(parsedDate) ? 'Today' :
      isYesterday(parsedDate) ? 'Yesterday' :
      isThisWeek(parsedDate) ? 'This Week' :
      isThisMonth(parsedDate) ? 'This Month' :
      isThisYear(parsedDate) ? format(parsedDate, 'MMMM') :
      format(parsedDate, 'yyyy');
  } catch (error) {
    return 'Invalid Date';
  }
};

const renderFooter = (chatsLength, totalChatsCount, hasNextPage, isNextPageLoading, onLoadMore) => {
  if (chatsLength === 0) {
    return (
      <FooterContainer>
        <Box className="flex items-center gap-3 mb-2">
          <MessageSquare className="w-5 h-5 text-blue-500" />
          <Typography className="font-medium text-gray-900 dark:text-gray-100">
            Start Chatting
          </Typography>
        </Box>
        
        <Typography className="text-sm text-gray-600 dark:text-gray-400">
          Begin your first conversation! Your chat history will appear here, making it easy to continue discussions and track your interactions.
        </Typography>
      </FooterContainer>
    );
  }

  if (chatsLength <= 5) {
    return (
      <FooterContainer>
        <Box className="flex items-center gap-3 mb-2">
          <Stars className="w-5 h-5 text-yellow-500" />
          <Typography className="font-medium text-gray-900 dark:text-gray-100">
            Getting Started
          </Typography>
        </Box>
        
        <Typography className="text-sm text-gray-600 dark:text-gray-400">
          You're off to a great start! Keep chatting to build up your conversation history.
        </Typography>
      </FooterContainer>
    );
  }

  return (
    <FooterContainer>
      <Box className="flex items-center gap-3 mb-2">
        <Archive className="w-5 h-5 text-gray-500" />
        <Typography className="font-medium text-gray-900 dark:text-gray-100">
          Older Chats
        </Typography>
      </Box>
      
      <Typography className="text-sm text-gray-600 dark:text-gray-400 mb-3">
        {`Showing ${chatsLength} recent conversations${totalChatsCount ? ` out of ${totalChatsCount} total` : ''}.
        Older chats are preserved but not immediately visible to optimize performance.`}
      </Typography>
      
      {hasNextPage && (
        <Button 
          variant="text" 
          size="small"
          className="text-blue-600 hover:text-blue-700 text-sm font-medium"
          onClick={() => {
            if (!isNextPageLoading) {
              onLoadMore();
            }
          }}
          disabled={isNextPageLoading}
        >
          {isNextPageLoading ? 'Loading...' : 'Load more chats'}
        </Button>
      )}
    </FooterContainer>
  );
};

const CategorizedChatList = ({ 
  chats, 
  currentChatId, 
  onSelectChat, 
  onMenuOpen,
  hasNextPage,
  isNextPageLoading,
  onLoadMore,
  onSidebarClose,
  isMobile,
  totalChatsCount
}) => {
  const [persistentState, setPersistentState] = useState(() => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY);
      return saved ? JSON.parse(saved) : {
        scrollPosition: 0,
        displayLimit: CHATS_PER_PAGE,
        loadedChats: [],
        lastVisibleCategory: null
      };
    } catch (error) {
      return {
        scrollPosition: 0,
        displayLimit: CHATS_PER_PAGE,
        loadedChats: [],
        lastVisibleCategory: null
      };
    }
  });

  const containerRef = React.useRef(null);
  const scrollTimeoutRef = React.useRef(null);
  const resizeObserverRef = React.useRef(null);
  const categoriesRef = React.useRef(new Map());

  const saveState = useCallback(() => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(persistentState));
    } catch (error) {
      console.error('Error saving state:', error);
    }
  }, [persistentState]);

  useEffect(() => {
    saveState();
  }, [persistentState, saveState]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = persistentState.scrollPosition;
    }
  }, []);

  const updateScrollPosition = useCallback((scrollTop) => {
    setPersistentState(prev => ({
      ...prev,
      scrollPosition: scrollTop
    }));
  }, []);

  const handleIntersection = useCallback((entries) => {
    entries.forEach(entry => {
      const category = entry.target.getAttribute('data-category');
      if (entry.isIntersecting) {
        setPersistentState(prev => ({
          ...prev,
          lastVisibleCategory: category
        }));
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: containerRef.current,
      threshold: 1.0
    });

    categoriesRef.current.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, [handleIntersection]);

  const handleScroll = useCallback(() => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      if (!containerRef.current || isNextPageLoading || !hasNextPage) {
        return;
      }

      const container = containerRef.current;
      const { scrollTop, scrollHeight, clientHeight } = container;
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

      updateScrollPosition(scrollTop);

      if (scrollPercentage > 0.8) {
        onLoadMore();
      }
    }, 150);
  }, [isNextPageLoading, hasNextPage, onLoadMore, updateScrollPosition]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const container = containerRef.current;
        if (container) {
          container.scrollTop = persistentState.scrollPosition;
          if (persistentState.lastVisibleCategory) {
            const categoryElement = categoriesRef.current.get(persistentState.lastVisibleCategory);
            if (categoryElement) {
              categoryElement.scrollIntoView({ behavior: 'auto', block: 'start' });
            }
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [persistentState]);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop } = containerRef.current;
      updateScrollPosition(scrollTop);
    }
  }, [updateScrollPosition]);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(handleResize);
    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize]);

  const handleChatSelect = useCallback((chatId) => {
    onSelectChat(chatId);
    if (isMobile) {
      onSidebarClose();
    }
  }, [onSelectChat, isMobile, onSidebarClose]);

  const categorizedChats = useMemo(() => {
    const categories = {};
    chats.forEach(chat => {
      const category = getChatCategory(chat.updatedAt || chat.createdAt);
      if (!categories[category]) categories[category] = [];
      categories[category].push(chat);
    });

    Object.values(categories).forEach(categoryChats => {
      categoryChats.sort((a, b) => {
        const dateA = parseISO(a.updatedAt || a.createdAt);
        const dateB = parseISO(b.updatedAt || b.createdAt);
        return compareDesc(dateA, dateB);
      });
    });
    
    return categories;
  }, [chats]);

  const categoryOrder = [
    'Today',
    'Yesterday', 
    'This Week',
    'This Month',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return (
    <StyledBox 
      ref={containerRef}
      onScroll={handleScroll}
    >
      <StyledList>
        {Object.entries(categorizedChats)
          .sort(([a], [b]) => {
            const indexA = categoryOrder.indexOf(a);
            const indexB = categoryOrder.indexOf(b);
            if (indexA === -1 && indexB === -1) return a.localeCompare(b);
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
          })
          .map(([category, categoryChats]) => (
            <Box 
              key={category}
              ref={element => {
                if (element) {
                  categoriesRef.current.set(category, element);
                }
              }}
              data-category={category}
            >
              <StyledListSubheader>
                {category}
              </StyledListSubheader>
              {categoryChats.map((chat) => (
                <ListItemButton 
                  key={chat.id} 
                  onClick={() => handleChatSelect(chat.id)}
                  selected={currentChatId === chat.id}
                  sx={{
                    pl: 3,
                    pr: 1,
                    py: 1.5,
                    borderRadius: '12px',
                    mx: 1,
                    '&.Mui-selected': {
                      backgroundColor: 'action.selected',
                      '& .MuiTypography-root': {
                        color: '#0385FF',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      borderRadius: '12px',
                    },
                  }}
                >
                  <StyledTypography noWrap>
                    {chat.title || "New chat"}
                  </StyledTypography>
                  <StyledIconButton 
                    size="small" 
                    onClick={(event) => {
                      event.stopPropagation();
                      onMenuOpen(event, chat.id);
                    }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </StyledIconButton>
                </ListItemButton>
              ))}
            </Box>
          ))}
          
        {isNextPageLoading && (
          <LoadingIndicator>
            <CircularProgress size={24} />
          </LoadingIndicator>
        )}

        {renderFooter(chats.length, totalChatsCount, hasNextPage, isNextPageLoading, onLoadMore)}
      </StyledList>
    </StyledBox>
  );
};

export default CategorizedChatList;