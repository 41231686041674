import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Tooltip, 
  Alert, 
  useMediaQuery, 
  Button,
  Snackbar
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  BarChart3, 
  DollarSign, 
  TrendingUp, 
  Package,
  AlertCircle
} from 'lucide-react';

import MarketAnalysis from './AGTProduct-products/MarketAnalysis';
import PricingStrategy from './AGTProduct-products/PricingStrategy';
import SalesStrategy from './AGTProduct-products/SalesStrategy';
import ProductsManager from './AGTProduct-products/ProductsManager';
import AGTProductAI from './AGTProduct-products/AGTProductAI';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  backgroundColor: 'transparent',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'light' 
    ? 'rgba(0, 0, 0, 0.1)'
    : 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: '50px',
  margin: '0 auto',
  width: 'fit-content',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
    maxWidth: 'calc(100% - 32px)',
  },
}));

const SlidingBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 5,
  left: 0,
  height: 'calc(100% - 10px)',
  borderRadius: '50px',
  backgroundColor: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.1)'
    : 'rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease, width 0.3s ease',
}));

const NavButton = styled(Button)(({ theme, $active }) => ({
  color: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.87)'
    : 'rgba(255, 255, 255, 0.87)',
  margin: theme.spacing(0, 0.5),
  textTransform: 'capitalize',
  borderRadius: '50px',
  zIndex: 1,
  padding: theme.spacing(0.5, 2),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    opacity: 0.8,
  },
  '&:active': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&:disabled': {
    opacity: 0.5,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0.25),
    fontSize: '0.8rem',
  },
}));

const AGTProduct = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [showNewProductDialog, setShowNewProductDialog] = useState(false);
  const [error, setError] = useState(null);
  const [slideStyle, setSlideStyle] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const buttonRefs = useRef([]);
  const containerRef = useRef(null);
  const lastNavigationTime = useRef(Date.now());

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  const navigationTabs = [
    { id: 0, icon: Package, label: 'My Products', disabled: false },
    { id: 1, icon: BarChart3, label: 'Market Analysis', disabled: !selectedProduct },
    { id: 2, icon: DollarSign, label: 'Pricing Strategy', disabled: !selectedProduct },
    { id: 3, icon: TrendingUp, label: 'Sales Strategy', disabled: !selectedProduct }
  ];

  const showNotification = useCallback((message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setShowSnackbar(true);
  }, []);

  const updateSlideStyle = useCallback(() => {
    if (!buttonRefs.current[activeTab] || !containerRef.current) return;

    requestAnimationFrame(() => {
      const activeButton = buttonRefs.current[activeTab];
      const buttonRect = activeButton.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const offsetLeft = buttonRect.left - containerRect.left;

      setSlideStyle({
        width: `${buttonRect.width}px`,
        transform: `translateX(${offsetLeft}px)`,
      });
    });
  }, [activeTab]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateSlideStyle);
    };

    updateSlideStyle();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      setSelectedProduct(null);
      setProducts([]);
      setActiveTab(0);
      setError(null);
    };
  }, [updateSlideStyle]);

  const handleTabChange = useCallback((newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      if (newValue !== 0 && !selectedProduct) {
        showNotification('Please select a product first', 'warning');
        return;
      }
      lastNavigationTime.current = now;
      setActiveTab(newValue);
      setError(null);
    }
  }, [selectedProduct, showNotification]);

  const handleProductSelect = useCallback((product) => {
    try {
      if (!product) {
        setSelectedProduct(null);
        setActiveTab(0);
        return;
      }

      if (!product.id || !product.name) {
        throw new Error('Invalid product data');
      }

      setSelectedProduct(product);
      setError(null);
    } catch (error) {
      setError(error.message);
      setSelectedProduct(null);
      showNotification(error.message, 'error');
    }
  }, [showNotification]);

  const handleProductCreate = useCallback((newProduct) => {
    try {
      if (!newProduct || !newProduct.name) {
        throw new Error('Invalid product data for creation');
      }

      const productWithId = {
        ...newProduct,
        id: `prod_${Date.now()}`,
        createdAt: new Date().toISOString()
      };

      setProducts(prevProducts => [...prevProducts, productWithId]);
      setSelectedProduct(productWithId);
      showNotification(`Product "${productWithId.name}" created successfully`, 'success');
      
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [showNotification]);

  const handleProductUpdate = useCallback((updatedProduct) => {
    try {
      if (!updatedProduct || !updatedProduct.id || !updatedProduct.name) {
        throw new Error('Invalid product data for update');
      }

      setProducts(prevProducts => 
        prevProducts.map(p => p.id === updatedProduct.id ? updatedProduct : p)
      );
      setSelectedProduct(updatedProduct);
      showNotification(`Product "${updatedProduct.name}" updated successfully`, 'success');
      
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [showNotification]);

  const handleProductDelete = useCallback((productId) => {
    try {
      const productToDelete = products.find(p => p.id === productId);
      if (!productToDelete) {
        return;
      }

      setSelectedProduct(null);
      setActiveTab(0);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
      showNotification(`Product "${productToDelete.name}" deleted successfully`, 'success');
      
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [products, showNotification]);

  const renderContent = useCallback(() => {
    if (error) {
      return (
        <Alert 
          severity="warning"
          onClose={() => setError(null)}
          icon={<AlertCircle size={20} color="#FFFFFF" />}
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={() => setError(null)}
              sx={{ color: '#FFFFFF' }}
            >
              DISMISS
            </Button>
          }
          sx={{ 
            marginBottom: 2,
            backgroundColor: 'transparent',
            color: '#FFFFFF',
            border: 'none',
            '& .MuiAlert-icon': {
              color: '#FFFFFF',
            },
            '& .MuiAlert-message': {
              color: '#FFFFFF',
            }
          }}
        >
          We know ideas flow fast. Take a brief pause to keep things running smoothly. You&apos;ll be back in 5 minutes.
        </Alert>
      );
    }

    switch (activeTab) {
      case 0:
        return (
          <ProductsManager 
            products={products}
            selectedProduct={selectedProduct}
            onProductSelect={handleProductSelect}
            onProductCreate={handleProductCreate}
            onProductUpdate={handleProductUpdate}
            onProductDelete={handleProductDelete}
            showNewProductDialog={showNewProductDialog}
            onNewProductDialogClose={() => setShowNewProductDialog(false)}
          />
        );
      case 1:
        return selectedProduct ? <MarketAnalysis selectedProduct={selectedProduct} /> : null;
      case 2:
        return selectedProduct ? <PricingStrategy selectedProduct={selectedProduct} /> : null;
      case 3:
        return selectedProduct ? <SalesStrategy selectedProduct={selectedProduct} /> : null;
      default:
        return null;
    }
  }, [
    activeTab,
    products,
    selectedProduct,
    error,
    showNewProductDialog,
    handleProductSelect,
    handleProductCreate,
    handleProductUpdate,
    handleProductDelete
  ]);

  return (
    <Box className="space-y-4 relative" sx={{ minHeight: '100vh' }}>
      <NavigationContainer ref={containerRef}>
        <SlidingBackground style={slideStyle} />
        {navigationTabs.map((tab, index) => {
          const Icon = tab.icon;
          return (
            <NavButton
              key={tab.id}
              ref={el => buttonRefs.current[index] = el}
              onClick={() => handleTabChange(tab.id)}
              disabled={tab.disabled}
              disableRipple
              $active={activeTab === tab.id}
            >
              <Icon size={20} />
              <span>{tab.label}</span>
            </NavButton>
          );
        })}
      </NavigationContainer>

      <Box sx={{ pb: '100px' }}>
        {!selectedProduct && activeTab !== 0 ? (
          <StyledPaper>
            <Box className="text-center py-8">
              <Package size={48} className="mx-auto mb-4 text-gray-400" />
              <Typography variant="h6" gutterBottom>
                No Product Selected
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Please select a product from your product list to view its analysis
              </Typography>
              <ActionButton 
                className="mt-4 mx-auto"
                onClick={() => setActiveTab(0)}
              >
                Go to My Products
              </ActionButton>
            </Box>
          </StyledPaper>
        ) : (
          renderContent()
        )}
      </Box>

      <AGTProductAI 
        activeTab={activeTab}
        selectedProduct={selectedProduct}
        onProductSelect={handleProductSelect}
        onTabChange={handleTabChange}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowSnackbar(false)} 
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AGTProduct;