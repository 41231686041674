import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  TextField, 
  IconButton, 
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Alert,
  CircularProgress,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Package,
  MoreVertical,
  Edit,
  Trash2,
  Plus,
  DollarSign,
  ShoppingBag,
  Briefcase,
  Smartphone,
  Coffee,
  AlertCircle,
  Search,
  RefreshCw,
  AlertTriangle
} from 'lucide-react';

import ProductService from '../../services/AGTUps-product-services/productService';

const defaultCategories = [
  { id: 'physical', name: 'Physical Products', icon: Package, color: '#4f46e5' },
  { id: 'digital', name: 'Digital Products', icon: Smartphone, color: '#0891b2' },
  { id: 'services', name: 'Services', icon: Briefcase, color: '#059669' },
  { id: 'subscription', name: 'Subscriptions', icon: ShoppingBag, color: '#7c3aed' },
  { id: 'food', name: 'Food & Beverage', icon: Coffee, color: '#db2777' }
];

const StyledComponents = {
  Container: styled(Box)({
    backgroundColor: 'transparent',
    minHeight: '100vh',
    padding: '24px',
  }),

  SearchBar: styled(TextField)({
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      '& fieldset': { border: 'none' },
      '& input': {
        color: '#FFFFFF',
        '&::placeholder': {
          color: 'rgba(255, 255, 255, 0.7)',
          opacity: 1,
        },
      },
    },
  }),

  ProductCard: styled(Box)(({ bgcolor }) => ({
    position: 'relative',
    borderRadius: '16px',
    aspectRatio: '1',
    overflow: 'hidden',
    cursor: 'pointer',
    background: `linear-gradient(45deg, ${bgcolor}, ${bgcolor}88)`,
    padding: '16px',
    transition: 'transform 0.2s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  })),

  Dialog: styled(Dialog)({
    '& .MuiDialog-paper': {
      backgroundColor: '#262626',
      color: '#FFFFFF',
      borderRadius: '16px',
    },
  }),

  TextField: styled(TextField)({
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '12px',
      color: '#FFFFFF',
      '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
      '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.2)' },
      '&.Mui-focused fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  }),

  Select: styled(Select)({
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    color: '#FFFFFF',
    borderRadius: '12px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
  }),

  MenuItem: styled(MenuItem)({
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  }),

  ActionButton: styled(Button)({
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '30px',
    color: '#FFFFFF',
    textTransform: 'none',
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  })
};

const ProductsManager = ({ 
  products: initialProducts,
  selectedProduct: propSelectedProduct,
  onProductSelect,
  onProductCreate,
  onProductUpdate,
  onProductDelete,
  showNewProductDialog,
  onNewProductDialogClose 
}) => {
  const [products, setProducts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories] = useState(defaultCategories);
  const [loadingStates, setLoadingStates] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const mounted = useRef(true);

  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    customFields: {}
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      setProducts(initialProducts || []);
    }
  }, [initialProducts]);

  useEffect(() => {
    if (mounted.current && propSelectedProduct?.id) {
      setSelectedProduct(propSelectedProduct);
    }
  }, [propSelectedProduct]);

  useEffect(() => {
    if (mounted.current) {
      loadProducts();
    }
  }, [refreshKey]);

  const showNotification = useCallback((message, severity = 'success') => {
    if (mounted.current) {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setShowSnackbar(true);
    }
  }, []);

  const handleError = useCallback((error) => {
    console.error(error);
    if (mounted.current) {
      if (error.name === 'CooldownError') {
        setError({
          name: 'CooldownError',
          message: error.message
        });
      } else {
        setError({
          name: 'Error',
          message: error.message || 'An unexpected error occurred'
        });
        showNotification(error.message || 'An unexpected error occurred', 'error');
      }
    }
  }, [showNotification]);

  const loadProducts = useCallback(async () => {
    if (!mounted.current) return;

    try {
      setLoading(true);
      setError(null);
      const response = await ProductService.getAllProducts();
      
      if (!response?.data?.products) {
        throw new Error('Invalid product data received from server');
      }
      
      if (mounted.current) {
        setProducts(response.data.products);
      }
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setProducts([]);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [handleError]);

  const loadProductDetails = useCallback(async (productId) => {
    if (!mounted.current || !productId) return null;

    try {
      setLoadingProduct(true);
      setError(null);
      
      const response = await ProductService.getProductById(productId);
      
      if (!response?.data) {
        throw new Error('Product data not found');
      }

      if (mounted.current) {
        setSelectedProduct(response.data);
        if (typeof onProductSelect === 'function') {
          onProductSelect(response.data);
        }
      }
      
      return response.data;
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setSelectedProduct(null);
      }
      return null;
    } finally {
      if (mounted.current) {
        setLoadingProduct(false);
      }
    }
  }, [onProductSelect, handleError]);

  const handleProductClick = useCallback(async (product) => {
    if (!mounted.current || !product?.id || loadingProduct) return;

    try {
      await loadProductDetails(product.id);
      showNotification(`Selected product: ${product.name}`);
    } catch (error) {
      handleError(error);
    }
  }, [loadProductDetails, loadingProduct, showNotification, handleError]);

  const handleOpenMenu = useCallback((event, product) => {
    if (!mounted.current) return;

    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedProduct(product);
    setProductToDelete(product);
  }, []);

  const handleCloseMenu = useCallback(() => {
    if (mounted.current) {
      setAnchorEl(null);
    }
  }, []);

  const handleDeleteClick = useCallback((product) => {
    if (!mounted.current) return;

    if (!product?.id) {
      showNotification('Invalid product selected for deletion', 'error');
      return;
    }
    
    const productExists = products.some(p => p.id === product.id);
    if (!productExists) {
      showNotification('Product no longer exists', 'info');
      handleCloseMenu();
      return;
    }
    
    setProductToDelete(product);
    setDeleteConfirmOpen(true);
    handleCloseMenu();
  }, [handleCloseMenu, showNotification, products]);

  const handleOpenDialog = useCallback(async (product = null) => {
    if (!mounted.current) return;

    try {
      setError(null);
      
      if (product) {
        if (!product.id) {
          throw new Error('Invalid product ID for editing');
        }
        
        const productDetails = await loadProductDetails(product.id);
        if (productDetails && mounted.current) {
          setNewProduct(productDetails);
          setSelectedProduct(productDetails);
        }
      } else {
        if (mounted.current) {
          setSelectedProduct(null);
          setNewProduct({
            name: '',
            description: '',
            category: '',
            price: '',
            customFields: {}
          });
        }
      }
      
      if (mounted.current) {
        setOpenDialog(true);
      }
    } catch (error) {
      handleError(error);
    }
  }, [loadProductDetails, handleError]);

  const handleAddProduct = useCallback(async () => {
    if (!mounted.current) return;

    try {
      setLoading(true);
      setError(null);

      const formattedProduct = ProductService.formatProductData(newProduct);
      
      let response;
      if (selectedProduct?.id) {
        response = await ProductService.updateProduct(selectedProduct.id, formattedProduct);
        showNotification('Product updated successfully');
        if (typeof onProductUpdate === 'function') {
          onProductUpdate(response.data);
        }
      } else {
        response = await ProductService.createProduct(formattedProduct);
        showNotification('Product created successfully');
        if (typeof onProductCreate === 'function') {
          onProductCreate(response.data);
        }
      }

      if (!response?.data) {
        throw new Error('Invalid server response');
      }

      await loadProducts();

      if (!selectedProduct?.id) {
        await handleProductClick(response.data);
      }

      if (mounted.current) {
        setOpenDialog(false);
        setSelectedProduct(null);
        setNewProduct({
          name: '',
          description: '',
          category: '',
          price: '',
          customFields: {}
        });
      }
      
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [
    selectedProduct,
    newProduct,
    onProductUpdate,
    onProductCreate,
    loadProducts,
    handleProductClick,
    showNotification,
    handleError
  ]);

  const handleDeleteProduct = useCallback(async () => {
    if (!mounted.current || !productToDelete?.id) return;

    try {
      const deletingProductId = productToDelete.id;
      const deletingProductName = productToDelete.name;

      if (selectedProduct?.id === deletingProductId) {
        setSelectedProduct(null);
        if (typeof onProductSelect === 'function') {
          onProductSelect(null);
        }
      }

      setLoadingStates(prev => ({ ...prev, [deletingProductId]: true }));
      
      const response = await ProductService.deleteProduct(deletingProductId);

      if (response.success && mounted.current) {
        setProducts(prevProducts => 
          prevProducts.filter(p => p.id !== deletingProductId)
        );

        showNotification(
          `Product "${deletingProductName}" has been deleted successfully`,
          'success'
        );

        if (typeof onProductDelete === 'function') {
          onProductDelete(deletingProductId);
        }

        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
        setError(null);
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        if (productToDelete?.id) {
          setLoadingStates(prev => ({ ...prev, [productToDelete.id]: false }));
        }
        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
      }
    }
  }, [
    productToDelete,
    selectedProduct,
    onProductDelete,
    onProductSelect,
    handleCloseMenu,
    showNotification,
    handleError,
    mounted
  ]);

  const handleSearch = useCallback(async () => {
    if (!mounted.current) return;

    try {
      setLoading(true);
      setError(null);

      if (!searchQuery.trim()) {
        await loadProducts();
        return;
      }

      const response = await ProductService.searchProducts({
        query: searchQuery.trim()
      });

      if (!response?.data?.products && mounted.current) {
        throw new Error('Invalid search response from server');
      }

      if (mounted.current) {
        setProducts(response.data.products);
      }
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setProducts([]);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [loadProducts, searchQuery, handleError]);

  const handleCategoryFilter = useCallback(async (category) => {
    if (!mounted.current) return;

    try {
      setLoading(true);
      setError(null);
      setCategoryFilter(category);

      if (!category) {
        await loadProducts();
        return;
      }

      const response = await ProductService.getProductsByCategory(category);
      
      if (!response?.data?.products && mounted.current) {
        throw new Error('Invalid category filter response');
      }

      if (mounted.current) {
        setProducts(response.data.products);
      }
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setProducts([]);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [loadProducts, handleError]);

  const handleRefresh = useCallback(() => {
    if (mounted.current) {
      setRefreshKey(prev => prev + 1);
    }
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    if (mounted.current) {
      setDeleteConfirmOpen(false);
      setProductToDelete(null);
      handleCloseMenu();
    }
  }, [handleCloseMenu]);

  const handleCloseDialog = useCallback(() => {
    if (mounted.current) {
      setOpenDialog(false);
      setNewProduct({
        name: '',
        description: '',
        category: '',
        price: '',
        customFields: {}
      });
      setError(null);
    }
  }, []);

  if (!mounted.current) {
    return null;
  }

  return (
    <StyledComponents.Container>
      <Box sx={{ marginBottom: 4 }}>
        {error && (
          <Alert 
            severity={error.name === 'CooldownError' ? 'warning' : 'error'}
            onClose={() => setError(null)}
            icon={error.name === 'CooldownError' ? 
              <AlertCircle size={20} color="#FFFFFF" /> : 
              undefined
            }
            action={
              <Button 
                color="inherit" 
                size="small"
                onClick={() => setError(null)}
                sx={{ color: '#FFFFFF' }}
              >
                {error.name === 'CooldownError' ? 'DISMISS' : 'CLOSE'}
              </Button>
            }
            sx={{ 
              marginBottom: 2,
              backgroundColor: error.name === 'CooldownError' ? 
                'transparent' : 
                'rgba(255,0,0,0.1)',
              color: '#FFFFFF',
              border: 'none',
              '& .MuiAlert-icon': {
                color: '#FFFFFF',
              },
              '& .MuiAlert-message': {
                color: '#FFFFFF',
              }
            }}
          >
            {error.message}
          </Alert>
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <StyledComponents.SearchBar
              fullWidth
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              InputProps={{
                startAdornment: <Search size={20} style={{ color: 'rgba(255, 255, 255, 0.7)', marginRight: 8 }} />,
              }}
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ minWidth: 120 }}>
              <StyledComponents.Select
                value={categoryFilter}
                onChange={(e) => handleCategoryFilter(e.target.value)}
                displayEmpty
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                  borderRadius: '12px',
                  '& .MuiSelect-select': {
                    paddingY: '8px',
                  }
                }}
              >
                <StyledComponents.MenuItem value="">
                  All Categories
                </StyledComponents.MenuItem>
                {categories.map((category) => (
                  <StyledComponents.MenuItem key={category.id} value={category.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(category.icon, { 
                        size: 16,
                        style: { color: category.color }
                      })}
                      {category.name}
                    </Box>
                  </StyledComponents.MenuItem>
                ))}
              </StyledComponents.Select>
            </FormControl>
          </Grid>
          <Grid item>
            <StyledComponents.ActionButton
              onClick={() => {
                setSelectedProduct(null);
                handleOpenDialog();
              }}
              startIcon={<Plus size={20} />}
              disabled={loading}
            >
              Add Product
            </StyledComponents.ActionButton>
          </Grid>
          <Grid item>
            <Tooltip title="Refresh">
              <IconButton 
                onClick={handleRefresh} 
                disabled={loading}
                sx={{ color: '#FFFFFF' }}
              >
                <RefreshCw size={20} className={loading ? 'animate-spin' : ''} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4, color: '#FFFFFF' }}>
              <CircularProgress color="inherit" />
            </Box>
          </Grid>
        ) : products.length === 0 ? (
          <Grid item xs={12}>
            <Box sx={{ 
              p: 4, 
              textAlign: 'center', 
              backgroundColor: 'rgba(255,255,255,0.1)',
              borderRadius: '16px',
              color: '#FFFFFF' 
            }}>
              <AlertCircle size={48} style={{ marginBottom: '16px', opacity: 0.7 }} />
              <Typography variant="h6" gutterBottom>
                No products found
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>
                {searchQuery 
                  ? "No products match your search" 
                  : "Get started by adding your first product"}
              </Typography>
            </Box>
          </Grid>
        ) : (
          products.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
              <StyledComponents.ProductCard 
                onClick={() => handleProductClick(product)}
                bgcolor={categories.find(c => c.id === product.category)?.color || '#666'}
              >
                <Box>
                  <Box sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '12px'
                  }}>
                    {React.createElement(
                      categories.find(c => c.id === product.category)?.icon || Package,
                      { size: 24, color: '#FFFFFF' }
                    )}
                  </Box>
                </Box>
                
                <Box sx={{ position: 'relative' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
                    <Typography
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                        marginBottom: '4px',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#FFFFFF', flexShrink: 0 }}>
                      ${typeof product.price === 'number' ? product.price.toFixed(2) : '0.00'}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.875rem'
                    }}
                  >
                    {product.description
                      ? product.description.length > 50 
                        ? `${product.description.substring(0, 50)}...` 
                        : product.description
                      : 'No description available'}
                  </Typography>
                  <Box sx={{ 
                    position: 'absolute',
                    top: -30,
                    right: 0,
                    display: 'flex',
                    gap: 1
                  }}>
                    {selectedProduct?.id === product.id && (
                      <Chip
                        size="small"
                        label="Selected"
                        sx={{ 
                          backgroundColor: 'rgba(255,255,255,0.2)',
                          color: '#FFFFFF',
                          borderRadius: '8px'
                        }}
                      />
                    )}
                    <IconButton 
                      size="small"
                      onClick={(e) => handleOpenMenu(e, product)}
                      disabled={loadingStates[product.id]}
                      sx={{ color: '#FFFFFF' }}
                    >
                      {loadingStates[product.id] ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <MoreVertical size={20} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </StyledComponents.ProductCard>
            </Grid>
          ))
        )}
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            backgroundColor: '#262626',
            color: '#FFFFFF',
            borderRadius: '12px',
          }
        }}
      >
        <StyledComponents.MenuItem onClick={() => {
          handleOpenDialog(selectedProduct);
          handleCloseMenu();
        }}>
          <Edit size={16} style={{ marginRight: 8 }} />
          Edit Product
        </StyledComponents.MenuItem>
        <StyledComponents.MenuItem 
          onClick={() => {
            if (selectedProduct) {
              handleDeleteClick(selectedProduct);
            }
          }}
          sx={{ color: '#ff4444' }}
        >
          <Trash2 size={16} style={{ marginRight: 8 }} />
          Delete Product
        </StyledComponents.MenuItem>
      </Menu>

      <StyledComponents.Dialog 
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ color: '#FFFFFF' }}>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <AlertTriangle size={24} color="#ff4444" />
            <Typography sx={{ color: '#FFFFFF' }}>
              {productToDelete ? (
                `Are you sure you want to delete "${productToDelete.name}"? This action cannot be undone.`
              ) : (
                'No product selected for deletion.'
              )}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <StyledComponents.ActionButton 
            onClick={handleCloseDeleteDialog}
          >
            Cancel
          </StyledComponents.ActionButton>
          <StyledComponents.ActionButton
            onClick={handleDeleteProduct}
            disabled={!productToDelete || loadingStates[productToDelete.id]}
            sx={{
              backgroundColor: '#ff4444',
              '&:hover': {
                backgroundColor: '#ff6666'
              }
            }}
          >
            {loadingStates[productToDelete?.id] ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Delete'
            )}
          </StyledComponents.ActionButton>
        </DialogActions>
      </StyledComponents.Dialog>

      <StyledComponents.Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ color: '#FFFFFF' }}>
          {selectedProduct ? 'Edit Product' : 'Add New Product'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>
            <StyledComponents.TextField
              fullWidth
              label="Product Name"
              value={newProduct.name || ''}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
              error={Boolean(error && error.message && error.message.includes('name'))}
              required
            />
            <StyledComponents.TextField
              fullWidth
              label="Description"
              multiline
              rows={3}
              value={newProduct.description || ''}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />
            <FormControl fullWidth required error={Boolean(error && error.message && error.message.includes('category'))}>
              <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Category</InputLabel>
              <StyledComponents.Select
                value={newProduct.category || ''}
                onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
                label="Category"
              >
                {categories.map((category) => (
                  <StyledComponents.MenuItem key={category.id} value={category.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(category.icon, { 
                        size: 16,
                        style: { color: category.color }
                      })}
                      {category.name}
                    </Box>
                  </StyledComponents.MenuItem>
                ))}
              </StyledComponents.Select>
            </FormControl>
            <StyledComponents.TextField
              fullWidth
              label="Price"
              type="number"
              value={newProduct.price || ''}
              onChange={(e) => setNewProduct({
                ...newProduct,
                price: e.target.value ? parseFloat(e.target.value) : ''
              })}
              error={Boolean(error && error.message && error.message.includes('price'))}
              required
              InputProps={{
                startAdornment: <DollarSign size={16} style={{ marginRight: 8, color: 'rgba(255, 255, 255, 0.7)' }} />,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <StyledComponents.ActionButton onClick={handleCloseDialog}>
            Cancel
            </StyledComponents.ActionButton>
          <StyledComponents.ActionButton 
            onClick={handleAddProduct}
            disabled={loading || !newProduct.name || !newProduct.category}
            sx={{
              backgroundColor: '#1DB954',
              '&:hover': {
                backgroundColor: '#1ed760'
              }
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : selectedProduct ? (
              'Save Changes'
            ) : (
              'Add Product'
            )}
          </StyledComponents.ActionButton>
        </DialogActions>
      </StyledComponents.Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowSnackbar(false)} 
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledComponents.Container>
  );
};

export default ProductsManager;