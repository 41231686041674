import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import DirectiveLogo from '../../common/DirectiveLogo';
import { getThemeStyles } from '../../themes/themeConfig';

const phrases = [
  [
    "Analyzing input data",
    "Processing information",
    "Gathering relevant parameters"
  ],
  [
    "Identifying key variables",
    "Establishing causal relationships",
    "Mapping data structures"
  ],
  [
    "Formulating hypotheses",
    "Predicting outcomes",
    "Simulating scenarios"
  ],
  [
    "Checking logical consistency",
    "Evaluating hypotheses",
    "Validating models"
  ],
  [
    "Integrating new information",
    "Finalizing conclusions",
    "Preparing comprehensive response"
  ]
];

const glowAnimation = keyframes`
  0% { opacity: 0; filter: blur(10px); }
  50% { opacity: 1; filter: blur(0px); }
  100% { opacity: 0; filter: blur(10px); }
`;

const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '8px',
  paddingBottom: '8px',
  opacity: 1,
  transform: 'none',
  '&:last-child': {
    marginBottom: '12px',
  },
  '@media (max-width: 1200px)': {
    maxWidth: '100%',
    paddingRight: '0',
    paddingLeft: '0',
  },
  '@media (max-width: 600px)': {
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '100%',
  },
  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  paddingRight: '0',
  marginLeft: '0',
  paddingTop: '8px',
  '@media (max-width: 1200px)': {
    paddingRight: '16px',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    paddingRight: '8px',
  },
});

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0px',
  marginTop: '10px',
  opacity: 1,
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#2a2a2a !important'
    : '#fff !important',
  border: theme.palette.mode === 'dark' 
    ? '1px solid rgba(255, 255, 255, 0.1)' 
    : '1px solid rgba(0, 0, 0, 0.1)'
}));

const MessageContent = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  maxWidth: '100%',
  width: 'auto',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: '0',
  marginRight: '0',
  lineHeight: '1.75',
  opacity: 1,
  transform: 'none',
  '@media (max-width: 1200px)': {
    marginRight: '0',
    maxWidth: 'calc(100% - 44px)',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    maxWidth: 'calc(100% - 34px)',
    width: '100%',
    marginRight: '0',
  },
  ...getThemeStyles('MessageContent', theme.palette.mode),
}));

const AnimatedText = styled(Typography)(({ theme }) => ({
  animation: `${glowAnimation} 2s infinite`,
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
}));

const ThinkingIcon = ({ startTime }) => {
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [phaseIndex, setPhaseIndex] = useState(0);

  useEffect(() => {
    const updatePhrase = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const newPhaseIndex = Math.min(
        Math.floor(elapsedTime / 1000),
        phrases.length - 1
      );

      if (newPhaseIndex !== phaseIndex) {
        setPhaseIndex(newPhaseIndex);
      }

      const currentPhrases = phrases[newPhaseIndex];
      const randomIndex = Math.floor(Math.random() * currentPhrases.length);
      setCurrentPhrase(currentPhrases[randomIndex]);
    };

    updatePhrase();
    const interval = setInterval(updatePhrase, 2000);

    return () => clearInterval(interval);
  }, [startTime, phaseIndex]);

  return (
    <MessageContainer>
      <MessageWrapper>
        <AssistantIconContainer>
          <DirectiveLogo size={18} className="text-current" />
        </AssistantIconContainer>
        <MessageContent>
          <AnimatedText variant="body1">{currentPhrase}</AnimatedText>
        </MessageContent>
      </MessageWrapper>
    </MessageContainer>
  );
};

export default ThinkingIcon;