import React, { useState, useCallback, memo } from 'react';
import { TextareaAutosize, IconButton, Box, Typography, Avatar } from '@mui/material';
import { ArrowUp, X, AlertCircle, Clock } from 'lucide-react';
import { styled, useTheme } from '@mui/material/styles';
import { getThemeStyles } from '../../themes/themeConfig';
import ModelSelector from './ModelSelector';
import PersonalitySelector from './PersonalitySelector';

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '12px',
  padding: '4px',
  ...getThemeStyles('InputWrapper', theme.palette.mode),
}));

const TopControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '8px',
  flexWrap: 'wrap',
}));

const InputRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  ...getThemeStyles('InputRow', theme.palette.mode),
}));

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: 'transparent',
  border: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  padding: '8px',
  '&:focus': {
    outline: 'none',
  },
  ...getThemeStyles('StyledTextarea', theme.palette.mode),
}));

const AttachedFilesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: '8px',
  marginBottom: '8px',
  padding: '4px',
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
  },
  ...getThemeStyles('AttachedFilesContainer', theme.palette.mode),
}));

const AttachedFileChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)',
  borderRadius: '20px',
  padding: '4px 12px 4px 4px',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  ...getThemeStyles('AttachedFileChip', theme.palette.mode),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('InputIconButton', theme.palette.mode),
}));

const RateLimitAlert = memo(({ message, resetTime, onOpenAGTPromo }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: isDarkMode ? 'rgba(254, 243, 199, 0.1)' : 'rgb(254, 243, 199)',
        border: `1px solid ${isDarkMode ? 'rgb(180, 150, 50)' : 'rgb(252, 211, 77)'}`,
        borderRadius: '8px',
        padding: '12px 16px',
        marginBottom: '8px',
        width: '100%',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <AlertCircle 
        size={20} 
        style={{ 
          color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(217, 119, 6)' 
        }} 
      />
      <Box sx={{ flex: 1 }}>
        <Typography 
          variant="body2" 
          sx={{ 
            color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(120, 53, 15)',
            fontWeight: 500,
            marginBottom: '2px',
            '& button': {
              color: 'inherit',
              fontWeight: 500,
              textDecoration: 'underline',
              background: 'none',
              border: 'none',
              padding: 0,
              cursor: 'pointer',
              fontSize: 'inherit',
              '&:hover': {
                opacity: 0.8,
              },
            }
          }}
        >
          You've reached the hourly message limit. Continue your conversation with{' '}
          <button onClick={onOpenAGTPromo}>Try Pro</button>
        </Typography>
        {resetTime && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1 
          }}>
            <Clock 
              size={14} 
              style={{ 
                color: isDarkMode ? 'rgb(252, 211, 77, 0.8)' : 'rgb(180, 83, 9)' 
              }} 
            />
            <Typography 
              variant="caption" 
              sx={{ 
                color: isDarkMode ? 'rgb(252, 211, 77, 0.8)' : 'rgb(146, 64, 14)',
                fontWeight: 400,
              }}
            >
              Try again in {Math.ceil(resetTime / 60)} minutes
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
});

const OptimizedInput = memo(({ 
  onSendMessage, 
  isWaitingForResponse, 
  inputRef, 
  selectedFiles = [], 
  onRemoveFile, 
  renderFileAttachment,
  rateLimitInfo = null,
  onOpenAGTPromo,
  selectedModel = 'claude-3-haiku-20240307',
  onModelChange,
  selectedPersonality = 'normal',
  onPersonalityChange,
  isPro = false
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const theme = useTheme();

  const handleInputChange = useCallback((e) => {
    setInputMessage(e.target.value);
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey && !isWaitingForResponse) {
      event.preventDefault();
      if (inputMessage && inputMessage.trim().length > 0) {
        onSendMessage(inputMessage);
        setInputMessage('');
      }
    }
  }, [onSendMessage, isWaitingForResponse, inputMessage]);

  const handleSendClick = useCallback(() => {
    if (inputMessage && inputMessage.trim().length > 0 && !isWaitingForResponse) {
      onSendMessage(inputMessage);
      setInputMessage('');
    }
  }, [onSendMessage, inputMessage, isWaitingForResponse]);

  const renderThumbnail = useCallback((file) => {
    if (file.type.startsWith('image/')) {
      return (
        <Avatar
          src={URL.createObjectURL(file)}
          alt={file.name}
          sx={{ width: 28, height: 28, marginRight: '8px' }}
        />
      );
    }
    return null;
  }, []);

  return (
    <InputWrapper>
      {rateLimitInfo && (
        <RateLimitAlert
          message={rateLimitInfo.message}
          resetTime={rateLimitInfo.resetIn}
          onOpenAGTPromo={onOpenAGTPromo}
        />
      )}
      
      <TopControls>
        <ModelSelector 
          selectedModel={selectedModel}
          onModelChange={onModelChange}
          disabled={isWaitingForResponse}
          isPro={isPro}
        />
        <PersonalitySelector
          selectedPersonality={selectedPersonality}
          onPersonalityChange={onPersonalityChange}
          disabled={isWaitingForResponse}
          isPro={isPro}
        />
      </TopControls>
      
      {selectedFiles.length > 0 && (
        <AttachedFilesContainer>
          {selectedFiles.map((file, index) => (
            <AttachedFileChip key={index}>
              {renderThumbnail(file)}
              <Typography 
                variant="body2" 
                sx={{ 
                  margin: '0 8px', 
                  maxWidth: '120px', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis' 
                }}
              >
                {file.name}
              </Typography>
              <StyledIconButton 
                size="small" 
                onClick={() => onRemoveFile && onRemoveFile(file)} 
                sx={{ padding: 0 }}
              >
                <X size={16} />
              </StyledIconButton>
            </AttachedFileChip>
          ))}
        </AttachedFilesContainer>
      )}

      <InputRow>
        {renderFileAttachment && renderFileAttachment()}
        <StyledTextarea
          ref={inputRef}
          placeholder={isWaitingForResponse ? "Waiting response..." : "Ask AGT"}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          minRows={1}
          maxRows={4}
          disabled={isWaitingForResponse}
        />
        <StyledIconButton 
          onClick={handleSendClick} 
          disabled={isWaitingForResponse || !inputMessage || inputMessage.trim().length === 0}
        >
          <ArrowUp size={20} />
        </StyledIconButton>
      </InputRow>
    </InputWrapper>
  );
});

OptimizedInput.displayName = 'OptimizedInput';
RateLimitAlert.displayName = 'RateLimitAlert';

export default OptimizedInput;