import { Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Class for handling file exports in AGTMaker
 */
class AGTMakerExport {
  /**
   * Exports content as a text file
   * @param {string} content - The content to export
   * @param {string} [filename='agt-maker-content.txt'] - The name of the output file
   * @returns {Promise<void>}
   */
  static async exportToTxt(content, filename = 'agt-maker-content.txt') {
    try {
      const blob = new Blob([content], { type: 'text/plain' });
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error exporting to TXT:', error);
      throw new Error('Failed to export as TXT');
    }
  }

  /**
   * Exports content as a PDF file
   * @param {string} content - The content to export
   * @param {Object} mermaidSvgs - Object containing Mermaid diagrams as SVGs
   * @param {string} [filename='agt-maker-content.pdf'] - The name of the output file
   * @returns {Promise<void>}
   */
  static async exportToPdf(content, mermaidSvgs, filename = 'agt-maker-content.pdf') {
    try {
      const pdf = new jsPDF();
      const lines = content.split('\n');
      let y = 20;
      const margin = 15;
      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const contentWidth = pageWidth - 2 * margin;

      // Document title
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(16);
      pdf.text("AGT Maker Content", pageWidth / 2, y, { align: "center" });
      y += 15;

      // Process content
      let mermaidIndex = 0;
      let inMermaidBlock = false;
      let mermaidContent = '';

      for (const line of lines) {
        // Handle Mermaid diagrams
        if (line.trim() === '```mermaid') {
          inMermaidBlock = true;
          mermaidContent = '';
          continue;
        }

        if (inMermaidBlock) {
          if (line.trim() === '```') {
            inMermaidBlock = false;
            if (y + 150 > pageHeight - margin) {
              pdf.addPage();
              y = 20;
            }

            try {
              const svg = mermaidSvgs[mermaidIndex];
              if (svg) {
                pdf.addSvgAsImage(svg, margin, y, contentWidth, 100);
                y += 110;
                mermaidIndex++;
              }
            } catch (error) {
              console.error('Error adding Mermaid diagram to PDF:', error);
              y += 10;
            }
            continue;
          }
          mermaidContent += line + '\n';
          continue;
        }

        // Add new page if needed
        if (y + 7 > pageHeight - margin) {
          pdf.addPage();
          y = 20;
        }

        // Handle headings and normal text
        if (line.trim().startsWith('#')) {
          const level = line.trim().match(/^#+/)[0].length;
          const text = line.trim().replace(/^#+\s+/, '');
          pdf.setFontSize(16 - level);
          pdf.setFont("helvetica", "bold");
          pdf.text(text, margin, y);
          y += 10;
        } else {
          pdf.setFontSize(12);
          pdf.setFont("helvetica", "normal");
          const splitText = pdf.splitTextToSize(line, contentWidth);
          pdf.text(splitText, margin, y);
          y += 7 * splitText.length;
        }
        y += 3;
      }

      // Add page numbers
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFont("helvetica", "italic");
        pdf.setFontSize(8);
        pdf.text(`Page ${i} of ${pageCount}`, pageWidth - 20, pageHeight - 10);
      }

      pdf.save(filename);
    } catch (error) {
      console.error('Error exporting to PDF:', error);
      throw new Error('Failed to export as PDF');
    }
  }

  /**
   * Exports content as a DOCX file
   * @param {string} content - The content to export
   * @param {string} [filename='agt-maker-content.docx'] - The name of the output file
   * @returns {Promise<void>}
   */
  static async exportToDocx(content, filename = 'agt-maker-content.docx') {
    try {
      const doc = new Document({
        styles: {
          paragraphStyles: [
            {
              id: 'Normal',
              name: 'Normal',
              basedOn: 'Normal',
              next: 'Normal',
              quickFormat: true,
              run: {
                size: 24,
                font: 'Calibri',
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
          ],
        },
        sections: [{
          properties: {},
          children: [
            new Paragraph({
              text: "AGT Maker Content",
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
              spacing: { before: 240, after: 400 },
            }),
            ...content.split('\n').map(line => {
              if (line.trim().startsWith('#')) {
                const level = line.trim().match(/^#+/)[0].length;
                const text = line.trim().replace(/^#+\s+/, '');
                return new Paragraph({
                  text: text,
                  heading: level,
                  spacing: { before: 240, after: 120 },
                });
              }
              return new Paragraph({
                children: [
                  new TextRun({
                    text: line,
                    size: 24,
                  }),
                ],
                spacing: { before: 120, after: 120 },
              });
            }),
          ],
        }],
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, filename);
    } catch (error) {
      console.error('Error exporting to DOCX:', error);
      throw new Error('Failed to export as DOCX');
    }
  }
}

export default AGTMakerExport;